<template>
    <div class="edit-live-wrap">
        <el-form :model="form" :rules="rules" ref="form" label-position="left">
            <el-form-item label="标题" prop="title" label-width="80px">
                <el-input placeholder="请填写标题" v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="副标题" prop="sub_title" label-width="80px">
                <el-input placeholder="请填写副标题" v-model="form.sub_title"></el-input>
            </el-form-item>
            <el-form-item label="配图" prop="img" label-width="80px" style="margin-bottom: 40px">
                <img-cutter :box-width="900" :box-height="500" :cut-width="750" :cut-height="380" rate="75:38"
                            :size-change=false
                            @cutDown="cutDown" file-type="jpeg" v-if="form.img===''"></img-cutter>
                <div class="complete-imgs" v-if="form.img!==''">
                    <img class="up-img" :src="form.img" alt="">
                    <div class="flex-div show-btn">
                        <!--<i class="el-icon-view" style="margin-right: 30px;" @click="previewImg"></i>-->
                        <i class="el-icon-delete" @click="deleteImg"></i>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="所属老师" prop="teacher_id" label-width="80px">
                <el-select v-model="form.teacher_id" placeholder="请选择">
                    <el-option v-for="item in teacherOptions" :label='item.label' :value='item.value'>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="直播类型" prop="type" label-width="80px">
                <el-select v-model="form.type" placeholder="请选择">
                    <el-option v-for="item in typeOptions" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="服务对象" prop="service_lesson_list" label-width="80px" v-if="form.type===1">
                <el-select v-model="form.service_lesson_list" multiple placeholder="请选择">
                    <el-option v-for="item in serviceLevelOptions" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="直播状态" prop="status" label-width="80px">
                <el-select v-model="form.status" placeholder="请选择">
                    <el-option v-for="item in statusOptions" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="直播时间" prop="start_time" label-width="80px">
                <el-date-picker type="datetime" value-format="YYYY-MM-DD HH:mm" format="YYYY-MM-DD HH:mm"
                                placeholder="选择日期" v-model="form.start_time"></el-date-picker>
            </el-form-item>
            <el-form-item label="启用状态" prop="enable" label-width="80px">
                <el-select v-model="form.enable" placeholder="请选择">
                    <el-option v-for="item in isEnableOptions" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="详情" prop="content" label-width="80px">
                <el-input type="textarea" v-model="form.content"></el-input>
            </el-form-item>
        </el-form>
        <div class="submit-btn" @click="submit">提交</div>
    </div>
    <div></div>
</template>

<script>
    import ImgCutter from "@/components/ImgCutter";
    import axios from 'axios'
    import MyMasks from "@/components/myMasks";
    import {ModifyAreaLive, GetTeacherList, AddOrEditCreationData} from "@/api";
    import {getLocalStorage} from "@/utils";

    export default {
        name: "editLiveCourse",
        components: {MyMasks, ImgCutter},
        data() {
            return {
                form: {
                    title: '',
                    sub_title: '',
                    img: '',
                    teacher_id: '',
                    type: 0,
                    service_lesson_list: '',  //service_lesson_list
                    status: 1,
                    start_time: '',
                    enable: 1,
                    content: ''
                },
                rules: {
                    title: [
                        {required: true, message: '标题不能为空', trigger: 'blur'}
                    ],
                    sub_title: [
                        {required: true, message: '副标题不能为空', trigger: 'blur'}
                    ],
                    img: [
                        {required: true, message: '图片不能为空', trigger: 'change'}
                    ],
                    teacher_id: [
                        {required: true, message: '老师不能为空', trigger: 'change'}
                    ],
                    type: [
                        {required: true, message: '直播类型不能为空', trigger: 'change'}
                    ],
                    service_lesson_list: [
                        {required: true, message: '服务对象不能为空', trigger: 'change'}
                    ],
                    status: [
                        {required: true, message: '服务对象不能为空', trigger: 'change'}
                    ],
                    start_time: [
                        {required: true, message: '时间不能为空', trigger: 'change'}
                    ],
                    enable: [
                        {required: true, message: '时间不能为空', trigger: 'change'}
                    ]
                },
                teacherOptions: [],
                typeOptions: [{label: '公开课', value: 0}, {label: '服务课', value: 1}],
                serviceLevelOptions: [],
                statusOptions: [{label: '已结束', value: 0}, {label: '未开始', value: 1}, {label: '直播中', value: 2}],
                isEnableOptions: [{label: '是', value: 1}, {label: '否', value: 0}],
                fileList: [],
                previewImgItem: false,
                editItem: {},   // 要修改数据
            }
        },
        mounted() {
            let that = this

            that.getTeacherData()
            if (JSON.stringify(that.$store.state.services) === '{}')
                that.$store.commit('setSomeInfo', JSON.parse(getLocalStorage('someInfo')))
            that.serviceLevelOptions = that.$store.state.services
            that.editItem = {}
            if (that.$route.params.item) {
                let temp = that.$store.state.breadCrumbList
                temp[2].meta.title = '修改直播课'
                that.$store.commit('setBreadCrumbList', temp)
                that.editItem = that.$store.state.editLiveCourse
                if (that.editItem.service_lesson_list)
                    that.editItem.service_lesson_list = that.editItem.service_lesson_list.split(",")
                Object.assign(that.form, that.editItem)
            } else {
                let temp = that.$store.state.breadCrumbList
                if (temp.length !== 0) {
                    temp[2].meta.title = '添加直播课'
                    that.$store.commit('setBreadCrumbList', temp)
                }
            }
        },
        methods: {
            // 获取老师数据
            getTeacherData() {
                const that = this
                GetTeacherList().then((res) => {
                    res.data.forEach((item, i) => {
                        that.teacherOptions[i] = {label: item.name, value: item.id}
                    })
                }).catch((e) => {
                    that.$message.error(e.msg)
                })
            },

            // 上传图片 图片状态改变
            handleChange(changeInfo, fileList) {
                if (changeInfo.status === 'success') {
                    this.form.img = changeInfo.response.data
                    this.fileList = fileList
                    // console.log(fileList)
                }
            },

            // 预览图片
            previewImg() {
                let temp = document.getElementsByClassName('up-img')[0]
                if (temp.naturalWidth > 500 || temp.naturalHeight > 500)
                    this.$message.info('图片太大了，暂不支持预览哦')
                else
                    this.previewImgItem = {url: this.form.img, width: temp.naturalWidth, height: temp.naturalHeight};
            },

            // 删除图片
            deleteImg() {
                this.form.img = ''
                if (this.fileList)
                    this.fileList.splice(0, 1)
            },

            // 验证上传图片格式
            beforeImgUpload(file) {
                const isJPG = file.type === 'image/jpeg'
                if (!isJPG)
                    this.$message.error('上传图片只能是JPG或jpg格式')
                return isJPG
            },

            /** 图片编辑部分 START */
            cutDown(e) {
                const that = this
                let params = new FormData()
                params.append('file', e.file)
                axios.post(that.$store.state.uploadUrl, params, {
                        headers: {
                            "token": getLocalStorage('token'), //token
                        }
                    }
                ).then(res => {
                    // console.log(res)
                    if (res.data.code === 200) {
                        that.form.img = res.data.data
                    }
                }).catch(err => {
                    that.$message.error(err)
                })
            },
            /** 图片编辑部分 END */

            // 提交
            submit() {
                const that = this
                that.form.service_lesson_list = that.form.service_lesson_list.toString()
                that.$refs['form'].validate((valid) => {
                    if (valid) {
                        ModifyAreaLive(that.form, that.$route.params.item ? 'edit' : 'create').then((res) => {
                            that.$message.info(res.data)
                            that.$router.push('/areaCourse')
                        }).catch((e) => {
                            that.$message.error(e.msg)
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .edit-live-wrap {
        .shadow-border-bgcolor();
        padding: 5% 0 10% 10%;

        ::v-deep(.el-upload) {
            width: 160px;
            height: 100px;
            background: #FAFAFA;
            border: 1px solid #E9E9E9;
        }

        ::v-deep(.el-form-item__error) {
            left: 12%;
        }
    }

    .complete-imgs {
        width: 150px;
        height: 76px;
    }

    .my-el-input(@w: 240px,@backgroundcolor: #fff);
    .my-el-select(@backgroundcolor: #fff);
    .my-el-datepicker(@w: 240px);
    .my-el-textarea(@backgroundcolor: #fff);

    .submit-btn {
        margin: 50px 0 0 30%;
        width: 120px;
        height: 40px;
        line-height: 40px;
        background-color: #2C8EFC;
        border-radius: 30px;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }
</style>