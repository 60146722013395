<template>
    <div>
        <span v-for="(t,i) in choices" :key="i" :class="choiceIndex === i ? 'span-active':''" @click="makeChoice(i)">{{t}}</span>
    </div>
</template>

<script>
    export default {
        name: "titleUnderlineChoice",
        props: {
            choices: Array,
            choiceIndex: Number
        },
        data() {
            return {
                // cIndex: 0,
            }
        },
        watch: {
            // choiceIndex(newValue, oldValue) {
            //     if (newValue !== oldValue) {
            //         this.cIndex = newValue;
            //     }
            // }
        },
        mounted() {
            // this.cIndex = this.choiceIndex
        },
        methods: {
            makeChoice(i) {
                this.$emit('getChoice', i)
            }
        }
    }
</script>

<style lang="less" scoped>
    div {
        padding: 0 0 24px 18px;
        border-bottom: 2px solid #F1F1F1;
        display: flex;

        span {
            margin-right: 148px;
            font-size: 18px;
            color: #8A8F99;
            cursor: pointer;
        }

        @bcolor:#2C8EFC;
        .span-active {
            position: relative;
            color: @bcolor;
        }

        .span-active::after {
            position: absolute;
            content: '';
            left: 12px;
            bottom: -25px;
            width: 47px;
            height: 4px;
            background: @bcolor;
            border-radius: 2px;
        }
    }
</style>