<template>
    <div>
        <small-title detail-title="已发推广素材" style="margin-top: 32px;margin-bottom: 28px;"></small-title>
        <my-table :column-num="userCommentsTableTitles.length" :table-titles="userCommentsTableTitles" :table-props="userCommentsTableProps"
                  :table-data="userCommentsTableData"></my-table>
    </div>
</template>

<script>
    import {myTable, smallTitle} from '@/components'
    export default {
        name: "dataUserComment",
        components: {myTable, smallTitle},
        data(){
            return{
                userCommentsTableTitles: ['用户', '学号', '评论', '查看时间'],
                userCommentsTableProps: ['user', 'sid', 'comment', 'time'],
                userCommentsTableData: [{
                    // user: {avatar: require('../../assets/temp-avatar.jpg'), name: '信念的爸爸'},
                    user:'头像+名字',
                    sid: '21458',
                    comment:'老师讲的太好了',
                    time: '2019-12-02  14:21'
                }],
            }
        }
    }
</script>

<style scoped>

</style>