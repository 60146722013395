import request from '@/utils/request.js'

function myRequest(urlPre, url, data) {
    data = {...data, client: 'office'}
    return request({url: urlPre + url, data})
}

/**
 * 获取登录二维码
 **/
export function GetLoginQRCode() {
    return myRequest('/office/LoginQr/', 'getQr')
}

/**
 * 确认登录结果
 * @param data：code
 **/
export function GetLoginResult(data) {
    return myRequest('/office/LoginQr/', 'check', data)
}

/**
 * 首页 - 数据概览
 **/
export function GetDataOverview() {
    return myRequest("/office/Index/", 'overview',)
}

/**
 * 首页 - 字典 （素材中心 - 推广好文 和 我的创作 的推广码链接）
 **/
export function GetSomeInfo() {
    return myRequest("/office/Index/", 'dict',)
}

let resourceUrlPre = "/office/Cabinet/"

/**
 * 素材中心 - 推广好文 / 推广海报 分类
 **/
export function GetPromotionTags(i) {
    return i === 0 ? myRequest('/office/Cabinet/', 'getTagList')
        : myRequest('/office/Poster/', 'getClassify')
}

/**
 * 素材中心-推广好文 / 素材详情
 **/
export function GetResourceDetail(data) {
    return myRequest(resourceUrlPre, 'detail', data)
}

/**
 * 素材中心-推广好文 / 我的创作
 **/
 export function GetOneTagList(data) {
  return myRequest(resourceUrlPre, 'getList', data)
}

/**
 * 素材中心 - 已发素材 - 用户列表
 */
export function GetUserList(data) {
    return myRequest(resourceUrlPre, 'getShareBrowLog', data)
}

/**
 * 素材中心-推广海报
 **/
export function GetPromotionPoster(data) {
    return myRequest('/office/Poster/', 'getList', data)
}

/**
 * 素材中心-我的创作-创作或者修改素材
 **/
export function AddOrEditCreationData(mode, data) {
    return myRequest(resourceUrlPre, mode + 'Creation', data)
}

/**
 * 素材中心-我的创作-删除素材
 **/
export function DeleteCreationData(data) {
    return myRequest(resourceUrlPre, 'deleteCreation', data)
}

/**
 * 首页 - 已发素材
 **/
export function GetSentResource(data) {
    return myRequest(resourceUrlPre, 'getShareLog', data)
}

/**
 * 素材中心-赠送课程
 **/
export function GetGivingCourse(data) {
    return myRequest('/office/Lesson/', 'getList', data)
}

/**
 * 素材中心-赠送课程-赠送记录
 **/
export function GetGivingRecord(data) {
    return myRequest('/office/Giving/', 'getLog', data)
}

let userUrlPre = "/office/User/"

/**
 * 个人信息
 **/
export function GetUserinfo() {
    return myRequest(userUrlPre, 'getInfo')
}

/**
 * 个人信息
 **/
export function GetPosterData() {
    return myRequest('/office/User/', 'posterData')
}

/**
 * 修改个人信息
 * @param data:array  {name:'',phone:'',wechat:'',intro:''}   string
 **/
export function EditUserinfo(data) {
    return myRequest(userUrlPre, 'editInfo', data)
}

/**
 * 家长列表
 **/
export function GetParentsList(data) {
    return myRequest(userUrlPre, 'getList', data)
}

/**
 * 获取用户列表 - 用户详情中的数据
 * 4-用户详情；0-购买记录；1-学习数据；2-学习进度；3-用户评论
 * **/
export function getUserDetailData(i, data) {
    let arr = ['getOrderList', 'getStudyChart', 'getStudyProcess', '', 'getDetail'];
    if (i === 3) {
        return myRequest('/office/Comment/', 'getList', data)
    }
    return myRequest(userUrlPre, arr[i], data)
}

/**
 * 家长用户详情 - 修改姓名
 **/
export function EditParentsInfo(data) {
    return myRequest(userUrlPre, 'editParentsInfo', data)
}

let tagPreUrl = '/office/UserTag/'

/**
 * 用户列表 - 标签列表   传入 this 如果state有就不用请求接口
 */
export function GetUserTagList(data) {
    return myRequest(tagPreUrl, 'getList', data)
}

/**
 * 标签管理 - 删除标签
 */
export function DelUserTag(data) {
    return myRequest(tagPreUrl, 'del', data)
}

/**
 * 标签管理 - 添加 或 修改标签
 */
export function AddOrEditUserTag(data) {
    return myRequest(tagPreUrl, data.id ? 'edit' : 'add', data)
}

/**
 * 设置标签
 */
export function SetUserTag(data) {
    return myRequest(tagPreUrl, 'setTag', data)
}

/**
 * 权限申诉 - 权限申请
 */
export function OpenService(data) {
    return myRequest('/office/OfficeServiceLessonApply/', 'apply', data)
}

/**
 * 服务课开通记录
 */
export function OpenServiceLog(data) {
    return myRequest('/office/OfficeServiceLessonApply/', 'getLog', data)
}

let permissionUrl = '/office/OfficeConflictApply/'

/**
 *  权限申诉 - 冲突申诉
 * **/
export function GetParentSearchRes(data) {
    return myRequest(permissionUrl, 'retrieve', data)
}

/**
 *  权限申诉 - 提交申诉
 * **/
export function SubmitAppeal(data) {
    return myRequest(permissionUrl, 'submit', data)
}

/**
 *  权限申诉 - 申诉记录
 * **/
export function AppealRecord(data) {
    return myRequest(permissionUrl, 'getLog', data)
}

/**
 * 使用教程、首页 - 公告
 */
export function GetAnnouncement(data) {
    return myRequest('/office/OfficeMsg/', 'getList', data)
}

/**
 * 使用教程 - 公告 - 公告详情
 */
export function GetAnnouncementDetail(data) {
    return myRequest('/office/OfficeMsg/', 'detail', data)
}

/**
 * 使用教程 - 图文教程
 */
export function GetVideoCourse(data) {
    return myRequest('/office/VideoCourse/', 'getList', data)
}

/**
 * 使用教程 - 图文教程 - 查看 详情
 */
export function GetVideoCourseDetail(data) {
    return myRequest('/office/VideoCourse/', 'detail', data)
}

/**
 * 使用教程 - 直播培训
 */
export function GetLiveData(data) {
    return myRequest('/office/Live/', 'getList', data)
}

/**
 * 使用教程 - 直播培训 - 详情
 */
export function GetLiveDetail(data) {
    return myRequest('/office/Live/', 'detail', data)
}

/**
 * 使用教程 - 直播培训 - 详情 - 获取聊天记录
 */
export function GetLiveMsgLog(data) {
    return myRequest('/office/Live/', 'getMsgLog', data)
}

/**
 * 使用教程 - 直播培训 - 详情 - 签到
 */
export function LiveSignIn(data) {
    return myRequest('/office/Live/', 'signIn', data)
}

/**
 * 使用教程 - 直播培训 - 详情 - 获取签到列表
 */
export function GetLiveSignList(data) {
    return myRequest('/office/Live/', 'getSignList', data)
}

/**
 * 使用教程 - 通讯录
 */
export function GetContacts(data) {
    return myRequest('/office/Contact/', 'getList', data)
}

/**
 * 使用教程 - 意见反馈
 */
export function GetFeedback(data) {
    return myRequest('/office/Feedback/', 'submit', data)
}

/**
 * 订单管理 - 订单列表
 */
export function GetOrdersList(i, data) {  // i:  0 知识商品  1 实物商品
    return myRequest('/office/Order/', 'getList', data)
}

/**
 * 订单管理 - 实物商品 - 查询物流信息
 */
export function GetLogistics(data) {
    return myRequest('/office/Order/', 'queryTracking', data)
}

/**
 * 订单管理 - 报名信息
 */
export function GetEnrollList(data) {
    return myRequest('/office/CollectForm/', 'getListForCons', data)
}

/**
 * 订单管理 - 报名信息 - 确认已联系
 */
export function ConfirmContact(data) {
    return myRequest('/office/CollectForm/', 'setIsContact', data)
}

/**
 * 作业系统 - 家长作业-线下课程分类
 */
export function GetTaskClassifyList(data) {
  return myRequest('/office/Homework/', 'classifyList', data)
}

/**
 * 作业系统 - 家长作业 或者 作业批改 列表
 */
export function GetTaskSystemList(i, data) {
    return myRequest('/office/Homework/', i === 0 ? 'getList' : 'getCorrectionList', data)
}

/**
 * 作业系统 - 家长作业 - 作业详情 / 作业批改 - 查看详情
 */
export function GetTaskSystemDetail(data) {
    return myRequest('/office/Homework/', 'detail', data)
}

/**
 * 作业系统 - 作业批改 - 查看详情 - 提交批阅
 */
export function SubmitCorrect(data) {
    return myRequest('/office/Homework/', 'correct', data)
}


/**
 * IM - 联系人 - 地区列表
 */
export function IMgetAreaList() {
    return myRequest('/office/Im/', 'getAreaList')
}

/**
 * IM - 联系人 - 用户列表  或者 搜索用户
 */
export function IMgetUserListByAreaId(data) {
    return myRequest('/office/Im/', 'getUserListByAreaId', data)
}

/**
 * IM - 联系人 - 最近联系人
 */
export function IMgetRecentContactedList() {
    return myRequest('/office/Im/', 'getRecentContactedList')
}

/**
 * IM - 和某人的聊天记录
 */
export function IMgetMsgLog(data) {
    return myRequest('/office/Im/', 'getMsgLog', data)
}

/**
 * 设置备注 - 用户详情 / 订单 / 报名信息 中
 */
export function SubmitRemark(data) {
    return myRequest('/office/Remark/', 'setRemark', data)
}

/**
 * 数据中心/地区数据 - 推广分析 - 折线图
 */
export function GetPromotionChart(data) {
    return myRequest('/office/DataCenter/', 'spreadTrend', data)
}

/**
 * 数据中心/地区服务 - 数据分析 - 销售分析 - 折线图
 */
export function GetSalesChart(data) {
    return myRequest('/office/DataCenter/', 'saleTrend', data)
}

/**
 * 数据中心 - 销售分析 - 表格
 */
export function GetDataCenterSalesTable(data) {
    return myRequest('/office/DataCenter/', 'saleTable', data)
}

/**
 * 数据中心/地区服务 - 数据分析 - 用户分析 - 折线图
 */
export function GetUsersChart(data) {
    return myRequest('/office/DataCenter/', 'userTrend', data)
}

/**
 * 数据中心 - 用户分析 - 表格
 */
export function GetDataCenterUsersTable(data) {
    return myRequest('/office/DataCenter/', 'userTable', data)
}

/**
 * 数据中心/地区服务 - 数据分析 - 会员分析 - 折线图
 */
export function GetVipChart(data) {
    return myRequest('/office/DataCenter/', 'memberTrend', data)
}

/**
 * 地区服务 - 数据分析 - 推广分析 - 表格
 */
export function GetAreaPromotionTable(data) {
    return myRequest('/office/DataCenter/', 'areaSpreadTable', data)
}

/**
 * 地区服务 - 数据分析 - 销售分析 - 表格
 */
export function GetAreaSalesTable(data) {
    return myRequest('/office/DataCenter/', 'areaSaleTable', data)
}

/**
 * 地区服务 - 数据分析 - 用户分析 - 表格
 */
export function GetAreaUsersTable(data) {
    return myRequest('/office/DataCenter/', 'areaUserTable', data)
}

/**
 * 地区服务 - 地区课程 - 聊天室笔记列表
 */
export function GetLiveAdminNotebook(data) {
    return myRequest('/office/LiveAdminNotebook/', 'getList', data)
}

/**
 * 地区服务 - 地区课程 - 聊天室笔记列表 - 添加
 */
export function AddLiveAdminNotebook(data) {
    return myRequest('/office/LiveAdminNotebook/', 'add', data)
}

/**
 * 地区服务 - 地区课程 - 聊天室笔记列表 - 修改
 */
export function EditLiveAdminNotebook(data) {
    return myRequest('/office/LiveAdminNotebook/', 'edit', data)
}

/**
 * 地区服务 - 地区课程 - 聊天室笔记列表 - 删除
 */
export function DeleteLiveAdminNotebook(data) {
    return myRequest('/office/LiveAdminNotebook/', 'delete', data)
}

/**
 * 地区服务 - 地区课程 - 直播地区列表
 */
export function GetAreaList(data) {
    return myRequest('/office/Live/', 'getAreaList', data)
}

/**
 * 地区服务 - 地区课程 - 直播/回放列表
 */
export function GetAreaLiveList(data, i) {
    return myRequest('/office/Live/', i ? 'getRecordList' : 'getAreaLiveList', data)
}

/**
 * 地区服务 - 地区课程 - 回放列表 - 删除回放/地区直播
 */
export function DeleteLiveData(data,i) {
    return myRequest('/office/Live/', i?'deleteRecord':'deleteAreaLive', data)
}

/**
 * 地区服务 - 地区课程 - 创建/修改地区直播
 */
export function ModifyAreaLive(data, str) {
    return myRequest('/office/Live/', str + 'AreaLive', data)
}

/**
 * 地区服务 - 地区课程 - 讲师
 */
export function GetTeacherList(data) {
    return myRequest('/office/Teacher/', 'getList', data)
}
