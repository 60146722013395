<template>
    <!-- 赠送课程 -->
    <div v-if="i === 0" class="flex-div giving-course-element">
        <img :src="columnData.img">
        <div>
            <span style="margin-bottom: 22px;">{{columnData.title}}</span>
            <span>主讲：{{columnData.teacher_name}}</span>
        </div>
    </div>
    <!-- 用户列表 -->
    <div v-if="i === 1" class="flex-div icon-name">
        <img :src="columnData.icon" alt="">
        <span>{{columnData.nickname}}</span>
    </div>
</template>

<script>
    export default {
        name: "tableSlots",
        props: ['i', 'columnData'],
        mounted() {
            // console.log(this.columnData)
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .giving-course-element div {
        width: 66%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
            color: #666666;
        }

        span:nth-child(1) {
            .text-over-hidden(99%)
        }
    }

    .giving-course-element img {
        .my-img-wh-mr-bradius(@w: 139px,@h: 78px,@mr: 54px,@b-radius: 5px)
    }

    .icon-name img {
        .my-img-wh-mr-bradius(@w: 37px,@h: 37px,@mr: 6px,@b-radius: 50%)
    }
</style>