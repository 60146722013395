<template>
    <task-system-template :homework-or-correct=1></task-system-template>
</template>

<script>
    import TaskSystemTemplate from "./taskSystemTemplate.vue";

    export default {
        name: "correct",
        components: {TaskSystemTemplate},
    }
</script>

<style scoped>

</style>