<template>
    <div>
        <small-title detail-title="已发推广素材" style="margin-top: 32px;margin-bottom: 28px;"></small-title>
        <!-- <my-table :column-num="userListTableTitles.length" :table-titles="userListTableTitles" :table-props="userListTableProps"
                  :table-data="userListTableData"></my-table> -->
                  <el-table
                    :data="userListTableData"
                    style="width: 100%">
                    <el-table-column v-for="i in userListTableTitles.length" :key="i" :label="userListTableTitles[i-1]" :prop="userListTableProps[i-1]"
                                 align="center">
                    </el-table-column>
                  </el-table>
                  <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" 
                       :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
    </div>
</template>

<script>
    import {myTable, smallTitle} from "@/components";
    import {GetUserList} from "@/api";
    export default {
        name: "dataUserList",
        components: {myTable, smallTitle},
        data(){
            return {
                userListTableTitles: ['用户昵称','学号', '姓名', '电话', '查看时间'],
                userListTableProps: ['nickname', 'sid', 'name', 'phone', 'time'],
                userListTableData: [
                //   {
                //     user: {avatar: '../../assets/temp-avatar.jpg', name: '信念的爸爸'},
                //     // user:'头像+名字',
                //     sid: '21458',
                //     name: '名字',
                //     phone: '11254369870',
                //     time: '2019-12-02  14:21'
                // }
                ],
                pageInfo: {}
            }
        },
        mounted(){
            this.getData()
        },
        destoryed() {

        },
        methods: {
            getData() {
                GetUserList({
                    id: this.$route.query.id
                    // id: 1
                }).then(res => {
                    console.log(res);
                    this.pageInfo = res.data
                    var result = res.data.page_data
                    if (result.length) {
                        result.forEach(item => {
                            this.userListTableData.push({
                                user: {
                                    icon: item.icon,
                                    name: item.nickname
                                },
                                sid: item.student_id,
                                name: item.name,
                                phone: item.phone,
                                time: item.add_time
                            })
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
</style>