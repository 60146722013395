<template>
    <promotion :choice-index="1"></promotion>
</template>

<script>
    import promotion from './components/promotion.vue'

    export default {
        name: "promotionPoster",
        components: {
            promotion,
        },
    }
</script>

<style lang="less" scoped></style>