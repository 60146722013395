<template>
    <div class="login-wrap" style="width: 100vw;height:100vh">
        <img class="bg-img" src="../assets/login_bg_color.png" style="width: 100vw;height:100vh" alt="">
        <div class="login-main">
            <div class="login-l">
                <span style="font-size: 46px;margin-top: 9px;">博瑞智微课堂个人办公</span>
                <span style="font-size: 30px;margin-top: 35px;margin-bottom: 101px;">助力顾问成长成功</span>
                <img style="width: 48vw;height: auto;" src="../assets/login_bg_img.png" alt="">
            </div>
            <div class="login-r-electron flex-div" v-if="isElectron">
                <img class="bg-phone" src="../assets/bg_phone.png" alt="">
                <div class="qrcode-wrap">
                    <div class="qrcode" ref="qrcodeContainer"></div>
                    <span style="display: block;margin-top: 40px;color: #7D7D7D;font-size: 26px;">微信扫码登录</span>
                </div>
            </div>
            <div class="login-r" v-else>
                <div class="qrcode" ref="qrcodeContainer"></div>
                <span style="display: block;margin-top: 40px;color: #7D7D7D;font-size: 26px;">微信扫码登录</span>
                <span style="display: block;margin-top: 88px;color: #8F8F8F;font-size: 24px;">下载客户端获得更好体验</span>
                <div class="download-div">
                    <div class="lr-div">
                        <div class="flex-div">
                            <img src="../assets/login_download_desktop.png" alt="">
                            <span>Windows</span>
                        </div>
                        <div class="click-div" :class="activeWin?'click-div-active':''" @click="download('windows')">
                            点击下载
                        </div>
                    </div>
                    <div class="lr-div">
                        <div class="flex-div">
                            <img src="../assets/login_download_ios.png" alt="">
                            <span>macOS</span>
                        </div>
                        <div class="click-div" :class="activeMac?'click-div-active':''" @click="download('macOS')">
                            点击下载
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span class="bottom-span">© 2021 博瑞智家庭教育 西安团队</span>
    </div>
</template>

<script>
    import {GetLoginQRCode, GetLoginResult, GetSomeInfo, GetUserTagList} from "@/api";
    import {GetAllUserTagList, getLocalStorage, getQRCode, setLocalStorage} from "@/utils";

    export default {
        name: "login",
        data() {
            return {
                activeWin: false,
                activeMac: false,
                isElectron: false,
                timer: 0
            }
        },
        computed: {
            w() {
                return window.innerWidth
            },
            h() {
                return window.innerHeight
            },
        },
        mounted() {
            let that = this;
            that.isElectron = require('is-electron')()
            const token = getLocalStorage('token')

            if (token) {
                that.$store.commit('setLoginUserinfo', JSON.parse(getLocalStorage('loginUserinfo')))
                that.$router.push('/overview')
            } else
                GetLoginQRCode().then(response => {
                    that.$nextTick(() => {
                        getQRCode(that.$refs.qrcodeContainer, 220, 220, response.data.url)
                        that.timer = setInterval(() => {
                            that.getRes(response.data.qr_code)
                        }, 2000)
                    })
                })
        },
        methods: {
            getRes(code) {
                let that = this;
                if (!that.timer)
                    return
                GetLoginResult({code}).then(response => {
                    clearInterval(that.timer)
                    GetSomeInfo().then((res) => {
                        console.log(res);
                        let temp = {};
                        Object.assign(temp, res.data.service_level)
                        let tempKeys = Object.keys(res.data.service_level)
                        res.data.service_level = {}
                        tempKeys.forEach((k, i) => {
                            res.data.service_level[i] = {value: k, label: temp[k]}
                        })
                        setLocalStorage('someInfo', JSON.stringify(res.data))
                        that.$store.dispatch('setSomeInfo', res.data)
                    })
                    GetAllUserTagList(that).then((res) => {
                        that.$store.commit('setUserTags', res)
                    })
                    setLocalStorage('token', response.data.token)
                    setLocalStorage('loginUserinfo', JSON.stringify({...response.data}))
                    that.$store.commit('setToken', response.data.token)
                    that.$store.commit('setLoginUserinfo', {...response.data})
                    that.$router.push('/overview')
                }).catch((e) => {
                    console.log(e)
                })
            },

            download(w) {
                let url;
                if (w === 'windows') {
                    this.activeWin = true;
                    url = 'https://brz-mini.oss-cn-beijing.aliyuncs.com/file/brz-office-windows-Setup%201.0.0.exe';
                } else {
                    this.activeMac = true;
                    url = 'https://brz-mini.oss-cn-beijing.aliyuncs.com/file/brz-office-mac.dmg';
                }
                location.href = url;
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .login-wrap {
        position: relative;
        overflow: hidden;

        .bg-img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .login-main {
        position: absolute;
        top: 66px;
        left: 5vw;
        right: 5vw;
        bottom: 0;
        display: flex;
        justify-content: space-between;

        .login-l {
            color: #FFFFFF;

            span {
                display: block;
                margin-left: 164px;
            }
        }

        .login-r-electron,
        .login-r {
            background: #FFFFFF;
            text-align: center;
            box-shadow: 0 15px 50px 0 rgba(103, 182, 206, 0.27);
            border-radius: 20px;
        }

        .login-r {
            width: 36vw;
            height: 88vh;
            padding: 10vh 6vw 6vh 6vw;
            box-sizing: border-box;

            .qrcode {
                margin: 0 auto;
                width: 250px;
                height: 250px;
                background-color: #f2f2f2;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 10px;
            }

            .download-div {
                margin-top: 80px;
                .flex-div();
                justify-content: space-between;

                span {
                    font-size: 20px;
                    color: #5F5F5F;
                }

                img:first-child {
                    .my-img-wh-mr-bradius(@w: 50px,@h: 50px,@mr: 26px)
                }

                img:last-child {
                    .my-img-wh-mr-bradius(@w: 55px,@h: 55px,@mr: 26px)
                }

                .click-div {
                    margin-top: 40px;
                    padding: 12px 32px;
                    border: 2px solid #4D74D8;
                    border-radius: 10px;

                    font-size: 18px;
                    color: #4D74D8;
                    cursor: pointer;
                }

                .click-div-active {
                    background: #406AD5;
                    color: #FFFFFF;
                }
            }
        }

        .login-r-electron {
            position: relative;
            background: none;
            box-shadow: none;
            width: 25vw;
            height: 80vh;

            .bg-phone {
                position: absolute;
                width: 25vw;
                height: auto;
            }

            .qrcode-wrap {
                position: absolute;
                z-index: 99;
                width: 25vw;
                margin: 0 auto;
                .flex-div();
                flex-direction: column;
                justify-content: center;

                // .qrcode {
                // }

                // span {
                // }
            }
        }
    }

    .bottom-span {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 22px;
        color: #FFFFFF;
    }

</style>