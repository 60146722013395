<template>
    <div id="liveApp" class="flex flex-row layui-bg-white" v-cloak>
        <!-- 左侧-->
        <div class="left" v-if="data.live">
            <!-- 标题 -->
            <div class="flex flex-row flex-item-center padt10 padb10" v-if="data.live">
                <div><img
                        :src="data.live.teacher_icon"
                        class="icon" alt=""></div>
                <div class="padl12">
                    <div class="txt-bold">
                        <span>{{data.live.title}} </span>
                        <span v-if="data.live.status===2" class="green-badge">正在直播...</span>
                        <span v-if="data.live.status===1" class="green-badge">距离直播开始还剩: {{timerText}}</span>
                        <span v-if="data.live.status===0 && data.live.record_url===''" class="red-badge">直播已结束</span>
                        <span v-if="data.live.status===0 && data.live.record_url!==''" class="red-badge">直播回放</span>
                    </div>
                    <div class="">
                        <span>主讲: {{data.live.teacher_name}}</span>
                        <span class="marl10 padl12">时间: {{data.live.start_time}}</span>
                    </div>
                </div>
            </div>
            <!-- 直播/回放 -->
            <video
                    autoplay
                    id="video" oncontextmenu="return false;"
                    v-if="data.live.status===2 || (data.live.status===0 && data.live.record_url!=='')"
                    class="video-js vjs-default-skin vjs-big-play-centered video-area" controls>
                <!--controlsList="nodownload"-->
                <source :src="playUrl" v-if="data.live.status===0">
                <source :src="playUrl" type="application/mpegURL" v-if="data.live.status===2">
            </video>
            <!-- 倒计时 -->
            <div class="video-area"
                 v-if="data.live.status===1">
                <img src="http://h5.dongjinyu.com/icon/20191213151012_5df33954164d3.jpg" alt="">
            </div>
            <!-- 已结束 -->
            <div class="video-area" v-if="data.live.status===0 && data.live.record_url===''">
                <img src="http://h5.dongjinyu.com/icon/20191213151032_5df339688bd07.jpg" alt="">
            </div>
            <!-- 签到按钮 -->
            <img src="http://h5.dongjinyu.com/icon/20191211153240_5df09b98182b9.png" alt="" class="sign-btn pointer"
                 @click="signIn" v-if="data.live.type===3">
            <!-- 打赏按钮 -->
            <!--<img src="http://h5.dongjinyu.com/icon/20191213151417_5df33a49a368c.png" class="sign-btn award-btn pointer" alt=""> -->
            <!-- 签到列表 -->
            <div class="sign-list" v-show="showSignList">
                <div class="head flex flex-row flex-bet padt15 padb15 border-bottom">
                    <span>签到排名({{signCount}})</span><span
                        class="close pointer" @click="showSignList=false">x</span></div>
                <div class="user-list" @scroll="signListScroll" id="sign-list-box">
                    <div v-for="(i,k) in signListData" class="flex flex-row flex-item-center padt10 padb10">
                        <div style="flex:.1;" class="flex flex-center flex-item-center">
                            <span v-if="k>2" class="font-bold">{{k+1}}</span>
                            <img class="honor" src="http://h5.dongjinyu.com/icon/20191213113207_5df30637769cd.png"
                                 v-if="k===0" alt="">
                            <img class="honor" src="http://h5.dongjinyu.com/icon/20191211163713_5df0aab9af470.png"
                                 v-if="k===1" alt="">
                            <img class="honor" src="http://h5.dongjinyu.com/icon/20191211163725_5df0aac5874c9.png"
                                 v-if="k===2" alt="">
                        </div>
                        <div class="flex flex-row" style="flex:.9;">
                            <div class="padl12 padr12"><img :src="i.icon" class="icon" alt=""></div>
                            <div class="flex flex-col font14">
                                <span class="max-line-1">{{i.name}}</span>
                                <span class="txt-gray font12">{{i.add_time}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 右侧-->
        <div class="right flex flex-col flex-bet text-center">
            <div class="tab flex flex-row flex-aro flex-start">
                <div style="text-align:start;padding-left: 10px;">讨论区</div>
                <!--<div :class="{active:tab==2}" @click="tab=2">成员({{audienceCount}})</div>-->
            </div>
            <!--消息列表 -->
            <div class="msg-list padl12 padr12 padb10" id="msg-list" @scroll="scrollEvent">
                <div class="msg-item  flex flex-row flex-col padt10" v-for="i in msgList"
                     :class="i.uid !== data.user.id+''?'flex-item-start':'flex-item-end'">
                    <div class="user padb10 flex flex-row flex-item-center" v-if="i.type!=='reward'">
                        <img :src="i.client_icon" class="icon" alt="">
                        <span class="marl5">{{i.from_client_name}}</span>
                    </div>
                    <!--  文字消息-->
                    <div class="text padt10 padb10 padl12 padr12 txt-word-break text-start"
                         v-if="i.type==='say'" v-html="i.content"></div>
                    <!-- 图片消息 -->
                    <div v-if="i.type==='img'"><img :src="i.content" alt="" style="width: 200px;display: block;"
                                                    @click="showImgModal(i.content,700)"></div>
                    <!-- 打赏消息 -->
                    <div v-if="i.type==='reward'" class="padt10 padb10" style="color: red;font-weight: bold">{{i.msg}}
                    </div>
                </div>
            </div>
            <!-- 聊天室在线用户列表 -->
            <!--<div class="msg-list padr12 padr12 padb10 padt10" v-show="tab==2">
                    <div class="flex flex-row flex-item-center padl12 padr12 padt5 padb5" v-for="(i,k) in userList">
                        <div><img
                                :src="i.client_icon"
                                class="icon" alt=""></div>
                        <div class="padl12">{{i.client_name}}</div>
                    </div>
                </div>
                -->
            <!-- 发送框 -->
            <div class="input-box padl12 padr12">
                <textarea placeholder="输入文字消息" ref="myInput" v-model="inputText" @keyup.enter="send"></textarea>
                <!--<textarea placeholder="输入文字消息" ref="myInput" v-model="inputText" @keyup.enter="send">{{inputText}}</textarea>-->
                <button @click="send">发送</button>
            </div>
        </div>
    </div>
</template>

<script>
    // import videojsflash from 'videojs-flash'
    import {GetLiveDetail, GetLiveMsgLog, GetLiveSignList, LiveSignIn} from '@/api'
    import {getPageInfo} from '@/utils'
    import videojs from 'video.js'

    export default {
        name: "livingDetail",
        data() {
            return {
                data: {},
                //tab: 1,
                playUrl: '',
                timerText: '',//倒计时
                ws: null,
                userList: [],//用户列表
                msgList: [],//消息列表
                inputText: '',//输入消息内容
                audienceCount: 0,//人数
                signListLoadingComplete: false,//签到列表是否加载完毕
                signListData: [],//签到列表
                signListPage: 1,//签到列表分页
                showSignList: false,//是否显示签到列表
                isSigned: false,//是否已经签到
                signCount: 0,//已签到人数
                // 聊天记录信息
                msgLogPageInfo: {}
            }
        },
        mounted() {
            // this.temp = this.msgList
            this.initData();
        },
        computed: {
            scrollEvent() {
                let that = this
                let msg = document.getElementById('msg-list')
                // let vh = msg.clientHeight
                let h1;
                msg.onscroll = () => {
                    h1 = msg.scrollHeight
                    if (msg.scrollTop === 0) {
                        if (that.msgLogPageInfo.current_page < that.msgLogPageInfo.last_page) {
                            GetLiveMsgLog({page: that.msgLogPageInfo.current_page + 1, ...that.$route.params}).then((res) => {
                                that.msgList = res.data.page_data.reverse().concat(that.msgList)
                                that.msgLogPageInfo = getPageInfo(res.data)
                                setTimeout(() => {
                                    msg.scrollTop = msg.scrollHeight - h1
                                }, 0)
                            })
                        } else
                            console.log("加载完成，没有更多数据了")
                    }
                }
            },
        },
        methods: {
            // scrollEvent(e){
            //     console.log(e)
            // },

            //获取数据
            initData: function () {
                let that = this;
                GetLiveDetail(that.$route.params).then((res) => {
                    that.setData(res.data)
                })
            },
            //处理数据
            setData: function (ret) {
                let self = this;
                self.data = ret;
                self.setWs();
                const status = ret.live.status;
                if (status === 2 || (status === 0 && ret.live.record_url !== '')) {
                    if (status === 2) {
                        self.playUrl = ret.live.play_url;
                    } else {
                        self.playUrl = ret.live.record_url;
                    }
                    setTimeout(function () {
                        const player = videojs('video', {
                            autoplay: true,
                        }, function () {
                            console.log('videojs播放器初始化成功');
                        });
                        player.play();
                    }, 10);
                } else if (status === 1) {
                    secondsTimeFormatExecuteTimer(ret.live.timeout, function (arr) {
                        const d = arr[0];
                        const h = arr[1];
                        const m = arr[2];
                        const s = arr[3];
                        let str = h + ':' + m + ':' + s;
                        if (d) {
                            str = d + '天' + str;
                        }
                        self.timerText = str;
                    }, function () {
                        self.data.live.status = 2;
                    }, false)
                }
            },
            //建立ws
            setWs: function () {
                this.getMessageLog();
                this.ws = new WebSocket('wss://wss.dongjinyu.com');
                this.ws.onopen = this.onWsOpen;
                this.ws.onmessage = this.onWsMessage;
            },
            //ws连接建立
            onWsOpen: function () {
                const that = this
                const data = JSON.stringify({
                    type: 'login',
                    uid: this.data.user.id,
                    client_name: this.data.user.nickname,
                    client_icon: this.data.user.icon,
                    room_id: that.$route.params.id,
                    is_presenter: '0',
                    is_vip_user: this.data.user.is_member ? '1' : 0,
                });
                this.ws.send(data);
            },
            //ws监听消息
            onWsMessage: function (e) {
                const data = JSON.parse(e.data);
                switch (data.type) {
                    case 'ping':
                        this.ws.send('');
                        break;
                    case 'login' :
                        this.userList = data.client_list;
                        this.audienceCount = data.real_audience_count;
                        break;
                    case 'logout':
                        this.userList = data.client_list;
                        this.audienceCount = data.real_audience_count;
                        break;
                    case 'say':
                        this.msgList.push(data);
                        this.scrollToBottom();
                        break;
                    case 'img':
                        this.msgList.push(data);
                        this.scrollToBottom();
                        break;
                    case 'reward':
                        this.msgList.push({
                            type: 'reward',
                            content: data.msg
                        });
                        this.scrollToBottom();
                        break;
                }
            },
            //发送消息
            send: function () {
                const msg = this.inputText.trim();
                if (msg !== '') {
                    this.ws.send(JSON.stringify({
                        type: 'say',
                        uid: this.data.user.id,
                        to_client_id: 'all',
                        to_client_name: '',
                        content: msg
                    }));
                    this.inputText = '';
                }
            },
            //获取聊天记录
            getMessageLog: function () {
                const self = this;
                GetLiveMsgLog(self.$route.params).then((ret) => {
                    let r = ret.data.page_data;
                    self.msgList = r.reverse()
                    self.msgLogPageInfo = getPageInfo(ret.data)
                    self.scrollToBottom();
                })
            },
            //消息列表自动滚动
            scrollToBottom: function () {
                let e = document.getElementById('msg-list')
                // console.log(e.scrollTop)
                // console.log(e.scrollHeight)
                setTimeout(() => {
                    e.scrollTop = e.scrollHeight
                }, 0)
                // scrollToBottom('msg-list')
            },
            //打卡
            signIn: function () {
                const that = this;
                that.showSignList = true;
                that.getSignList();
                that.isSigned = true;
                LiveSignIn(that.$route.params).then((res) => {
                    that.$message.success(res.data)
                }).catch((e) => {
                    that.$message.info(e.msg)
                })
            },
            //获取签到列表
            getSignList: function () {
                const that = this;
                GetLiveSignList({...that.$route.params, page: that.signListPage}).then((res) => {
                    that.signCount = res.data.total;
                    if (that.signListPage <= res.data.last_page) {
                        that.signListData = that.signListData.concat(res.data.page_data);
                        that.signListPage += 1;
                    }
                })
            },
            //签到列表滚动
            signListScroll: function () {
                const box = document.getElementById('sign-list-box');
                const scroll = box.scrollTop;
                const height = 250;
                const totalHeight = box.scrollHeight;
                if (height + scroll + 20 >= totalHeight) {
                    this.getSignList();
                }
            }
        }
    }
</script>

<style scoped>
    @import "../../styles/livingCommon.css";

    html, body {
        background: #fff;
    }

    #liveApp {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    .tab {
        background: #F2F2F2;
        height: 58px;
    }

    .tab > div {
        font-weight: 900;
        font-size: 16px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 10px 0;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background: #fff;
    }

    .tab > div.active {
        background: #F2F2F2;
    }

    .left {
        width: calc(100% - 340px);
        box-sizing: border-box;
        position: relative;
    }

    .right {
        background: #F2F2F2;
        width: 340px;
        height: 100%;
    }

    .video-area {
        width: 100%;
        height: calc(100% - 58px);
    }

    .video-area img {
        width: 100%;
        height: 100%;
    }

    .msg-list {
        overflow-y: scroll;
        background: #F2F2F2;
        flex: .9;
    }

    .msg-item .text {
        background: #fff;
        border-radius: 8px;
    }

    .input-box {
        background: #fff;
        flex: .1;
        padding-top: 14px;
        border-top: 1px solid #eee;
    }

    .input-box textarea {
        height: 100px;
        background: #F2F2F2;
        border: none;
        display: block;
        width: 100%;
        border-radius: 6px;
        padding: 4px;
        resize: none;
    }

    .input-box button {
        color: #fff;
        background: red;
        border: none;
        outline: none;
        margin: 12px 0;
        padding: 8px 0;
        min-width: 65%;
        border-radius: 30px;
    }

    .sign-btn {
        height: 50px;
        position: absolute;
        bottom: 55px;
        right: 2%;
        display: block;
    }

    .award-btn {
        right: 25%;
        height: 90px;
        bottom: 35px;
    }

    .sign-list {
        position: fixed;
        bottom: 140px;
        background: #fff;
        height: 300px;
        padding: 0 15px;
        width: 300px;
        right: 20%;
        border-radius: 10px;
        box-sizing: border-box;
    }

    .sign-list .user-list {
        overflow-y: scroll;
        height: calc(100% - 50px);
    }

    .sign-list:before {
        content: '';
        display: block;
        border: 20px solid transparent;
        width: 0;
        height: 0;
        position: absolute;
        bottom: -40px;
        right: 25px;
        border-top-color: #fff;
        border-right-width: 0px;
        border-left-width: 50px;
    }

    .sign-list .user-list .honor {
        width: 26px;
        height: 26px;
        display: block;
    }

    .sign-list .head {
        border-bottom: 1px solid #eee;
    }

    .sign-list .head .close {
        padding-left: 25px;
        display: block;
    }

    .red-badge {
        color: #fff;
        background: red;
        border-radius: 5px;
        font-size: 12px;
        padding: 2px 6px;
    }

    .green-badge {
        color: #fff;
        background: #0ccf88;
        border-radius: 5px;
        font-size: 12px;
        padding: 2px 6px;
    }
</style>