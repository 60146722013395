<template>
    <div class="resource-wrap" v-if="!showView">
        <title-underline-choice :choice-index=3 :choices="choices" @getChoice="makeChoice"></title-underline-choice>
        <div class="flex-div filter-creation-giving-course">
            <div class="event-div" @click="toCreate">
                <img src="../../assets/icon_creation.png">
                <span>我要创作</span>
            </div>
            <div class="search-div">
                <el-input placeholder="输入素材名称" v-model="searchKeyword"></el-input>
                <el-button @click="getData">搜索</el-button>
            </div>
        </div>
        <my-table :column-num="columnNum" :table-titles="tableTitles" :table-props="tableProps" :table-data="tableData"
                  :page-info="pageInfo" :actions="actions" @makeActionChoice="getActionChoice" @pageIndexChange="changePageIndex"></my-table>
        <my-masks v-if="showQRCode" :pos="2" :qrcode-info="qrcodeInfo" @closeMask="showQRCode=false"></my-masks>
    </div>
    <router-view v-else></router-view>
</template>

<script>
    import {DeleteCreationData, GetOneTagList} from "@/api";
    import {myMasks,titleUnderlineChoice,myTable,} from "@/components";
    import {getLoading, getLocalStorage, getPageInfo} from "@/utils";

    export default {
        name: "myCreation",
        components: {myMasks,titleUnderlineChoice,myTable,},
        data() {
            return {
                choices:['推广好文','推广海报','赠送课程','我的创作'],
                searchKeyword: '',

                columnNum: 3,
                tableTitles: ['标题', '添加时间', '描述'],
                tableProps: ['title', 'add_time', 'content'],
                tableData: [],
                pageInfo:{},
                actions:['立即推广','修改','删除'],
                // 立即推广
                showQRCode: false,  // 展示二维码弹框
                qrcodeInfo: {tit: '立即推广'}
            }
        },
        mounted(){
            this.searchKeyword = ""
            this.getData()
        },
        computed:{
            showView(){
                return this.$route.matched.length === 3;
            }
        },
        methods: {
            getData(curPage){
                let that = this
                let loading = getLoading(that,'.resource-wrap')
                let temp = {}
                if(that.searchKeyword)
                    temp['title'] = that.searchKeyword
                if(curPage)
                    temp['page'] = curPage
                GetOneTagList({type: 1,...temp}).then((res) => {
                  console.log(res);
                    if (res.data.page_data && res.data.page_data .length) {
                      that.tableData = res.data.page_data
                      that.pageInfo = getPageInfo(res.data)
                    }
                    loading.close()
                })
            },

            changePageIndex(curPage){
                this.getData(curPage)
            },

            makeChoice(i) {
                this.$router.push(this.$router.options.routes[3].children[i].path)
            },

            getActionChoice(item,ai) {
                let that = this
                if(ai === 0) {
                    if(!this.$store.state.promotionCodeUrl){
                        this.$store.commit('setSomeInfo', JSON.parse(getLocalStorage('someInfo')))
                    }
                    let temp = this.$store.state.loginUserinfo.encrypted_uid
                    this.qrcodeInfo.url = `${this.$store.state.promotionCodeUrl}?id=${item.id}&from_uid=${temp}&origin_from_uid=${temp}`
                    this.showQRCode = true
                }
                else if(ai === 1){
                    that.$router.push({name:'createPage',params:{id:item.id}})
                }
                else{
                    this.$confirm('确定要删除吗?', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消'
                    }).then(() => {
                          DeleteCreationData({id:item.id}).then((response) => {
                              that.$message.success(response.data)
                              that.getData()
                          }).catch((e)=>{
                              this.$message.info(e.msg)
                          })
                      })
                      .catch(() => {
                          this.$message.info('取消删除')
                      })
                }
            },

            toCreate() {
                this.$router.push('/createPage')
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "style/resourceCenterCommon";

    .event-div {
        .event-btn-div();
    }
</style>