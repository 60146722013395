<template>
    <div>
        <div class="communicate-wrap" id="drag1" v-if="!closeChatMain">
            <div v-drag style="cursor:move;height: 10px;width: 100%;background-color: rgba(128, 185, 255, 0.99)"></div>
            <div class="top-part flex-div">
                <div class="flex-div"
                     style="width:100%;padding:0 10px;box-sizing:border-box;justify-content: space-between">
                    <span>{{userSelf.nickname}}</span>
                    <i class="el-icon-close" style="cursor: pointer" @click="closeChat"></i>
                </div>
                <div class="flex-div tab-div">
                    <i class="el-icon-chat-dot-round" :class="tabIndex === 0 ? 'i-active':''" @click="changeTab(0)"></i>
                    <i class="el-icon-user-solid" :class="tabIndex === 1 ? 'i-active':''" @click="changeTab(1)"></i>
                </div>
            </div>
            <chat-main :tab-index="tabIndex" :search-txt="searchTxt" @dragWindow="showDragWindow"></chat-main>
            <chat-bottom @searchUser="searchUser"></chat-bottom>
        </div>
        <div class="min-chat-div flex-div" v-else @click="closeChatMain = false">
            <div class="my-badge" v-if="$store.state.IMstore.totalUnreadMsgs">{{$store.state.IMstore.totalUnreadMsgs}}</div>
            <img :src="userSelf.icon" alt="">
            <span style="cursor: pointer;">我的IM</span>
        </div>
        <chat-window v-if="showDrag" :counterpart-user="counterpartUser" :new-msg-item="newMsgItem" @sendMsg="sendMsg"
                     @closeDrag="closeDragWindow" @deleteChatUserItem="deleteChatUserItem"></chat-window>
    </div>
</template>

<script>
    import ChatBottom from "@/views/chat/chatBottom.vue";
    import ChatMain from "@/views/chat/chatMain.vue";
    import ChatWindow from "@/views/chat/chatWindow.vue";

    export default {
        name: "chatIndex",
        components: {ChatWindow, ChatBottom, ChatMain},
        data() {
            return {
                ws: '',
                pingTimer: null,
                newMsgItem: {},
                tempMsgItem: {},
                // 切换显示的页面
                tabIndex: 0,
                showDrag: false,
                // 和谁的聊天框
                counterpartUser: {users: [], curIndex: -1},
                // 查找用户
                searchTxt: '',
                // 关闭聊天框
                closeChatMain: true,
                // 用户个人信息
                userSelf: {},
            }
        },
        mounted() {
            let that = this
            that.userSelf = that.$store.state.loginUserinfo
            that.socketConnect({
                type: 'login',
                uid: that.userSelf.uid,
                icon: that.userSelf.icon,
                nickname: that.userSelf.nickname
            });
        },
        watch:{
            newMsgItem(n){
                // todo 有新消息，处理
                // 需要全局变量
                // 存入 本地的最近联系人实时更新还是离开页面时更新？
                if(0){   // IM 关闭（收到的一定是 ta 发来的消息）  显示右下角消息红点（并闪动）

                }else{   // IM 打开
                    if(0){  // 聊天窗口关闭，ta 给我发消息
                        if(0){  // ta 在最近联系人中 ，更新最近联系人（最新一条消息及时间）

                        }else{  // ta 不在最近联系人中，添加到最近联系人（包含最新一条消息和时间）

                        }
                        // 按时间排序最近联系人
                    }else{  // 聊天窗口打开
                        if(0){   // 我给ta发消息  一定在聊天窗口列表中，更新聊天窗口列表
                            if(0){  // ta 在最近联系人 ，更新最近联系人信息（最新一条消息及时间）

                            }else{  // ta 不在最近联系人，添加到最近联系人（包含最新一条消息和时间）

                            }
                        }else{   // 收到 ta 的信息
                            if(0){  // 我正在和 ta 聊天 ， 更新聊天窗口列表

                            }else{  // 我和别人聊天
                                if(0){  // ta 在聊天列表中，更新聊天窗口列表 增加消息红点和（闪动）

                                }else{  // ta 不在聊天列表中，在右边最近联系人中增加ta,增加红点和（闪动）

                                }
                            }
                            // 消息列表按时间排序
                        }
                    }
                }
            }
        },
        methods: {
            // 建立 websocket 链接
            socketConnect(loginData) {
                let that = this
                that.ws = new WebSocket('wss://im.dongjinyu.com');
                that.ws.onopen = () => {
                    that.ws.send(JSON.stringify(loginData))
                };
                that.ws.onmessage = (e) => {
                    that.onWsMessage(e)
                };
                that.pingTimer = setInterval(() => {
                    that.ws.send(JSON.stringify({type: 'ping'}))
                }, 50000)
            },

            // 监听服务器消息
            onWsMessage(e) {
                let that = this
                const data = JSON.parse(e.data);
                console.log(data);
                switch (data.type) {
                    case 'private': {   // 私信消息
                        data.type = 1
                        that.newMsgItem = {...data}
                        that.showDragInfo()   // 判断聊天框状态
                        break
                    }
                    case 'feedback': {  // 反馈信息
                        break;
                    }
                    case 'notify':   // 系统通知
                        break;
                    case 'ping':      // ping服务器心跳
                        that.ws.send(JSON.stringify({type: 'pong'}))
                        break;
                    case 'pong':      // ping服务器心跳
                        break;
                }
            },

            // 收到私信消息
            showDragInfo(){
                let that = this
                if(that.closeChatMain){   // 未读消息数加一
                    that.$store.state.IMstore.dispatch('addTotalUnreadMsgs',1)
                }else{
                    if(!that.showDrag){
                        // 谁的私信消息，在他的未读消息处加一
                    }
                }
            },

            // 发送消息
            sendMsg(msgType, content) {
                let that = this
                that.tempMsgItem = {
                    type: "send_to_user",
                    to_user_id: that.counterpartUser.users[that.counterpartUser.curIndex].id,
                    msg_type: msgType,
                    content: content,
                }
                that.ws.send(JSON.stringify(that.tempMsgItem))
                that.tempMsgItem['is_self'] = true;
                that.tempMsgItem['time'] = new Date().getTime() / 1000;
                that.newMsgItem = {
                    icon: that.userSelf.icon,
                    nickname: that.userSelf.nickname,
                    ...that.tempMsgItem
                }
            },

            // 切换tab
            changeTab(i) {
                if (this.tabIndex !== i) {
                    this.tabIndex = i;
                }
            },

            // 打开聊天框
            showDragWindow(item) {
                let that = this
                let i = 0;
                let l = that.counterpartUser.users.length
                for (; i < l; i++) {
                    if (that.counterpartUser.users[i] === item)
                        break
                }
                if (i === l) {
                    that.counterpartUser.users.push(item)
                    that.counterpartUser.curIndex = l
                } else
                    that.counterpartUser.curIndex = i
                if(!that.showDrag){
                    that.showDrag = true
                    that.$store.commit('IMstore/setChatWindowOpen',that.showDrag)
                }
            },

            // 删除
            deleteChatUserItem(i){
                this.counterpartUser.users.splice(i,1)
                if(i < this.counterpartUser.curIndex)
                    this.counterpartUser.curIndex = this.counterpartUser.curIndex - 1
                else if(i === this.counterpartUser.curIndex){
                    this.counterpartUser.curIndex = 0
                }
            },

            // 关闭聊天框
            closeDragWindow() {
                this.showDrag = false
                this.$store.commit('IMstore/setChatWindowOpen',this.showDrag)
                this.$store.commit('IMstore/setRecentUsers',this.counterpartUser.users)
                this.counterpartUser = {users: [], curIndex: -1}
                this.newMsgItem = {}
            },

            // 查找用户
            searchUser(sFlag, txt) {
                if (sFlag)
                    this.searchTxt = txt
            },

            // 关闭IM
            closeChat() {
                this.closeChatMain = true;
                this.showDrag = false   // 关闭IM界面，同时关闭聊天界面
                this.ws.close()
                clearInterval(this.pingTimer)
            }
        },

        directives: {
            drag(el) {
                el.onmousedown = (e) => {
                    console.log(el)
                    let elem = document.getElementById('drag1')
                    // 算出鼠标相对元素的位置
                    let disX = e.pageX - elem.offsetLeft;
                    let disY = e.pageY - elem.offsetTop;
                    document.onmousemove = (e) => {
                        // 用鼠标位置减去鼠标相对元素的位置，得到元素的位置
                        let l = e.pageX - disX;
                        let t = e.pageY - disY;
                        // 判断超出视觉范围
                        if (l < 0) l = 0
                        if (t < 0) t = 0
                        if (l + elem.clientWidth > e.view.innerWidth) l = e.view.innerWidth - elem.clientWidth
                        if (t + elem.clientHeight > e.view.innerHeight) t = e.view.innerHeight - elem.clientHeight
                        // 绑定元素位置到 positionX 和 positionY 上面，移动当前元素
                        elem.style.left = l + 'px'
                        elem.style.top = t + 'px'
                        if (el.clientWidth === 600) {
                            elem.style.margin = '0'
                        }
                        // el.style.left = l + 'px';
                        // el.style.top = t + 'px';
                        // if (el.clientWidth === 600)
                        //     el.style.margin = 0
                    }
                    document.onmouseup = () => {
                        document.onmousemove = document.onmouseup = null;
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .min-chat-div,
    .communicate-wrap {
        position: fixed;
        background: lightblue;
        box-shadow: 0 0 6px 2px lightblue;
        bottom: 0;
        right: 0;

        z-index: 966;
        width: 300px;
        height: 500px;
    }

    .min-chat-div {
        width: 140px;
        height: 50px;
        padding-left: 10px;
        box-sizing: border-box;

        img {
            .my-img-wh-mr-bradius(@w: 40px,@h: 40px,@mr: 20px,@b-radius: 50%)
        }

        .my-badge{
            position: absolute;
            top: -8px;
            left: -8px;
            padding: 0 5px;
            border-radius: 50%;
            background-color: red;
            color: #fff;
            font-size: 8px;
            animation: flicker 800ms infinite;
        }
    }


    .top-part {
        height: calc(20% - 10px);
        background: rgba(128, 185, 255, 0.99);
        color: #c5d8fa;
        flex-direction: column;
        justify-content: space-between;

        .tab-div {
            width: 100%;
            justify-content: space-around;
            font-size: 30px;

            i {
                padding: 10px;
                box-sizing: border-box;
                cursor: pointer;
            }

            .i-active {
                position: relative;
            }

            .i-active::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                border-radius: 10px;
                background-color: #06ecb2;
            }
        }
    }
</style>