<template>
    <div>
        <video autoplay id="video" oncontextmenu="return false;"
               class="video-js vjs-default-skin vjs-big-play-centered video-area" controls
               v-if="liveInfo.status===2">
            <source :src="liveInfo.play_url" type="application/mpegURL">
        </video>
        <!--已禁言用户列表-->
        <div class="banned-list">
            <div class="header">
                <span>全局禁言用户({{bannedList.length}})</span>
                <button @click="setRoomAllBanned">{{isRoomAllBanned?'关闭全员禁言':'开启全员禁言'}}</button>
            </div>
            <div class="box">
                <div class="item" v-for="i in bannedList" :key="i">
                    <span class="remove-banned" @click="removeBanned(i.uid)">解除</span>
                    <img :src="i.client_icon"
                         class="icon">
                    <span>{{i.client_name}}</span>
                </div>
            </div>
        </div>
        <!--选择当前售卖的货物-->
        <!--<div class="goods">-->
        <!--    <div style="margin-bottom: 10px;">广告位</div>-->
        <!--    <img :src="goodsImg" v-show="goodsImg">-->
        <!--    <select v-model="goodsIndex" @change="handleGoodsChange">-->
        <!--        <option :value="k" v-for="(v,k) in goodsList">{{v.name}}</option>-->
        <!--    </select>-->
        <!--    <div style="margin-top: 20px;">-->
        <!--        <button @click="sendCoupon">发布活动优惠券</button>-->
        <!--    </div>-->

        <!--</div>-->
        <div class="container">
            <div class="flex flex-bet flex-item-center title">
                <div class="flex flex-row flex-item-center" style="margin-left: 20px">
                    <div>实时在线人数：{{real_audience_count}} &nbsp;历史最高人数：{{audience_count}}</div>
                    <button class="copy-audience-btn"
                            @click="copy('实时在线人数：'+real_audience_count+' 历史最高人数：'+audience_count)">复制
                    </button>
                </div>
                <div class="flex flex-end flex-item-center">
                    <span>{{liveInfo.title}}</span>
                    <button class="dark-time-btn" @click="setDarkTheme">{{isDarkTheme?'开灯':'关灯'}}</button>
                </div>
            </div>
            <div class="msg-box" id="msgBox" @scroll="scrollEvent">
                <!--消息列表-->
                <div class="item flex flex-row flex-item-center" v-for="i in msgList" :key="i"
                     v-on:contextmenu="menu('icon',$event,i.uid,i.from_client_name,i.uid,i.msg_id)">
                    <!--有人发言-->
                    <div v-if="i.type==='say'" class="flex flex-row">
                        <div class="img">
                            <img :src="i.client_icon" class="icon" alt>
                        </div>
                        <div class="text">
                            <div>
                                <span class="vip_user" v-if="i.is_vip_user==='1'">VIP</span>
                                <span class="nickname">{{i.from_client_name}}</span>
                                <span class="date">{{i.time}}</span>
                            </div>
                            <div class="msg" v-on:contextmenu="menu('msg',$event,i.uid)" @click="copy(i.content)">
                                <span> {{i.content}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 图片消息-->
                    <div v-else-if="i.type==='img'" class="flex flex-row">
                        <div class="img">
                            <img :src="i.client_icon" class="icon">
                        </div>
                        <div class="text">
                            <div>
                                <span class="vip_user" v-if="i.is_vip_user===1">VIP</span>
                                <span class="nickname">{{i.from_client_name}}</span>
                                <span class="date">{{i.time}}</span>
                            </div>
                            <div class="msg">
                                <img :src="i.content" style="width: 200px">
                            </div>
                        </div>
                    </div>
                    <!--禁言-->
                    <div class='center tip' v-if="i.type==='banned' || i.type==='remove_banned'">{{i.msg}}</div>
                    <!--打赏-->
                    <div class="center tip" style="color:red;font-weight: bold" v-if="i.type==='reward'">{{i.msg}}
                    </div>
                </div>
                <div><a id="msg_end" name="1" href="#1"> </a></div>
            </div>
            <!--输入框-->
            <div class="send">
                <input type="text" placeholder="请输入您想说的话~~" v-on:keyup="inputChange" v-model="inputMsg"
                       @keyup.enter="send" id="input">
                <div class="right">
                    <el-upload :show-file-list=false :on-change="handleFileSelected" :action="$store.state.uploadUrl">
                        <i class="el-icon-picture-outline" style="font-size: 30px"></i>
                    </el-upload>
                    <div style="margin:0 6px;font-size: 26px;cursor: pointer"
                         @click="showEmotionPanel=!showEmotionPanel">{{emotions[0]}}
                    </div>
                    <button class="sendBtn" v-if="sendAble" @click="send">发送</button>
                    <button v-if="!sendAble">发送</button>
                    <button class="piao" @click="showBanner">飘屏</button>
                </div>
            </div>
            <!--填写姓名-->
            <div class="input-nickname" v-show="showInputNickname">
                <div>
                    <div class="header">填写您的姓名</div>
                    <div style="display: flex;align-items: center">
                        <input type="text" v-model="inputNickname" autofocus="autofocus" @keyup.enter="submitNickname">
                        <button style="color: #fff" @click="submitNickname">确定</button>
                    </div>
                </div>
            </div>
            <!--右键菜单-->
            <div class="transparent-mask" v-show="showMenu" @click="clearWidget">
                <div class="menu" :style="menuPosition">
                    <div @click="menuClick(0)">禁言</div>
                    <!--<div @click="menuClick(1)">解除禁言</div>-->
                    <div @click="menuClick(2)">私聊</div>
                    <div @click="menuClick(3)">@</div>
                    <div @click="menuClick(4)">撤回(删除)</div>
                </div>
            </div>
        </div>
        <!--笔记本-->
        <div class="note flex flex-col flex-bet">
            <div class="box">
                <div class="item" v-for="(i,k) in noteList" :key="k" @click="currNote=i"
                     :class="{'note-active-dark':currNote.id===i.id && isDarkTheme , 'note-active':currNote.id===i.id && !isDarkTheme}">
                    {{k+1}} &nbsp;&nbsp; {{i.text}}
                </div>
                <div class="item" style="color:#888;" v-if="noteList.length===0">暂无笔记，点击下面的添加按钮可以添加笔记。</div>
            </div>
            <div>
                <button @click="addNote">添加</button>
                <button @click="editNote">修改</button>
                <button @click="copyNote">复制</button>
                <button @click="delNote">删除</button>
                <button @click="sendNote">发送</button>
            </div>
        </div>
        <!--用于实现浏览器复制的输入框-->
        <input type="text" id="for-copy-input" style="position: fixed;bottom:-100px;">
        <!--表情包面板-->
        <div class="emotion" v-show="showEmotionPanel">
            <div class="close" @click="showEmotionPanel=false">x</div>
            <div class="box">
                <div v-for="i in emotions" :key="i" class="flex flex-center flex-item-center" @click="inputMsg+=i">{{i}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    window.document.oncontextmenu = function () {
        return false;
    };

    import {
        GetLiveMsgLog,
        GetLiveAdminNotebook,
        AddLiveAdminNotebook,
        EditLiveAdminNotebook,
        DeleteLiveAdminNotebook, GetLiveDetail
    } from "@/api";
    import {getPageInfo} from "@/utils";
    import videojs from 'video.js'

    export default {
        name: "liveChatRoom",
        data: function () {
            return {
                liveInfo: {},
                sendAble: false,
                inputMsg: '',
                msgList: [],
                signList: [],
                nickname: '',
                inputNickname: '小爱老师',
                showInputNickname: false,
                wsObj: null,
                heartTimerSet: false,//心跳定时器是否已设置
                showMenu: false,
                menuPosition: "",
                selectedUid: '',
                selectedName: '',
                selectedClientId: '',
                selectedMsgId: '',
                real_audience_count: 0,//真实人数
                audience_count: 0,//历史最高人数
                // goodsList: [],
                // goodsIndex: null,
                // goodsImg: '',
                file: null,
                bannedList: [],//已禁言用户列表
                isRoomAllBanned: false,//直播间是否全员禁言
                allBannedAutoReply: '',//全员禁言自动回复
                ///笔记本
                noteList: [],
                currNote: {
                    id: null,
                    text: ''
                },
                //夜间模式
                isDarkTheme: false,
                //表情包
                emotions: [
                    '😀', '😅', '😇', '🙂', '😋', '😟', '🤣', '🙃', '😛', '😕',
                    '😫', '😗', '🤓', '🙁', '😩', '😆', '🤗', '😶', '😯', '🤐',
                    '😈', '🤔', '😐', '😦', '😴', '🤒', '😬', '😮', '🌹',
                    '🤥', '🙄', '😲', '🤧', '🤠', '😙', '😎', '🤝🏻', '🤞🏻', '🤜🏻',
                    '🤛🏻', '🤘🏻', '🤚🏻', '🖖🏻', '🐓', '🐕', '🐏', '🐃', '🐂', '🐄',
                    '🐐', '🦌', '🦌', '🌞', '🌝', '🌛', '🌜', '🌼', '🎋', '🍄',
                    '🍏', '🌁', '🎊', '💞', '💕', '💖'
                ],
                showEmotionPanel: false,  //是否显示表情面板
                // 聊天记录信息
                msgLogPageInfo: {}
            }
        },
        watch: {
            inputMsg: function (val) {
                this.sendAble = val.trim() !== '';
            }
        },
        computed: {
            scrollEvent() {
                let that = this
                let msg = document.getElementById('msgBox')
                // let vh = msg.clientHeight
                let h1;
                msg.onscroll = () => {
                    h1 = msg.scrollHeight
                    if (msg.scrollTop === 0) {
                        if (that.msgLogPageInfo.current_page < that.msgLogPageInfo.last_page) {
                            GetLiveMsgLog({page: that.msgLogPageInfo.current_page + 1, ...that.$route.params}).then((res) => {
                                that.msgList = res.data.page_data.reverse().concat(that.msgList)
                                that.msgLogPageInfo = getPageInfo(res.data)
                                setTimeout(() => {
                                    msg.scrollTop = msg.scrollHeight - h1
                                }, 0)
                            })
                        } else
                            console.log("加载完成，没有更多数据了")
                    }
                }
            },
        },
        mounted: function () {
            let that = this
            that.getLiveInfo()
            that.setWs();
            // that.getGoodsList();
            that.getNote();
            that.isSetDarkTheme();

            setTimeout(function () {
                let myVideo = videojs('video', {
                    bigPlayButton: true,
                    textTrackDisplay: false,
                    posterImage: false,
                    errorDisplay: false,
                })
                myVideo.play() // 视频播放
                // myVideo.pause() // 视频暂停
            }, 100);
        },
        methods: {
            // 直播详情
            getLiveInfo() {
                const that = this
                GetLiveDetail(that.$route.params).then((res) => {
                    that.liveInfo = res.data.live
                }, (e) => {
                    that.$message.error(e.msg)
                })
            },

            //设置壁纸
            setBackground: function () {
                let index = 1;
                const time = 300000;
                setTimeout(function () {
                    changeBackground();
                }, time);

                function changeBackground() {
                    index++;
                    if (index > 5) index = 1;
                    document.body.style.background = "url('/image/wallpaper/" + index + ".jpg')";
                    setTimeout(function () {
                        changeBackground();
                    }, time)
                }
            },
            // getGoodsList: function () {
            //     const self = this;
            //     $.post('{:url("")}', {self.$route.params}, function (ret) {
            //         self.goodsList = ret.ad_list;
            //         self.goodsList.push({
            //             id: null,
            //             name: '不显示会员位',
            //             img: '',
            //             type: 0
            //         });
            //         for (let i = 0; i < self.goodsList.length; i++) {
            //             const item = self.goodsList[i];
            //             if (item.type === ret.ad_type) {
            //                 self.goodsIndex = i;
            //                 self.goodsImg = item.img;
            //             }
            //         }
            //     });
            // },
            // handleGoodsChange: function () {
            //     const index = this.goodsIndex;
            //     console.error(index);
            //     //更改数据库字段
            //     const adType = this.goodsList[index].type;
            //     let that = this
            //     // $.post("{:url('change_live_ad')}", {
            //     //     id: that.$route.params.id,
            //     //     ad_type: adType
            //     // }, function (ret) {
            //     //     that.$message.info(ret)
            //     // });
            //     //发送实时聊天室数据
            //     let ad;
            //     if (adType !== 0) {
            //         const item = this.goodsList[index];
            //         this.goodsImg = item.img;
            //         ad = {
            //             img: item.img,
            //             type: item.type,
            //             type_name: item.name,
            //             visible: true
            //         };
            //     } else {
            //         this.goodsImg = '';
            //         ad = {
            //             img: '',
            //             type: 0,
            //             type_name: '',
            //             visible: false
            //         };
            //     }
            //     this.wsObj.send(JSON.stringify({
            //         type: 'change_ad',
            //         ad: ad
            //     }));
            // },
            inputChange: function () {
                this.sendAble = this.inputMsg.trim() !== '';
            },
            setWs: function () {
                const self = this;
                if (navigator.userAgent.indexOf("Chrome") > -1 || navigator.userAgent.indexOf("Firefox") > -1) {
                    const wsUrl = "wss://wss.dongjinyu.com";
                    this.wsObj = new WebSocket(wsUrl);
                    // 当socket连接打开时，输入用户名
                    this.wsObj.onopen = this.onopen;
                    // 当有消息时根据消息类型显示不同信息
                    this.wsObj.onmessage = this.onmessage;
                    this.wsObj.onclose = function () {

                    };
                    this.wsObj.onerror = function () {
                        console.error("出现错误");
                        self.setWs()
                    };
                    //心跳
                    if (!this.heartTimerSet) {
                        this.heartTimerSet = true;
                        setInterval(function () {
                            self.wsObj.send(JSON.stringify({type: 'ping'}))
                        }, 10 * 1000)
                    }
                } else {
                    alert('请使用谷歌浏览器或者火狐浏览器');
                }
            },
            // 连接建立时发送登录信息
            onopen: function () {
                if (!this.nickname) {
                    this.showInputNickname = true;
                }
            },
            onmessage: function (e) {
                const data = JSON.parse(e.data);
                console.log(data);
                switch (data['type']) {
                    // 服务端ping客户端
                    case 'ping':
                        this.wsObj.send('{"type":"pong"}');
                        break;
                    // 登录 更新用户列表
                    case 'login':
                        //{"type":"login","client_id":xxx,"client_name":"xxx","client_list":"[...]","time":"xxx"}
                        this.say(data.type, data['from_client_id'], data['client_name'], data['client_icon'], data['client_name'] + '加入了聊天室', data['time'], data.uid);
                        if (data['client_list']) {
                            client_list = data['client_list'];
                        } else {
                            client_list[data['client_id']] = data['client_name'];
                        }
                        //console.log(data['client_name'] + "登录成功");
                        this.real_audience_count = data.real_audience_count;
                        this.audience_count = data.audience_count;
                        break;
                    case 'info':
                        this.bannedList = data.banned_list;
                        this.isRoomAllBanned = data.is_room_all_banned === '1';
                        this.real_audience_count = data.real_audience_count;
                        this.audience_count = data.audience_count;
                        break;
                    // 发言
                    case 'say':
                        this.say(
                            data.type,
                            data['from_client_id'],
                            data['from_client_name'],
                            data['client_icon'],
                            data['content'],
                            data['time'],
                            data.uid,
                            data.is_vip_user,
                            data.is_presenter,
                            data.msg_id
                        );
                        break;
                    // 用户退出 更新用户列表
                    case 'logout':
                        //{"type":"logout","client_id":xxx,"time":"xxx"}
                        this.say(data.type, data['from_client_id'], data['from_client_name'], '', data['from_client_name'] + ' 退出了', data['time'], data.uid);
                        //delete client_list[data['from_client_id']];
                        this.real_audience_count = data.real_audience_count;
                        break;
                    case 'withdraw':
                        this.withdraw(data.msg_id);
                        break;
                    case 'banned':
                        this.say(data.type, data['from_client_id'], data['from_client_name'], '', data.msg, data['time'], data.uid);
                        this.bannedList = data.banned_list;

                        break;
                    case 'remove_banned':
                        this.say(data.type, data['from_client_id'], data['from_client_name'], '', data.msg, data['time'], data.uid);
                        this.bannedList = data.banned_list;

                        break;
                    case 'reward':
                        this.say(data.type, "", "", '', data.msg, '', '');
                        break;
                    //图片消息
                    case 'img':
                        this.say(
                            data.type,
                            data['from_client_id'],
                            data['from_client_name'],
                            data['client_icon'],
                            data['content'],
                            data['time'],
                            data.uid,
                            data.is_vip_user,
                            data.is_presenter,
                            data.msg_id
                        );
                        //console.log(data.content);
                        break;
                    case 'room_all_banned':
                        this.isRoomAllBanned = true;
                        break;
                    case 'cancel_room_all_banned':
                        this.isRoomAllBanned = false;
                        break;
                    case 'banner':
                        this.$message.info('飘屏发送成功');
                        break;
                    case 'any_admin_group_message':
                        this.$message.info('操作成功');
                        break;
                }
            },
            //消息撤回
            withdraw: function (msgId) {
                for (let i = 0; i < this.msgList.length; i++) {
                    const item = this.msgList[i];
                    if (item.msg_id === msgId) {
                        this.$message.info('撤回成功');
                        this.msgList.splice(i, 1);
                    }
                }
            },
            //填写昵称并逻辑登录
            submitNickname: function () {
                if (this.inputNickname.trim() !== '') {
                    this.nickname = this.inputNickname;
                    this.showInputNickname = false;
                    // 登录
                    let loginData = {
                        type: 'login',
                        uid: '__admin__',
                        client_name: this.nickname,
                        room_id: this.$route.params.id,
                        is_presenter: '1',
                        is_vip_user: '0'
                    };

                    // console.info(111, loginData);

                    loginData = JSON.stringify(loginData);
                    // console.log("websocket握手成功，发送登录数据:" + loginData);
                    this.wsObj.send(loginData);
                    this.getMessageLog();

                    const self = this;

                    //定时获取直播间状态
                    (function () {
                        callInfo();

                        function callInfo() {
                            self.wsObj.send(JSON.stringify({type: 'info'}));
                            setTimeout(function () {
                                callInfo();
                            }, 3000);
                        }
                    })();
                }
            },
            scrollToBottom(str) {
                let e = document.getElementById(str)
                setTimeout(() => {
                    e.scrollTop = e.scrollHeight
                }, 0)
            },
            // 发言
            say: function (type, from_client_id, from_client_name, from_client_icon, content, time, uid, is_vip_user, is_presenter, msg_id) {
                let target;
                if (type === 'login' || type === 'logout') {
                    target = this.signList
                } else {
                    target = this.msgList
                }
                target.push({
                    client_icon: from_client_icon,
                    is_presenter: is_presenter,
                    is_vip_user: is_vip_user,
                    content: content,
                    from_client_name: from_client_name,
                    time: time,
                    type: type,
                    uid: uid,
                    msg_id: msg_id
                });
                let that = this
                console.log(this.msgList)
                // that.scrollToBottom('signBox');
                that.scrollToBottom('msgBox', true, function () {
                    // that.$message.info('有新消息')
                })
            },
            // 提交消息
            send: function () {
                const input = this.inputMsg.trim();
                if (input !== "") {
                    ///var prefix = "@" + this.selectedName + "：";
                    //群发
                    //if (input.indexOf(prefix) == -1) {
                    this.wsObj.send(JSON.stringify({
                        type: 'say',
                        uid: '001',
                        to_client_id: 'all',
                        to_client_name: 'all',
                        content: input,
                        is_presenter: '1',
                        is_vip_user: '0',
                    }));
                    // } else {
                    //     //私聊
                    //     this.wsObj.send(JSON.stringify({
                    //         type: 'say',
                    //         to_client_id: this.selectedClientId,
                    //         content: input.replace(prefix, ''),
                    //         to_client_name: this.selectedName,
                    //     }));
                    //     this.selectedName = null;
                    //     this.selectedClientId = 'all';
                    // }

                    this.inputMsg = '';
                    this.sendAble = false;

                    //手动插入消息
                    // this.msgList.push({
                    //     is_presenter: '1',
                    //     is_vip_user: '0',
                    //     msg: input,
                    //     icon: 'http://h5.dongjinyu.com/img/20180428144236_5ae417dc7f88b.png',
                    //     nickname: this.nickname,
                    //     time: this.getTime(),
                    //     type: 'say',
                    //     uid: '__admin__'
                    // });

                    this.scrollToBottom('msgBox');
                }
            },
            //用户头像右键菜单
            menu: function (type, e, id, name, from_client_id, msg_id) {
                this.menuPosition = 'left:' + e.pageX + 'px;top:' + e.pageY + 'px;';
                this.selectedUid = id;
                this.selectedName = name;
                this.selectedClientId = from_client_id;
                this.showMenu = true;
                this.selectedMsgId = msg_id;
            },
            //清除小部件,包括菜单
            clearWidget: function () {
                this.showMenu = false;
            },
            //右键菜单
            menuClick: function (i) {
                this.showMenu = false;
                if (this.selectedUid != null) {
                    switch (i) {
                        case 0:
                            //发起禁言操作
                            const data = {
                                type: 'banned',
                                target_uid: this.selectedUid
                            };
                            this.wsObj.send(JSON.stringify(data));

                            break;
                        case 1:

                            break;
                        case 2:
                            //发起私聊
                            this.inputMsg = "@" + this.selectedName + "：";
                            //this.sendAble = true;
                            document.getElementById('input').focus();
                            break;
                        case 3:
                            this.inputMsg = '@' + this.selectedName + "：";
                            document.getElementById('input').focus();
                            break;
                        case 4:
                            const message = JSON.stringify({
                                type: 'withdraw',
                                msg_id: this.selectedMsgId
                            });
                            this.wsObj.send(message);
                            break;
                    }
                }
            },
            //解除禁言
            removeBanned: function (uid) {

                //解除禁言
                const dataR = {
                    type: 'remove_banned',
                    target_uid: uid
                };

                this.wsObj.send(JSON.stringify(dataR));
            },
            //获取聊天记录
            getMessageLog: function () {
                const self = this;
                GetLiveMsgLog(self.$route.params).then((res) => {
                    let r = res.data.page_data;
                    self.msgList = r.reverse()
                    self.msgLogPageInfo = getPageInfo(res.data)
                    self.scrollToBottom('msgBox');

                    // self.msgList = res.data.page_data.reverse();
                    // self.scrollToBottom('msgBox')
                }, (e) => {
                })
            },
            processMsgLog(r) {
                let temp = [];
                for (let i = 0; i < r.length; i++) {
                    const item = r[i];
                    temp.push({
                        uid: item.uid,
                        type: item.type,
                        client_icon: item.client_icon,
                        from_client_name: item.nickname,
                        content: item.content
                    });
                }
                return temp
            },
            //上传图片文件
            handleFileSelected: function (e) {
                const self = this;
                if (e.status !== 'success')
                    return
                const file = e.raw
                const typeStr = "image/jpg,image/png,image/gif,image/jpeg";
                if (typeStr.indexOf(file.type) !== -1) {
                    if (file.size > 2097152) {
                        self.$alert("上传的文件不能大于2M");
                    } else {
                        self.sendImgMsg(e.response.data);
                    }
                } else {
                    alert("请上传格式为jpg、png、gif、jpeg的图片");
                }
            },
            //发送图片消息
            sendImgMsg: function (img) {
                this.wsObj.send(JSON.stringify({
                    type: 'img',
                    uid: '__admin__',
                    to_client_id: 'all',
                    to_client_name: 'all',
                    content: img,
                    is_presenter: '1',
                    is_vip_user: '0'
                }));
                //手动插入消息
                // this.msgList.push({
                //     is_presenter: '1',
                //     is_vip_user: '0',
                //     msg: img,
                //     icon: 'http://h5.dongjinyu.com/img/20180428144236_5ae417dc7f88b.png',
                //     nickname: this.nickname,
                //     time: this.getTime(),
                //     type: 'img',
                //     uid: '__admin__'
                // });

                this.scrollToBottom('msgBox');

            },
            getTime: function () {
                const date = new Date();
                const year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let hour = date.getHours();
                let minute = date.getMinutes();
                let seconds = date.getSeconds();
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                if (hour < 10) {
                    hour = '0' + hour;
                }
                if (minute < 10) {
                    minute = '0' + minute;
                }
                if (seconds < 10) {
                    seconds = '0' + seconds;
                }
                return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + seconds;
            },
            //全员禁言
            setRoomAllBanned: function () {
                const that = this
                const allBanned = !this.isRoomAllBanned;
                if (allBanned) {
                    that.$prompt('填写全员禁言自动回复', {
                        inputValue: '现在开始全员禁言，大家好好听课。'
                    }).then((v) => {
                        let reply = v.value
                        if (reply !== null && reply.trim() !== '') {
                            that.wsObj.send(JSON.stringify({
                                type: 'room_all_banned',
                                auto_reply: reply
                            }));

                            that.isRoomAllBanned = true;
                        }
                    }).catch((e) => {
                    });
                } else {
                    that.wsObj.send(JSON.stringify({
                        type: 'cancel_room_all_banned'
                    }));
                    that.isRoomAllBanned = false;
                }
            },
            //获取笔记
            getNote: function () {
                const self = this;
                GetLiveAdminNotebook({}).then((res) => {
                    // GetLiveAdminNotebook({id:138}).then((res) => {
                    self.noteList = res.data;
                })
            },
            //添加笔记
            addNote: function () {
                const self = this;
                self.$prompt('请输入新的笔记').then((v) => {
                    if (v.value !== null && v.value.trim() !== '') {
                        AddLiveAdminNotebook({
                            text: v.value
                        }).then((res) => {
                            self.$message.success('添加成功！');
                            self.getNote();
                        })
                    }
                });
            },
            //如果选择了笔记
            isSelectedNote: function (callback) {
                if (this.currNote.id == null) {
                    this.$message.info('请选择笔记后再操作')
                } else {
                    callback(this.currNote);
                }
            },
            //删除笔记
            delNote: function () {
                const that = this;
                that.isSelectedNote(function (note) {
                    that.$confirm('确定要删除' + note.text + '吗？').then((res) => {
                        DeleteLiveAdminNotebook({id: note.id}).then((res) => {
                            that.$message.success('删除成功')
                            that.getNote()
                        })
                    }).catch((e) => {
                    })
                })
            },
            //修改笔记
            editNote: function () {
                const that = this;
                that.isSelectedNote(function (note) {
                    that.$prompt('修改笔记', {
                        inputValue: note.text,
                    }).then((v) => {
                        if (v.value !== null && v.value !== '' && v.value !== note.text) {
                            EditLiveAdminNotebook({
                                id: note.id,
                                text: v.value
                            }).then((res) => {
                                that.$message.success('修改成功！')
                                that.getNote();
                            }).catch((e) => {
                            })
                        }
                    }).catch((e) => {
                    })
                })
            },
            //复制笔记
            copyNote: function () {
                const self = this;
                this.isSelectedNote(function (note) {
                    self.copy(note.text);
                })
            },
            //发送笔记
            sendNote: function () {
                const self = this;
                this.isSelectedNote(function (note) {
                    self.inputMsg = note.text;
                    self.send();
                })
            },
            //复制
            copy: function (text) {
                const input = document.getElementById('for-copy-input');
                input.value = text;
                input.select();
                document.execCommand('Copy');
                this.$message.info('复制成功');
            },
            //发送飘屏横幅
            showBanner: function () {
                const that = this;
                that.$prompt('请输入需要飘屏的文字内容').then((v) => {
                    let text = v.value
                    if (text !== null && text.trim() !== '') {
                        that.$prompt('请输入飘屏次数,最少1次，最多10次', {
                            inputValue: '1',
                        }).then((v1) => {
                            let repeat = parseInt(v1.value);
                            if (repeat < 1) {
                                repeat = 1;
                            }
                            if (repeat > 10) {
                                repeat = 10;
                            }
                            const data = JSON.stringify({
                                type: 'banner',
                                text: text,
                                repeat: repeat
                            });
                            that.wsObj.send(data);
                        });
                    }
                }).catch((e) => {
                });
            },
            //开灯/关灯
            setDarkTheme: function () {
                if (!this.isDarkTheme) {
                    localStorage.setItem('__live_is_dark_theme', '1');
                    this.setDarkThemeStyle(true);
                } else {
                    localStorage.setItem('__live_is_dark_theme', '0');
                    this.setDarkThemeStyle(false);
                }
            },
            //设置夜间模式
            setDarkThemeStyle: function (isDark) {
                const html = document.getElementsByTagName('html')[0];
                const divs = document.getElementsByTagName('div');
                const btns = document.getElementsByTagName('button');
                const emotion = document.getElementsByClassName('emotion')[0];
                let i, j;
                if (isDark) {
                    html.style.background = 'rgba(31,32,33)';
                    this.isDarkTheme = true;
                    for (i = 0; i < divs.length; i++) {
                        divs[i].style.borderColor = '#333';
                    }
                    for (j = 0; j < btns.length; j++) {
                        btns[j].style.color = '#888';
                        btns[j].style.background = '#555';
                    }
                    emotion.style.background = '#555';
                } else {
                    html.style.background = 'rgba(255,255,255)';
                    this.isDarkTheme = false;
                    for (i = 0; i < divs.length; i++) {
                        divs[i].style.borderColor = '#eee';
                    }
                    for (j = 0; j < btns.length; j++) {
                        btns[j].style.color = '#fff';
                        btns[j].style.background = '#0a6aa1';
                    }
                    emotion.style.backgroundColor = '#fff';
                }
            },
            //是否设置了夜间模式
            isSetDarkTheme: function () {
                const cache = localStorage.getItem('__live_is_dark_theme');
                this.isDarkTheme = parseInt(cache) === 1;
                this.setDarkThemeStyle(this.isDarkTheme);
            },
            // 发放优惠券到直播间
            // sendCoupon: function () {
            //     if (!this.wsObj) {
            //         return;
            //     }
            //
            //     //天数券
            //
            //     // if (confirm('确定在直播间弹出优惠券领取弹窗吗？')) {
            //     //     var data = {
            //     //         type: 'any_admin_group_message',
            //     //         tag: 'member_coupon_618'
            //     //     }
            //     //     this.wsObj.send(JSON.stringify(data));
            //     // }
            //
            //     //金额券
            //
            //     const value = parseInt(prompt('输入优惠券金额'));
            //     if (typeof value == 'number' && value >= 0 && value <= 50) {
            //         const now = new Date();
            //         const nowText = (now.getMonth() + 1) + '月' + now.getDate() + '日';
            //         const expire = new Date(Date.now() + 1000 * 86400 * 7);
            //         const expireText = (expire.getMonth() + 1) + '月' + expire.getDate() + '日';
            //         const dateText = nowText + '-' + expireText;
            //         const data = {
            //             type: 'any_admin_group_message',
            //             tag: 'member_coupon_amount',
            //             value: value,
            //             dateExpireText: dateText
            //         };
            //         this.wsObj.send(JSON.stringify(data));
            //     } else {
            //         this.$message.info('金额无效')
            //     }
            // }
        }
    }
</script>

<style scoped>
    /*修改滚动条样式*/
    ::-webkit-scrollbar {
        width: 3px;
        height: 2px;
        /**/
    }

    ::-webkit-scrollbar-track {
        background: rgba(239, 239, 239, 0);
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #c7c7c7;
    }

    /*html, body {*/
    /*    margin: 0;*/
    /*    padding: 0;*/
    /*    color: #555;*/
    /*}*/

    /*body {*/
    /*    !*background: url(/image/wallpaper/2.jpg);*!*/
    /*    !*background: #eee;*!*/
    /*    height: 100%;*/
    /*    transition: all 1.5s;*/
    /*    !*background-repeat: no-repeat;*!*/
    /*}*/

    .flex {
        display: flex;
    }

    .flex-row {
        flex-direction: row;
    }

    .flex-col {
        flex-direction: column;
    }

    .flex-bet {
        justify-content: space-between;
    }

    .flex-start {
        justify-content: flex-start;
    }

    .flex-end {
        justify-content: flex-end;
    }

    .flex-aro {
        justify-content: space-around;
    }

    .flex-eve {
        justify-content: space-evenly;
    }

    .flex-item-center {
        align-items: center;
    }

    .flex-item-start {
        align-items: start;
    }

    .flex-center {
        justify-content: center;
        align-items: center;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .container {
        width: 1000px;
        height: 550px;
        /*box-shadow: 0 0 33px #333;*/
        border: 1px solid #eee;
        margin: 30px auto 0;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
    }

    .note {
        width: 1000px;
        border: 1px solid #eee;
        border-top: none;
        margin: 0 auto;
        box-sizing: border-box;
        font-size: 14px;
        max-height: 300px;
    }

    .note .box {
        overflow-y: scroll;
        margin-bottom: 10px;
    }

    .note .item {
        padding: 4px 14px;
        cursor: pointer;
    }

    .note .item.note-active {
        color: #fff;
        background: #0a6aa1;
    }

    .note .item.note-active-dark {
        color: #888;
        background: #555;
    }

    .title {
        text-align: center;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #eee;
        font-size: .9rem;
        letter-spacing: 1px;
        position: relative;
        right: 0;
    }

    .send {
        height: 50px;
        position: absolute;
        bottom: 0;
        box-sizing: border-box;
        width: 100%;
        /*box-shadow: 0 -6px 25px #ddd;*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #eee;
    }

    .send input {
        width: 100%;
        height: 46px;
        padding: 0 14px;
        border: none;
        box-sizing: border-box;
        margin-left: 0;
        flex: .92;
        background: transparent;
        color: #555;
    }

    .send input:focus {
        outline: none;
    }

    .send .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: .18;
    }

    .send .right input[type=file] {
        visibility: hidden;
    }

    .send .icon {
        width: 34px;
        display: block;
        vertical-align: center;
        margin-right: 10px;
        padding: 0;
    }

    .send button {
        font-size: .8rem;
        border: none;
        outline: none;
        height: 50px;
        width: 80px;
        float: right;
        background: #3499da;
        opacity: .4;
    }

    .send button.piao {
        opacity: 1;
        background: #0ccf67;
    }

    .send .sendBtn {
        background: #3499da;
        color: #fff;
        opacity: 1;
    }

    .msg-box .item {
        padding: 5px 15px;
        /*border-bottom: 1px solid #eee;*/
    }

    .msg-box .item .icon {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .msg-box .item .img {
        width: 40px;
        vertical-align: top;
    }

    .msg-box .item .text {
        /*width: 880px;*/
        padding-left: 20px;
    }

    .msg-box .item .text .date {
        font-size: 12px;
        /*color: #888;*/
        margin-left: 14px;
    }

    .msg-box .item .text .nickname {
        font-size: 14px;
        font-weight: bold;
    }

    .msg-box .item .text .msg {
        font-size: 14px;
        color: #444;
        margin-top: 10px;
        word-break: break-all;
        cursor: default;
    }

    .msg-box {
        height: 450px;
        overflow-y: scroll;
    }

    .input-nickname {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 2;
    }

    .input-nickname > div {
        background: #3499da;
        width: 500px;
        height: 200px;
        color: #fff;
        position: absolute;
        left: 50%;
        top: 200px;
        margin-left: -250px;
        padding: 60px 30px;
        box-sizing: border-box;
    }

    .input-nickname .header {
        height: 30px;
        line-height: 30px;
    }

    .input-nickname div input {
        height: 30px;
        padding: 0;
        margin: 0;
    }

    .input-nickname div button {
        margin-left: 10px;
        outline: none;
        border: none;
        height: 32px;
        color: #555;
        width: 80px;
        vertical-align: middle;
    }

    .center {
        text-align: center;
    }

    .tip {
        color: #999;
    }

    .transparent-mask {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0);
    }

    .menu {
        background: #fff;
        position: fixed;
        /*box-shadow: 0 0 33px #333;*/
        z-index: 3;
        font-size: .8rem;
        -webkit-user-select: none;
        border: 1px solid #eee;
    }

    .menu > div {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #eee;
        padding: 4px 30px;
    }

    .menu > div:hover {
        background: #eee;
    }

    .vip_user {
        color: #fff;
        padding: 1px 5px;
        border-radius: 8px;
        margin-right: 5px;
        background: red;
        font-size: 12px;
    }

    .real_audience_count {
        position: absolute;
        left: 20px;
    }

    .person-sign-log-container {
        background: rgba(0, 0, 0, 0.5);
        width: 200px;
        height: 300px;
        position: fixed;
        left: 10px;
        color: #0dff06;
        padding: 15px;
        font-size: 12px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .goods {
        position: fixed;
        top: 460px;
        left: 10px;
    }

    .goods select {
        margin: 10px 0;
    }

    .goods img {
        border-radius: 8px;
        width: 60px;
        display: block;
        margin: 0;
    }

    .banned-list {
        position: fixed;
        left: 10px;
        background-color: #fff;
        width: 300px;
        top: 30px;
        box-sizing: border-box;
    }

    .banned-list .box {
        height: 330px;
        overflow-y: scroll;
        top: 45px;
        position: absolute;
        box-sizing: border-box;
        overflow-x: hidden;
        width: 100%;
        padding: 0 14px;
        border: 1px solid #eee;
    }

    .banned-list .box .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .banned-list .header {
        position: absolute;
        top: 0;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 14px;
    }

    .banned-list .box .item .remove-banned {
        color: #888;
        cursor: pointer;
    }

    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 10px;
    }

    button {
        outline: none;
        border: none;
        background: #0a6aa1;
        padding: 6px 10px;
        color: #fff;
    }

    button:active {
        opacity: .5;
    }

    .dark-time-btn {
        height: 29px;
        margin-left: 14px;
        margin-right: 10px;
    }

    #video {
        position: fixed;
        right: 14px;
        z-index: 33;
        top: 30px;
        display: block;
        width: 350px;
    }

    .emotion {
        width: 300px;
        position: fixed;
        height: 220px;
        right: calc((100% - 1000px) / 2);
        box-sizing: border-box;
        padding: 12px;
        background: #fff;
        top: 310px;
    }

    .emotion .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        height: 100%;
        /*overflow-y: scroll;*/
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        /*padding-left: 25px;*/
    }

    .emotion .box > div {
        float: left;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        font-size: 16px;
        cursor: default;
        user-select: none;
        margin: 0;
    }

    .emotion .box > div:hover {
        font-size: 2rem;
        transition: .3s ease;
    }

    .emotion .close {
        width: 30px;
        height: 30px;
        /*border-radius: 50%;*/
        background: red;
        position: absolute;
        /*left: -10px;*/
        display: flex;
        justify-content: center;
        align-items: center;
        left: -30px;
        top: -0px;
        color: #fff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: default;
        user-select: none;
    }

    .emotion .close:hover {
        opacity: .5;
    }

    .copy-audience-btn {
        height: 29px;
        margin-left: 14px;
        margin-right: 10px;
    }
</style>