<template>
    <div>
        <div class="top-tips">温馨提示：本月你还能群发 <span style="color: #E72222;">4</span> 次</div>
        <div class="flex-div send-item">
            <span>群发标题</span>
            <span class="required-option">*</span>
            <el-input v-model="sendForm.tit" placeholder="请填写群发名称"></el-input>
        </div>
        <div class="flex-div send-item">
            <span>目标人群</span>
            <span class="required-option">*</span>
            <div class="focus-group-option-div flex-div">
                <el-radio v-model="radioChoice" label="n">所有人</el-radio>
                <el-radio v-model="radioChoice" label="0-5">重点人群</el-radio>
                <el-select v-model="focusGroupDesDefault" placeholder="请选择">
                    <el-option v-for="(item, index) in focusGroupDes" :key="index" :label="item.tit" :value="item.tit"></el-option>
                </el-select>
            </div>
            <span style="margin:0 32px 0 102px;">标签人群</span>
            <el-select v-model="sendForm.tagGroup" placeholder="请选择">
                <!--<el-option v-for="item in " :label="item.tit" :value="item.tit"></el-option>-->
            </el-select>
        </div>
        <div class="flex-div send-item">
            <span>群发方式</span>
            <span class="required-option">*</span>
            <el-select v-model="sendForm.massway" placeholder="请选择">
                <!--<el-option v-for="item in " :label="item.tit" :value="item.tit"></el-option>-->
            </el-select>
        </div>
        <div class="flex-div send-item" style="margin-bottom: 120px;">
            <span style="margin-right: 53px;align-self: flex-start;">群发内容</span>
            <el-input v-model="sendForm.content" type="textarea" placeholder="请填写内容信息"></el-input>
        </div>
        <div class="btn-div" @click="saveSend">保存</div>
    </div>
</template>

<script>
    export default {
        name: "createGroupMsg",
        data() {
            return {
                radioChoice: 'n',
                focusGroupDes: [{
                    tit: '无手机号码客户',
                    des: '领取平台或好友赠送的短期体验会员的用户',
                }, {
                    tit: '高活跃客户',
                    des: '近7天内访问大于3天的用户',
                }, {
                    tit: '会员用户',
                    des: '有过消费记录但近30天未访问过的平台的用户',
                }, {
                    tit: '服务课程客户',
                    des: '近7天内，存在未付款订单的用户',
                }, {
                    tit: '已购专栏客户',
                    des: '对收藏过店铺任意商品的用户进行激励',
                },],
                focusGroupDesDefault: '',
                sendForm: {
                    tit: '',
                    targetGroup: '',
                    tagGroup: '',
                    massway: '',
                    content: '',
                }
            }
        },
        mounted() {
            if (this.$route.params.w !== 'n') {
                this.radioChoice = '0-5'
                this.focusGroupDesDefault = this.focusGroupDes[this.$route.params.w].tit
            }
        },
        methods: {
            // 群发消息
            saveSend() {
                this.$message.success(`保存`)
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .top-tips {
        margin-top: 37px;
        padding-left: 23px;
        width: 660px;
        height: 37px;
        line-height: 37px;
        background: #FFF4E8;
        border-radius: 5px;
        font-size: 18px;
        color: #4F545D;
    }

    .send-item {
        margin-top: 30px;

        .my-el-input();
        .my-el-textarea(@h: 240px);

        span:first-child {
            color: #404040;
        }

        .focus-group-option-div {
            padding-left: 20px;
            box-sizing: border-box;
            width: 510px;
            height: 60px;
            background: #F2F5F8;

            ::v-deep(.el-radio__label) {
                font-size: 16px;
                color: #4D4D4D;
            }

            .my-el-select();
        }

        .my-el-select(@w: 260px,@h: 45px);
    }

    .btn-div {
        margin-left: 250px;
    }
</style>