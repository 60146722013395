<template>
    <div class="creation-wrap">
        <el-form :model="form" status-icon :hide-required-asterisk=true :rules="rules" ref="form" label-position="left">
            <el-form-item label="标题" prop="title" class="creation-item">
                <span class="required-option">*</span>
                <el-input placeholder="请填写标题" v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="副标题" prop="sub_title" class="creation-item">
                <span class="required-option">*</span>
                <el-input placeholder="请填写副标题" v-model="form.sub_title"></el-input>
            </el-form-item>
            <el-form-item label="是否显示表单" class="creation-item">
                <span class="required-option">*</span>
                <el-select v-model="form.show_form" placeholder="请选择">
                    <el-option v-for="(item, i) in options" :key="i" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="表单是否显示课程选项" class="creation-item">
                <span class="required-option">*</span>
                <el-select v-model="form.show_lesson_option" placeholder="请选择">
                    <el-option v-for="(item, i) in options" :key="i" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="flex-div creation-item">
                <div style="width:40px">配图</div>
                <span class="required-option">*</span>
                <div class="complete-imgs" v-if="form.img!==''">
                    <img class="up-img" :src="form.img" alt="">
                    <div class="flex-div show-btn">
                        <!--<i class="el-icon-view" style="margin-right: 30px;" @click="previewImg"></i>-->
                        <i class="el-icon-delete" @click="deleteImg"></i>
                    </div>
                </div>
                <img-cutter :cut-width="300" :cut-height="300" rate="1:1" :size-change="false" @cutDown="cutDown"
                            file-type="jpeg" v-else></img-cutter>
                <!--<el-upload v-else class="upload" :action="$store.state.uploadUrl" :limit=1 :file-list="fileList"-->
                <!--           list-type="picture-card" accept="image/jpg,image/JPG" :before-upload="beforeImgUpload"-->
                <!--           :show-file-list=false :on-change="handleChange">-->
                <!--    <template #tip>-->
                <!--        <div class="el-upload__tip"-->
                <!--             style="position:absolute;bottom:-34px;font-size: 14px;color: #EB712F;">-->
                <!--            只支持.jpg格式-->
                <!--        </div>-->
                <!--    </template>-->
                <!--    <div class="flex-div"-->
                <!--         style="width:100%;height:100%;flex-direction: column;justify-content: center;">-->
                <!--        <img style="width: 30px;height: 30px;" src="@/assets/icon_img_placeholder.png" alt="">-->
                <!--        <span style="font-size: 14px;color:#9F9F9F;line-height: 30px;">上传图片</span>-->
                <!--    </div>-->
                <!--</el-upload>-->
                <!--<my-masks :pos="6" :preview-img-item="previewImgItem" v-if="previewImgItem"-->
                <!--          @closeMask="previewImgItem=false"></my-masks>-->

            </div>
            <el-form-item label="启用" class="creation-item">
                <span class="required-option">*</span>
                <el-select v-model="form.status" placeholder="请选择">
                    <el-option v-for="(item, i) in options" :key="i" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <div>描述内容 <span class="required-option">*</span></div>
            <quill-editor
                :content="content" 
                ref="myQuillEditor" 
                :options="editorOption"
                @ready="onEditorReady($event)">
            </quill-editor>
            <el-form-item>
                <div class="submit-div" style="cursor: pointer;" @click="submit('form')">提交</div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css'
    import ImgCutter from "@/components/ImgCutter";
    import axios from 'axios'
    import {AddOrEditCreationData, GetResourceDetail} from "@/api";
    import {myMasks} from "@/components";
    import {getLocalStorage} from "@/utils";

    export default {
        name: "createPage",
        components: {myMasks, ImgCutter, QuillEditor},
        props: ['item'],
        data() {
            return {
                content:'<p>123</p>',
                editorOption: {
                    placeholder: "请在这里输入描述",
                    modules:{
                        toolbar:[
                            ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                            [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                            [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
                            [{ 'font': [] }],     //字体
                            [{ 'align': [] }],    //对齐方式
                            ['image']    //上传图片、上传视频
                        ]
                    }
                },


                options: [{
                    value: 1,
                    label: '是'
                }, {
                    value: 0,
                    label: '否'
                }],

                form: {
                    title: '',
                    sub_title: '',
                    show_form: 1,
                    show_lesson_option: 1,
                    img: '',
                    status: 1,  // 是否启用
                    content: '',
                },
                rules: {
                    title: [
                        {required: true, message: '标题不能为空', trigger: 'blur'}
                    ],
                    sub_title: [
                        {required: true, message: '副标题不能为空', trigger: 'blur'}
                    ]
                },
                fileList: [],   // 修改时显示默认的图片，上传时赋值上传成功的图片
                editItem: false,   // 要修改数据
                previewImgItem: false,
                ele: ''
            }
        },
        created() {
            if (this.$route.params.id) {
              console.log(this.$route.params.id);
              this.getDetail()
              this.editItem = true
            }
        },
        watch: {
          content(value) {
            if (value && this.ele) {
              this.ele.innerHTML = value
            }
          }
        },
        methods: {
            getDetail() {
                GetResourceDetail({
                  id: this.$route.params.id
                }).then(res => {
                  this.form = res.data
                  this.content = this.form.content
                  this.form.show_form = this.form.show_form ? 1 : 0
                  this.form.show_lesson_option = this.form.show_lesson_option ? 1 : 0
                  this.form.id = this.$route.params.id
                  delete this.form.share_id
                }).catch(err => {
                  this.$message.error(err.msg)
                })
            },

            onEditorReady (e) {
                this.ele = e.container.querySelector('.ql-blank')
            },

            // 上传图片 图片状态改变
            handleChange(changeInfo, fileList) {
                if (changeInfo.status === 'success') {
                    this.form.img = changeInfo.response.data
                    this.fileList = fileList
                }
            },

            // 预览图片
            previewImg() {
                let temp = document.getElementsByClassName('up-img')[0]
                if (temp.naturalWidth > 500 || temp.naturalHeight > 500)
                    this.$message.info('图片太大了，暂不支持预览哦')
                else
                    this.previewImgItem = {url: this.form.img, width: temp.naturalWidth, height: temp.naturalHeight};
            },

            // 删除图片
            deleteImg() {
                this.form.img = ''
                if (this.fileList)
                    this.fileList.splice(0, 1)
            },

            // 验证上传图片格式
            beforeImgUpload(file) {
                const isJPG = file.type === 'image/jpeg'
                if (!isJPG)
                    this.$message.error('上传图片只能是JPG或jpg格式')
                return isJPG
            },

            /** 图片编辑部分 START */
            cutDown(e) {
                const that = this
                let params = new FormData()
                params.append('file', e.file)
                axios.post(that.$store.state.uploadUrl, params, {
                        headers: {
                            "token": getLocalStorage('token'), //token
                        }
                    }
                ).then(res => {
                    // console.log(res)
                    if (res.data.code === 200) {
                        that.form.img = res.data.data
                    }
                }).catch(err => {
                    that.$message.error(err)
                })
            },
            /** 图片编辑部分 END */

            // 提交
            submit() {
                let that = this;
                console.log(this.ele.innerHTML);
                this.form.content = this.ele.innerHTML
                that.$refs['form'].validate((valid) => {
                    if (!that.form.img) {
                      that.$message.error('必需要上传图片！')
                      return
                    }
                    if (valid) {
                        console.log(this.form);
                        AddOrEditCreationData(this.editItem ? 'edit' : 'add', that.form).then((res) => {
                            that.$message.success(res.data)
                            that.$router.push('/myCreation')
                        }).catch((e) => {
                            that.$message.info(e.msg)
                        })
                    } else {
                        that.$message.error('描述内容不能为空！')
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "style/resourceCenterCommon";
    @import "/src/styles/common";

    .creation-wrap {
        .shadow-border-bgcolor();
        padding: 40px 0 100px 115px;
    }

    .creation-item {
        margin: 30px 0;

        .my-el-select(@w: 108px,@h: 36px);

        ::v-deep(.el-upload) {
            width: 160px;
            height: 100px;
            background: #FAFAFA;
            border: 1px solid #E9E9E9;
        }

        ::v-deep(.el-form-item__error) {
            left: 12%;
        }
    }

    .complete-imgs {
        width: 150px;
        height: 150px;
    }

    .my-el-input(@fontsize:14px,@backgroundcolor: #FAFAFA);
    .my-el-textarea(@fontsize:14px,@backgroundcolor: #FAFAFA);

    .submit-div {
        margin: 77px 0 0 300px;
        .test-btn-div(@ptb: 3px,@plr: 100px);
    }

  /deep/.ql-editor {
    width: 800px;
    height: 400px;
  }
  /deep/.ql-snow {
    width: 800px;
    .ql-tooltip[data-mode=link]::before {
      content: "请输入链接地址:";
    }

    .ql-tooltip.ql-editing a.ql-action::after {
      border-right: 0px;
      content: '保存';
      padding-right: 0px;
    }

    .ql-tooltip[data-mode=video]::before {
        content: "请输入视频地址:";
    }

    .ql-picker.ql-size,
    .ql-picker.ql-header {
      width: 70px;
    }

    .ql-picker.ql-size .ql-picker-label::before,
    .ql-picker.ql-size .ql-picker-item::before {
      content: "14px";
    }

    .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
      content: "10px";
    }

    .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
      content: "18px";
    }

    .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
    .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
      content: "32px";
    }

    .ql-picker.ql-header .ql-picker-label::before,
    .ql-picker.ql-header .ql-picker-item::before {
      content: "文本";
    }

    .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
      content: "标题1";
    }

    .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
      content: "标题2";
    }

    .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
      content: "标题3";
    }

    .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
      content: "标题4";
    }

    .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
      content: "标题5";
    }

    .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
      content: "标题6";
    }

    .ql-picker.ql-font .ql-picker-label::before,
    .ql-picker.ql-font .ql-picker-item::before {
      content: "标准字体";
    }

    .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
      content: "衬线字体";
    }

    .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
    .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
      content: "等宽字体";
    }
  }
  
</style>