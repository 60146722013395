<template>
    <div class="module-wrap">
        <div class="top-div">
            <big-title :img-src="sentResourceIconImg" title="已发素材"></big-title>
            <div class="more-div flex-div" @click="toPage">
                <span>更多</span>
                <img src="../../assets/more_row.png">
            </div>
        </div>
        <div class="day-item" v-for="(item, i) in dataList" :key="i" @click="toPage">
            <span style="padding-top:50px; font-size: 30px;color: #F58B52;">{{item.date}}</span>
            <div style="margin-left: 20px;">
                <div class="one-item flex-div" v-for="(l, j) in item.list" :key="j">
                    <img :src="l.img" alt="暂无图片">
                    <div class="text-div">
                        <span style="display: block;">{{l.title}}</span>
                        <div style="margin-top: 22px;">
                            <span>访客数：{{l.brow_count}}</span>
                            <span>浏览量：{{l.total_brow_count}}</span>
                            <span>评论：{{l.comment_count}}</span>
                            <span>素材类型：{{l.cabinet_type}}</span>
                            <span>分享方式：{{l.share_type}}</span>
                            <span>分享时间：{{l.add_time}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
    </div>
</template>

<script>

    import bigTitle from "./components/big-title.vue";
    import {GetSentResource} from "@/api";
    import {getPageInfo} from "@/utils";

    export default {
        name: "sentResource",
        components: {
            bigTitle,
        },
        // props:[]
        data() {
            return {
                dataList: [],
                pageInfo: {},
                sentResourceIconImg: require("@/assets/icon_latestResource.png"),
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData(curPage) {
                let that = this
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                GetSentResource(temp).then((res) => {
                    that.processData(res.data.page_data)
                    that.pageInfo = getPageInfo(res.data)
                })
            },

            // 处理数据格式
            processData(data) {
                let that = this
                let temp = [];
                let i = 0
                data[0].cabinet_type = that.getCabinetType(data[0].cabinet_type)
                temp[i] = {date: data[0].date, list: [data[0]]}
                for (let j = 1; j < data.length; j++) {
                    data[j].cabinet_type = that.getCabinetType(data[j].cabinet_type)
                    if (data[j].date === temp[i].date)
                        temp[i].list.push(data[j])
                    else {
                        temp.push({date: data[j].date, list: [data[j]]})
                        i++;
                    }
                }
                that.dataList = temp
            },

            // 处理素材类型
            getCabinetType(type) {
                switch (type) {
                    case 0:
                        return '推广好文'
                    case 1:
                        return '推广海报'
                    case 2:
                        return '线下素材'
                    default:
                        return '推广好文'
                }
            },

            // 跳转页面
            toPage() {
                this.$router.push('/dataCenterPromotion')
            },

            // 切换页数
            changePageIndex(curPage) {
                this.getData(curPage)
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";
    @import "./style/overviewCommon";

    .top-div {
        display: flex;
        justify-content: space-between;
    }

    .day-item {
        margin-left: 20px;
        display: flex;
        cursor: pointer;

        .one-item {
            margin: 30px 0;
            padding: 10px 0 10px 20px;
            width: 1360px;
            height: 110px;
            background: #F7F8FD;

            img {
                .my-img-wh-mr-bradius(@w: 190px,@h: 110px,@mr: 33px);
            }

            .text-div {
                div span {
                    margin-right: 20px;
                }
            }
        }

        .one-item:nth-child(2n) {
            background: #ffffff;
        }
    }
</style>