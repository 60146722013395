import {getLocalStorage} from "@/utils";

export default {
    namespaced: true,
    state: {
        IMOpen: false,   // IM 打开状态
        totalUnreadMsgs: 0,    // 总的未读消息数（右下角）
        chatWindowOpen: false,  // 聊天框是否有打开
        // 最近联系人 包含属性：用户id-uid,用户昵称nickname，用户头像icon，最近聊天时间time，最新一条消息newMsg，最新一条消息是否是自己发出的isSelf
        // 登录后获取并与接口（未读消息）数据合并，增加未读消息红点 对应到每一格用户有几条消息
        recentUsers: [],
        chatUsers: [],          // 正在打开聊天框的对象集合
        chatIndex: 0,           // 正在聊天的对象下标？   用对象形式还是下标形式标记？
        
    },
    mutations: {
        setIMOpen(state, s) {
            state.IMOpen = s
        },
        setChatWindowOpen(state, s) {
            state.chatWindowOpen = s
        },
        setTotalUnreadMsgs(state, num) {
            state.totalUnreadMsgs = num
        },
        // 关闭聊天框时（或关闭网页）添加最新联系人（确定了最近一次聊天的最新消息内容和时间）
        // 从通讯录中点击一个用户进行聊天，大概率是新用户(没有聊天需要添加到最近联系人吗，点击时间，或先添加，关闭IM清空没有消息的人？)
        setRecentUsers(state, users) {
            if (users) {
                // 依次判断是否已有相关用户，有则删除，否则按时间“插入”
                let l = users.length;
                let i = 0,l1,j;
                for (; i < l; i++) {
                    l1 = state.recentUsers.length;
                    j = 0;
                    for (; j < l1; j++) {
                        // 如果存在，则删除
                        if (state.recentUsers[j].uid === users[i]) {
                            state.recentUsers.splice(j, 1)
                            break
                        }
                    }
                }
                // 依次按时间插入  users 需要有最新消息内容和时间
                i = 0;
                for (; i < l; i++) {
                    l1 = state.recentUsers.length;
                    j = l1 - 1
                    for (; j >= 0; j--) {
                        // 时间小（最新消息早）向后移动，为users留出空间
                        if (state.recentUsers[j].time < users[i].time) {
                            state.recentUsers[j + 1] = state.recentUsers[j]
                        } else {  // else 插入，并跳出
                            state.recentUsers[j + 1] = users[i]
                            break
                        }
                    }
                    if (j === -1) {
                        state.recentUsers[0] = users[i]
                    }
                }
            } else {  // users空  直接从内存中获取
                // 从接口获取最新消息等，从storage中取出，合并
                // state.recentUsers = JSON.parse(getLocalStorage('recentUsers'))
            }
        }
    },
    actions: {
        // addTotalUnreadMsgs(state, addNum) {
        //     state.commit('setTotalUnreadMsgs', state.totalUnreadMsgs + addNum)
        // },
        // processRecentUsers(state, users) {
        //     let temp;
        //     state.commit('setRecentUsers', temp)
        // }
    },
    // getters: {
    //     // 最近联系人 包含属性：用户id,用户昵称，最近聊天时间，(最近一条消息）  登录后获取并与接口数据合并，增加未读消息红点
    //     recentUsers:state=>{
    //         // 由true -> false
    //         return state.chatWindowOpen
    //         // if(!state.chatWindowOpen){
    //         //     console.log('closeclose')
    //         //     return 'closecloseclose'
    //         // }else{
    //         //     console.log('openopenopen')
    //         //     return 'openopenopen'
    //         // }
    //     }
    // }
}