// import './pageComponent.js'

import Layout from "@/layout/index.vue"
import areaDataIndex from "@/views/areaData/areaDataIndex.vue"
import areaCourse from "@/views/areaData/areaCourse.vue"
import m3u8 from "@/views/areaData/m3u8.vue"
import editLiveCourse from "@/views/areaData/editLiveCourse.vue"
import chatRoom from "@/views/areaData/liveChatRoom.vue"
import dataCenterIndex from "@/views/dataCenter/dataCenterIndex.vue"
import dataUserComment from "@/views/dataCenter/module/dataUserComment.vue"
import dataUserList from "@/views/dataCenter/module/dataUserList.vue"
import dataCenterPromotionChart from "@/views/dataCenter/module/dataCenterPromotionChart.vue"
import Login from "@/views/login.vue"

import knowledgeCommodity from "@/views/ordersManage/knowledgeCommodity.vue"
import physicalCommodity from "@/views/ordersManage/physicalCommodity.vue"
import registration from "@/views/ordersManage/registration.vue"

import overview from "@/views/overview/index.vue"
import more from "@/views/overview/more.vue"

import apply from "@/views/permission/apply.vue"
import conflict from "@/views/permission/conflict.vue"

import personalInfo from "@/views/personalInformation/index.vue"
import createPage from "@/views/resourceCenter/createPage.vue"
import givingCourses from "@/views/resourceCenter/givingCourses.vue"
import givingRecords from "@/views/resourceCenter/givingRecords.vue"
import myCreation from "@/views/resourceCenter/myCreation.vue"
import promotionPoster from "@/views/resourceCenter/promotionPoster.vue"

import promotionArticle from "@/views/resourceCenter/promotionArticle.vue"
import correct from "@/views/taskSystem/correct.vue"
import correctPage from "@/views/taskSystem/correctPage.vue"
import parentsWork from "@/views/taskSystem/parentsWork.vue"

import announcement from "@/views/tutorial/announcement.vue"
import feedback from "@/views/tutorial/feedback.vue"
import graphicTutorials from "@/views/tutorial/graphicTutorials.vue"
import contacts from "@/views/tutorial/contacts.vue"
import liveTraining from "@/views/tutorial/liveTraining.vue"
import livingDetail from "@/views/tutorial/livingDetail.vue"

import groupMessage from "@/views/usersManage/groupMessage/groupMessage.vue"
import createGroupMsg from "@/views/usersManage/groupMessage/createGroupMsg.vue"
import tagManage from "@/views/usersManage/groupMessage/tagManage.vue"
import createTag from "@/views/usersManage/groupMessage/createTag.vue"
import usersDetail from "@/views/usersManage/usersDetail.vue"
import usersList from "@/views/usersManage/usersList.vue"

import {createRouter, createWebHashHistory} from "vue-router";
// import store from '../store/index.js'

let routes = [
    {
        path: '/login',
        name: '登录',
        meta: {
            hideInSidebar: true,
            hideInBread: true,
            hideChat: true,
            title: '登录',
            // access:['1']
        },
        component: Login,
        children: [],
    },
    {
        path: '/overview',
        name: '概况总览',
        meta: {
            title: '概况总览'
        },
        component: Layout,
        children: [{
            path: '/overview',
            component: overview,
            meta: {
                title: '概况总览'
            },
            children: [{
                path: '/more/:imgSrc/:title',
                name: 'more',
                component: more,
                props: true,
                meta: {
                    title: '更多'
                }
            }]
        }]
    },
    {
        path: '/personalInfo',
        name: '个人信息',
        meta: {
            title: '个人信息'
        },
        component: Layout,      // component,不是components!!!!!
        children: [{
            path: '/personalInfo',
            component: personalInfo,
            meta: {
                title: '个人信息'
            },
        }]
    },
    {
        path: '/promotionArticle',
        name: '素材中心',
        meta: {
            title: '素材中心',
        },
        component: Layout,
        // props:true,   // 适用于静态参数
        children: [{
            path: '/promotionArticle',
            name: '推广好文',
            meta: {
                title: '推广好文',
            },
            component: promotionArticle
        }, {
            path: '/promotionPoster',
            name: '推广海报',
            meta: {
                title: '推广海报',
            },
            component: promotionPoster
        }, {
            path: '/givingCourses',
            name: '赠送课程',
            meta: {
                title: '赠送课程',
            },
            component: givingCourses,//routeReplaceSelf(givingCourses),
            children: [{
                path: '/givingRecords',
                name: '赠送记录',
                meta: {
                    title: '赠送记录',
                    hideInSidebar: true,
                },
                component: givingRecords
            }]
        }, {
            path: '/myCreation',
            name: '我的创作',
            meta: {
                title: '我的创作',
            },
            component: myCreation,
            children: [{
                path: '/createPage/:item?',
                name: 'createPage',
                meta: {
                    title: '我要创作',
                    hideInSidebar: true,
                },
                component: createPage
            }]
        }]
    },
    {
        path: '/usersList',
        name: '用户管理',
        meta: {
            title: '用户管理'
        },
        component: Layout,
        children: [{
            path: '/usersList',
            name: '用户列表',
            meta: {
                title: '用户列表',
            },
            component: usersList,
            children: [{
                path: '/usersDetail/:user_id',
                name: 'usersDetail',
                meta: {
                    title: '用户详情',
                    hideInSidebar: true
                },
                component: usersDetail
            }]
        }, {
            path: '/groupMessage',
            name: '群发消息',
            meta: {
                title: '群发消息',
            },
            component: groupMessage,
            children: [{
                path: '/createGroupMsg/:w',
                name: 'createGroupMsg',
                meta: {
                    title: '新建群发',
                    hideInSidebar: true,
                },
                component: createGroupMsg
            }, {
                path: '/tagManage',
                name: 'tagManage',
                meta: {
                    title: '标签管理',
                    hideInSidebar: true,
                },
                component: tagManage,
                children: [{
                    path: '/createTag',
                    name: 'createTag',
                    meta: {
                        title: '新建标签',
                        hideInSidebar: true,
                    },
                    component: createTag
                }]
            }]
        }]
    },
    {
        path: '/knowledgeCommodity',
        name: '订单管理',
        meta: {
            title: '订单管理'
        },
        component: Layout,
        children: [{
            path: '/knowledgeCommodity',
            name: '知识商品',
            meta: {
                title: '知识商品',
            },
            component: knowledgeCommodity
        }, {
            path: '/physicalCommodity',
            name: '实物商品',
            meta: {
                title: '实物商品',
            },
            component: physicalCommodity
        }, {
            path: '/registration',
            name: '报名信息',
            meta: {
                title: '报名信息',
            },
            component: registration
        }]
    },
    {
        path: '/dataCenterPromotion',
        name: '数据中心',
        meta: {
            title: '数据中心'
        },
        component: Layout,
        children: [{
            path: '/dataCenterPromotion',
            name: '推广分析',
            meta: {
                title: '推广分析',
            },
            component: dataCenterIndex,
            redirect: '/dataChart',
            children: [
                {
                    path: '/dataChart',
                    name: 'dataChart',
                    meta: {
                        title: '推广图表',
                        // hideInSidebar: true
                    },
                    component: dataCenterPromotionChart
                },
                {
                    path: '/dataUserList',
                    name: 'dataUserList',
                    meta: {
                        title: '用户列表',
                        // hideInSidebar: true
                    },
                    component: dataUserList
                },
                {
                    path: '/dataUserComment',
                    name: '用户评论',
                    meta: {
                        title: '用户评论',
                        // hideInSidebar: true
                    },
                    component: dataUserComment
                }]
        }, {
            path: '/dataCenterSalesAnalysis',
            name: '销售分析',
            meta: {
                title: '销售分析',
            },
            component: dataCenterIndex
        }, {
            path: '/dataCenterUserAnalysis',
            name: '用户分析',
            meta: {
                title: '用户分析',
            },
            component: dataCenterIndex
        }, {
            path: '/dataCenterVipAnalysis',
            name: '会员分析',
            meta: {
                title: '会员分析',
            },
            component: dataCenterIndex
        }]
    },
    {
        path: '/apply',
        name: '权限申诉',
        meta: {
            title: '权限申诉'
        },
        component: Layout,
        children: [{
            path: '/apply',
            name: '权限申请',
            meta: {
                title: '权限申请',
            },
            component: apply
        }, {
            path: '/conflict',
            name: '冲突申诉',
            meta: {
                title: '冲突申诉',
            },
            component: conflict,
            redirect: '/retrieve',
            children: [{
              path: '/retrieve',
              name: '冲突申诉-检索',
              meta: {
                title: '检索',
              },
              component: () => import('@/views/permission/components/retrieve.vue')
            }, {
              path: '/record',
              name: '冲突申诉-记录',
              meta: {
                title: '记录',
              },
              component: () => import('@/views/permission/components/record.vue')
            }]
        }]
    },
    {
        path: '/parentsWork',
        name: '作业系统',
        meta: {
            title: '作业系统'
        },
        component: Layout,
        children: [{
            path: '/parentsWork',
            name: '家长作业',
            meta: {
                title: '家长作业',
            },
            component: parentsWork
        }, {
            path: '/correct',
            name: '作业批改',
            meta: {
                title: '作业批改',
            },
            component: correct,
            children: [{
                path: '/correctPage/:id/:parents_id',
                name: 'correctPage',
                meta: {
                    title: '查看',
                    hideInSidebar: true,
                },
                component: correctPage
            }]
        }]
    },
    {
        path: '/announcement',
        name: '使用教程',
        meta: {
            title: '使用教程'
        },
        component: Layout,
        children: [{
            path: '/announcement',
            name: '公告',
            meta: {
                title: '公告',
            },
            component: announcement
        }, {
            path: '/graphicTutorials',
            name: '图文教程',
            meta: {
                title: '图文教程',
            },
            component: graphicTutorials
        }, {
            path: '/liveTraining',
            name: '直播培训',
            meta: {
                title: '直播培训',
            },
            component: liveTraining,
        }, {
            path: '/contacts',
            name: '通讯录',
            meta: {
                title: '通讯录',
            },
            component: contacts
        }, {
            path: '/feedback',
            name: '意见反馈',
            meta: {
                title: '意见反馈',
            },
            component: feedback
        }]
    },
    {
        path: '/areaDataPromotionAnalysis',
        name: '地区服务',
        meta: {
            title: '地区服务',
            // hideInSidebar: $store.state.loginUserinfo.area_admin.id === 0
        },
        component: Layout,
        children: [{
            path: '/areaDataPromotionAnalysis',
            name: '数据分析',    // 不能重名
            meta: {
                title: '数据分析',
            },
            component: areaDataIndex,
            children: [{
                path: '/areaDataPromotionAnalysis',
                name: '推广分析1',    // 不能重名
                meta: {
                    title: '推广分析',
                    hideInSidebar: true
                },
                component: areaDataIndex
            }, {
                path: '/areaDataSalesAnalysis',
                name: '销售分析1',
                meta: {
                    title: '销售分析',
                    hideInSidebar: true
                },
                component: areaDataIndex
            }, {
                path: '/areaDataUserAnalysis',
                name: '用户分析1',
                meta: {
                    title: '用户分析',
                    hideInSidebar: true
                },
                component: areaDataIndex
            }, {
                path: '/areaDataVipAnalysis',
                name: '会员分析1',
                meta: {
                    title: '会员分析',
                    hideInSidebar: true
                },
                component: areaDataIndex
            }]
        }, {
            path: '/areaCourse',
            name: '地区课程',    // 不能重名
            meta: {
                title: '地区课程',
            },
            component: areaCourse,
            children: [{
                path: '/editLiveCourse',
                name: 'editLiveCourse',
                meta: {
                    title: '添加直播课',
                    hideInSidebar: true
                },
                component: editLiveCourse
            }]
        }],
    },
    {
        path: '/livingDetail/:id',
        name: 'livingDetail',
        meta: {
            title: '直播详情',
            hideInSidebar: true,
            hideChat: true,
        },
        component: livingDetail
    },
    {
        path: '/chatRoom/:id',
        name: 'chatRoom',
        meta: {
            title: '管理聊天室',
            hideInSidebar: true,
            hideChat: true
        },
        component: chatRoom
    },
    {
        path: '/m3u8/:url',
        name: 'm3u8',
        meta: {
            title: '观看视频',
            hideInSidebar: true,
            hideChat: true
        },
        component: m3u8
    },
    {
        path: '/',
        redirect: '/login',
        // meta: {
        //     hideChat: true
        // }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// router.beforeEach(async (to, from, next) => {
//     console.log(to)
//     next()
// })

export default router
