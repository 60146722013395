<template>
    <div class="app-wrapper">
        <div class="sideBar">
            <img class="logo-pic" src="@/assets/logo.png" alt="">
            <div class="sideBar-main">
                <div class="sidebar-item" v-for="(item,i) in sidebarList" :key="i">
                    <!-- 没有子元素 -->
                    <div class="sidebar-title flex-div" v-if="item.children.length === 1"
                         :class="$store.state.breadCrumbNameArr.indexOf(item.name)!==-1 ? 'sidebar-title-choosen' : ''">
                        <img v-if="item.path === $route.path" class="icon-l"
                             :src="require('@/assets/sideBarIcons/icon' + i + '_selected.png')"
                             alt=""/>
                        <img v-else class="icon-l" :src="require('@/assets/sideBarIcons/icon' + i + '_unselected.png')"
                             alt=""/>
                        <router-link :to="item.path"><span>{{item.name}}</span></router-link>
                    </div>
                    <!-- 有子元素，非地区服务模块 -->
                    <div class="sidebar-title flex-div" v-else-if="item.name !== '地区服务'">
                        <img class="icon-l" :src="require('@/assets/sideBarIcons/icon' + i + '_unselected.png')"
                             alt=""/>
                        <router-link :to="item.path" style="width: 60%;"><span>{{item.name}}</span></router-link>
                        <img class="icon-r" v-if="item.children.length !== 1"
                             src="@/assets/sideBarIcons/sidebar-row.png" alt=""/>
                    </div>
                    <!-- 地区服务 -->
                    <div class="sidebar-title flex-div" v-else-if="$store.state.loginUserinfo.area_admin.id !== 0">
                        <img class="icon-l" :src="require('@/assets/sideBarIcons/icon' + i + '_unselected.png')"
                             alt=""/>
                        <router-link :to="item.path" style="width: 60%;"><span
                                :title="$store.state.loginUserinfo.area_admin.area"
                                class="name-overflow">{{item.name}}({{$store.state.loginUserinfo.area_admin.area}})</span>
                        </router-link>
                        <img class="icon-r" v-if="item.children.length !== 1"
                             src="@/assets/sideBarIcons/sidebar-row.png" alt=""/>
                    </div>
                    <!-- 子元素 -->
                    <div class="sidebar-titles"
                         v-if="item.children.length > 1 && ((item.name !== '地区服务') || ( item.name === '地区服务' && $store.state.loginUserinfo.area_admin.id !== 0))">
                        <template v-for="(subtitle, index) in item.children" :key="index">
                            <router-link :to="subtitle.path">
                                <span :style="$store.state.breadCrumbNameArr.indexOf(subtitle.name)!==-1 ? chooseSty:{}">{{subtitle.name.replace(/\d+/g,'')}}</span>
                                <!--<span :style="breadCrumbPath.indexOf($route.path) !== -1 ? chooseSty:{}">{{subtitle.name.replace(/\d+/g,'')}}</span>-->
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightPanel">
            <div class="header">
                <el-breadcrumb separator="/" style="font-size: 16px;">
                    <template v-for="(item,i) in $store.state.breadCrumbList" :key="i">
                        <el-breadcrumb-item :to="{ path: item.path }" v-if="(i+1)!==$store.state.breadCrumbList.length">
                            {{item.meta.title.replace(/\d+/g,'')}}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item v-else>
                            {{item.meta.title.replace(/\d+/g,'')}}
                        </el-breadcrumb-item>
                    </template>
                </el-breadcrumb>
                <div class="header_r flex-div" @click="exitFlag = !exitFlag">
                    <img :src="user.icon" alt="">
                    <span>{{user.nickname}}</span>
                    <img class="exit-img" src="@/assets/my-down-row.png" alt="">
                    <div class="exit-div" v-if="exitFlag" @click="confirmExit">退出</div>
                </div>
            </div>
            <div class="main-container">
                <!--<keep-alive>-->
                <router-view :key="$route.fullPath"/>
                <!--</keep-alive>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {getBreadCrumb, getExportLoading, getSidebarList, removeLocalStorage} from "@/utils";

    export default {
        name: "Layout",
        data() {
            return {
                sidebarList: [],      // 侧边栏列表
                user: {},

                breadCrumbPath: [],
                chooseSty: {
                    background: '#fff',
                    color: '#406AD5',
                },
                // 点击退出
                exitFlag: false,
            }
        },
        watch: {
            '$route'(newRoute, oldRoute) {
                if (newRoute !== oldRoute) {
                    getBreadCrumb(newRoute, this);
                    // this.breadCrumbPath = []
                    // this.$store.state.breadCrumbList.forEach((item)=>{
                    //     this.breadCrumbPath.push(item.path)
                    // })
                    // console.log(this.breadCrumbPath)
                    // console.log(this.$route.path)
                    // console.log(this.breadCrumbPath.indexOf(this.$route.path))
                }
            },
        },
        mounted() {
            this.sidebarList = getSidebarList()
            getBreadCrumb(this.$route, this);
            // this.breadCrumbPath = []
            // this.$store.state.breadCrumbList.forEach((item)=>{
            //     this.breadCrumbPath.push(item.path)
            // })
            this.user = this.$store.state.loginUserinfo
        },
        methods: {
            confirmExit() {   // 清空token信息
                removeLocalStorage('token')
                removeLocalStorage('loginUserinfo')
                removeLocalStorage('someInfo')
                this.$router.replace('/login')
            }
        }
    }
</script>

<style lang="less">
    @import '/src/styles/layout.less';

    .name-overflow {
        display: block;
        width: 90%;
        .text-over-hidden(90%)
    }
</style>