<template>
    <video id="myVideo" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto" :width="w" :height="h" data-setup='{}'>
        <source id="source" :src="$route.params.url"  type="application/x-mpegURL">
    </video>
</template>

<script>
    import videojs from 'video.js'
    export default {
        name: "m3u8",
        data(){
            return{
                w:window.innerWidth,
                h:window.innerHeight,
            }
        },
        mounted(){
            let myVideo = videojs('myVideo',{
                bigPlayButton : true,
                textTrackDisplay : false,
                posterImage: false,
                errorDisplay : false,
            })
            myVideo.play() // 视频播放
            // myVideo.pause() // 视频暂停
        },
    }
</script>

<style scoped>

</style>