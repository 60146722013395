<template>
    <router-view v-if="showView"></router-view>
    <div class="area-course-wrap" v-else>
        <title-underline-choice :choices="choices" :choice-index=choiceIndex
                                @getChoice="makeChoice"></title-underline-choice>
        <div class="area-course-part1" v-if="choiceIndex===0">
            <div class="area-course-part1-l" @click="toPage">添加直播课</div>
            <div class="area-course-part1-r">
                <span style="margin-right: 20px">所属地区</span>
                <el-select v-model="defaultValue" placeholder="请选择">
                    <el-option v-for="(item, i) in areas" :label="item.area" :key="i"
                               :value="item.id"></el-option>
                </el-select>
            </div>
        </div>
        <div style="height: 60px" v-else></div>
        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}" fit
                  :cell-style="{cursor:'pointer'}"
                  highlight-current-row empty-text="暂无数据" v-if="choiceIndex===0" @row-click="clickItem">

            <el-table-column v-for="i in tableTitles.length" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                             align="center">
                <template #default="scope">
                    <img :src="scope.row[tableProps[i-1]]" style="width: 120px;height: auto;border-radius: 5px" alt
                         v-if="i===3">
                    <div class="action-class" v-else-if="i===tableTitles.length">
                        <span @click.stop="getActionChoice(scope.row,0)">{{actions[0]}}</span>
                        <template v-if="actions.length>1">
                            <span class="line">|</span>
                            <span @click.stop="getActionChoice(scope.row,1)">{{actions[1]}}</span>
                            <span class="line">|</span>
                            <span @click.stop="getActionChoice(scope.row,2)">{{actions[2]}}</span>
                            <span class="line">|</span>
                            <span @click.stop="getActionChoice(scope.row,3)">{{actions[3]}}</span>
                            <span class="line">|</span>
                            <el-popover placement="top" :width="80" :visible="popVisible[scope.row.id]===true">
                                <span style="display:inline-block;margin-bottom: 5px;">确定删除？</span>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="popVisible[scope.row.id] = false">取消
                                    </el-button>
                                    <el-button type="primary" size="mini" @click="confirmDelete(scope.row)">确定
                                    </el-button>
                                </div>
                                <template #reference>
                                    <span @click.stop="getActionChoice(scope.row,4)">{{actions[4]}}</span>
                                </template>
                            </el-popover>
                        </template>
                    </div>
                    <span v-else>{{scope.row[tableProps[i-1]]}}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}" fit
                  highlight-current-row empty-text="暂无数据" v-else>

            <el-table-column v-for="i in tableTitles.length-1" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                             align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <div class="action-class">
                        <span @click="getActionChoice(scope.row,0)">{{actions[0]}}</span>
                        <span class="line">|</span>
                        <el-popover placement="top" :width="80" :visible="popVisible[scope.row.id]===true">
                            <span style="display:inline-block;margin-bottom: 5px;">确定删除？</span>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="popVisible[scope.row.id] = false">取消
                                </el-button>
                                <el-button type="primary" size="mini" @click="confirmDelete(scope.row)">确定</el-button>
                            </div>
                            <template #reference>
                                <span @click="getActionChoice(scope.row,1)">{{actions[1]}}</span>
                            </template>
                        </el-popover>
                    </div>

                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
        <my-masks v-if="showLiveTips" :pos="9" @closeMask="showLiveTips=false"></my-masks>
        <my-masks v-if="showActionPanel" :pos="10" @chooseAction="chooseAction"
                  @closeMask="showActionPanel=false"></my-masks>
    </div>
</template>

<script>
    import TitleUnderlineChoice from "@/components/titleUnderlineChoice";
    import {GetAreaLiveList, GetAreaList, DeleteLiveData} from "@/api";
    import MyMasks from "@/components/myMasks";
    import {getLoading, getLocalStorage, getPageInfo} from "@/utils";

    let areaObj = {}

    export default {
        name: "areaCourse",
        components: {MyMasks, TitleUnderlineChoice},
        data() {
            return {
                choices: ['直播列表', '直播回放'],
                choiceIndex: 0,
                areas: [],
                defaultValue: 0,
                areaAdmin: {},

                tableTitles: [],
                tableProps: [],
                actions: [],
                tableData: [],
                pageInfo: {},

                popVisible: {},

                curItem: {},  // 当前操作的对象

                showLiveTips: false,  // 是否显示直播步骤的弹框
                showActionPanel: false, // 点击整行，显示控制面板
            }
        },
        watch: {
            defaultValue(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue !== this.areaAdmin.id) {
                        // this.tableTitles.pop()
                        this.actions = ['打开']
                    } else {
                        this.initData()
                    }
                    this.getData()
                }
            }
        },
        computed: {
            showView() {
                return this.$route.matched.length === 3
            }
        },
        mounted() {
            let that = this
            that.initData()
            that.getAreaData();
        },
        methods: {
            // 获取直播地区
            getAreaData() {
                const that = this
                that.areaAdmin = JSON.parse(getLocalStorage('loginUserinfo')).area_admin
                that.defaultValue = that.areaAdmin.id
                GetAreaList(that.areaAdmin).then((res) => {
                    that.areas = res.data
                    areaObj = {}
                    res.data.forEach((item) => {
                        areaObj[item.id] = item.area
                    })
                }).catch((e) => {
                    that.$message.error(e.msg)
                })
            },

            // 获取数据
            getData(curPage) {
                let that = this
                Object.assign(that.popVisible, {})
                let temp = {
                    area_id: that.defaultValue
                }
                if (curPage)
                    temp['page'] = curPage
                // 获取直播课程    缺少目标人群
                GetAreaLiveList(temp, that.choiceIndex).then((res) => {
                    if (that.choiceIndex === 0)
                        that.tableData = that.processData(res.data.page_data)
                    else
                        that.tableData = that.processRecordData(res.data.page_data)
                    that.pageInfo = getPageInfo(res.data)
                }, (e) => {
                    that.$message.error(e.msg)
                })
            },
            // 处理直播列表数据
            processData(data) {
                data.forEach((item) => {
                    item['type1'] = item['type'] ? '服务课' : '公开课'
                    item['status1'] = !item['status'] ? '直播已结束' : item['status'] === 1 ? '直播未开始' : '正在直播'
                    item['enable1'] = item['enable'] ? '已启用' : '未启用'
                })
                return data
            },

            // 处理回放列表数据
            processRecordData(data) {
                let m, n;
                data.forEach((item) => {
                    m = Math.ceil(item['duration'])
                    item['duration'] = ''
                    n = m % 3600
                    m = Math.floor(m / 3600)
                    if (m)
                        item['duration'] += m + '小时'
                    m = Math.floor(n / 60)
                    n = n % 60
                    if (m)
                        item['duration'] += m + '分钟'
                    item['duration'] += n + '秒'
                })
                return data
            },

            // 确定删除
            confirmDelete(item) {
                const that = this
                that.popVisible[item.id] = false
                DeleteLiveData({id: item.id}, that.choiceIndex).then((res) => {
                    that.$message.success(res.data)
                    that.getData()
                }).catch((e) => {
                    that.$message.error(e.msg)
                })
            },

            // 只有打开按钮时课操作一行
            clickItem(item) {
                if (this.actions.length === 1)
                    this.getActionChoice(item, 0)
                else {
                    this.showActionPanel = true
                    this.curItem = item
                }
            },

            chooseAction(i) {
                this.showActionPanel = false
                this.getActionChoice(this.curItem, i)
            },

            // 表格操作
            getActionChoice(item, ai) {
                let that = this
                if (that.choiceIndex === 0) {
                    if (ai === 0) {
                        let routeUrl = this.$router.resolve({name: 'livingDetail', params: {id: item.id}})
                        window.open(routeUrl.href, '_blank')
                    } else if (ai === 1) {
                        let routeUrl = this.$router.resolve({name: 'chatRoom', params: {id: item.id}})
                        window.open(routeUrl.href, '_blank')
                    } else if (ai === 2) {
                        that.showLiveTips = true
                    } else if (ai === 3) {
                        that.$store.commit('setEditLiveCourse', item)
                        that.$router.push({name: 'editLiveCourse', params: {item: 1}})
                    } else {
                        that.popVisible[item.id] = true
                    }
                } else {
                    if (ai === 0) {
                        if (item.uri.indexOf('.mp4') === -1) {
                            that.$message.info('该视频格式暂不支持下载！马上为您跳转新页面观看哦~~')
                            setTimeout(function () {
                                let routeUrl = that.$router.resolve({name: 'm3u8', params: {url: item.uri}})
                                window.open(routeUrl.href, '_blank')
                            }, 500)
                        } else {
                            that.downloadVideo(item.uri, item.title)
                        }
                    } else {
                        that.popVisible[item.id] = true
                    }
                }
            },

            // 下载视频
            downloadVideo(url, name) {
                const that = this
                let loading = getLoading(that, '.area-course-wrap')
                let xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.addEventListener("progress", function (obj) {
                    if (obj.lengthComputable) {
                        let percentComplete = obj.loaded / obj.total;
                        let p = (percentComplete * 100).toFixed(2) + "%"
                        // 可得到下载进度
                        console.log(p)
                        loading.setText(`正在下载: ${p}`)
                        if (p === '100.00%') {
                            setTimeout(function () {
                                loading.close();
                            }, 200)
                        }
                    }
                }, false);
                xhr.responseType = 'blob'; // 设置返回类型blob
                xhr.onload = function () {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let blob = this.response;
                        // 转换一个blob链接
                        let u = window.URL.createObjectURL(new Blob([blob], {
                            type: 'video/mp4'
                        }))
                        let a = document.createElement('a');
                        a.download = name; //这里是文件名称，这里暂时用链接代替，可以替换
                        a.href = u;
                        a.style.display = 'none'
                        document.body.appendChild(a)
                        a.click();
                        a.remove();
                    }
                };
                xhr.send()
            },

            // tab切换
            makeChoice(i) {
                this.choiceIndex = i
                this.initData()
                this.getData()
            },

            // 表格页码切换
            changePageIndex(curPage) {
                this.getData(curPage)
            },

            // 页面跳转
            toPage() {
                this.$router.push('/editLiveCourse')
            },

            // 初始化数据
            initData() {
                let that = this
                if (that.choiceIndex === 0) {
                    that.tableTitles = ['标题', '副标题', '图片', '所属老师', '所属地区', '直播类型', '直播状态', '开始时间', '启用状态', '操作']
                    that.tableProps = ['title', 'sub_title', 'img', 'teacher_name', 'area', 'type1', 'status1', 'start_time', 'enable1', '']
                    that.actions = ['打开', '管理聊天室', '去直播', '修改', '删除']
                    that.tableData = []
                } else {
                    that.tableTitles = ['所属直播', '文件时长(秒)', '开始时间', '结束时间', '生成时间', '操作']
                    that.tableProps = ['title', 'duration', 'start_time', 'stop_time', 'add_time', '']
                    that.actions = ['下载', '删除']
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .area-course-wrap {
        .shadow-border-bgcolor();
        padding: 32px 20px 50px 20px;
    }

    .area-course-part1 {
        margin: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .area-course-part1-l {
            padding: 5px 10px;
            border-radius: 5px;
            background-color: #2C8EFC;
            color: #fff;
        }

        .area-course-part1-r {
            display: flex;
            align-items: center;
            .my-el-select(@w: 160px)
        }
    }

</style>