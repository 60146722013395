<template>
    <div class="user-detail-wrap">
        <div class="user-detail-part1">
            <div class="avatar-name">
                <div class="avatar-name-l">
                    <img :src="infoValue.icon" alt="">
                    <div>
                        <span style="display:block;color: #222222">{{infoValue.nickname}}</span>
                        <div class="real-name">
                            <el-input placeholder="请编辑真实姓名" v-model="infoValue.name" v-if="editNameFlag" ref="realname"
                                      @blur="changeName"></el-input>
                            <template v-else>
                                <el-input placeholder="请编辑真实姓名" v-model="infoValue.name" class="nameExist"></el-input>
                                <img src="@/assets/icon_edit_name.png" @click="editRealName" alt="">
                            </template>
                        </div>
                    </div>
                </div>
                <div class="avatar-name-r" @click="openService">开通服务课</div>
            </div>
            <div class="base-info">
                <div class="base-info-item" v-for="(v,i) in baseInfoKey" :key="i">
                    <span>{{v}}</span>
                    <span>{{infoValue[getValueFromKey[i]]}}</span>
                </div>
                <div class="base-info-item">
                    <span>标签</span>
                    <span style="margin-right:20px" v-if="infoValue.tag.id">{{infoValue.tag.name}}</span>
                    <span v-else>{{infoValue.tag.name}}</span>
                    <el-popover :width="200">
                        <div v-if="true">
                            <div class="flex-div" style="justify-content: space-between">
                                <span>{{infoValue.tag.id ? '修改标签' : '贴标签'}}</span>
                                <span style="color: #2C8EFC;cursor:pointer;" @click="toCreateTagPage">新建标签</span>
                            </div>
                            <el-select v-model="tags.choosenValue" @change="completeTagChoice" placeholder="请选择"
                                       style="margin: 10px auto;">
                                <el-option v-for="item in tags.options" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                            <!--<div class="tag-btns flex-div" style="justify-content: space-around">-->
                            <!--    <span :style="tagBtnStyle">取消</span>-->
                            <!--    <span :style="tagBtnStyle" style="background-color: #2C8EFC;color: #fff;"-->
                            <!--          @click="confirmTag">确认</span>-->
                            <!--</div>-->
                        </div>
                        <template #reference>
                            <span style="color: #4C88FF;cursor: pointer">{{infoValue.tag.id ? '修改标签' : '贴标签'}}</span>
                        </template>
                    </el-popover>
                </div>
                <div class="base-info-item" style="display: flex">
                    <span>备注</span>
                    <input ref="input" class="input-text" v-if="showRemarkInput" placeholder="请填写备注" v-model="infoValue['remarks']" @blur="submitRemark" @keyup.enter="this.$refs.input.blur()">
                    <span style="cursor:pointer;margin-left: 3px" v-else>{{infoValue['remarks']}}</span>
                    <!-- <span style="margin-left:30px;color: #4C88FF;cursor: pointer" @click="submitRemark"
                          v-if="infoValue['remarks']">修改备注</span>
                    <span style="margin-left:10px;color: #4C88FF;cursor: pointer" @click="submitRemark"
                          v-else>添加备注</span> -->
                    <span v-if="editRemarkShow" style="margin-left:10px;color: #4C88FF;cursor: pointer" @click="addRemark(infoValue['remarks'])"
                          >编辑备注</span>
                </div>
            </div>
        </div>
        <div class="user-detail-part2">
            <div class="user-detail-part2-l">
                <small-title detail-title="用户资产" style="margin-bottom: 15px"></small-title>
                <div class="user-equity-item">
                    <span>优惠券</span>
                    <el-table :data="assets.coupon" style="width: 90%;" :header-cell-style="{color:'#818181'}"
                              border fit>
                        <el-table-column label="未使用" prop="not_used" align="center"></el-table-column>
                        <el-table-column label="已使用" prop="used" align="center"></el-table-column>
                        <el-table-column label="已过期" prop="expired" align="center"></el-table-column>
                    </el-table>
                </div>
                <div class="user-equity-item">
                    <span>礼品</span>
                    <el-table :data="assets.gift_card" style="width: 90%;" :header-cell-style="{color:'#818181'}" border
                              fit>
                        <el-table-column label="未使用" prop="not_used" align="center"></el-table-column>
                        <el-table-column label="已使用" prop="used" align="center"></el-table-column>
                        <el-table-column label="已过期" prop="expired" align="center"></el-table-column>
                    </el-table>
                </div>
                <div class="user-equity-item">
                    <span>爱心</span>
                    <el-table :data="assets.love" style="width: 90%;" :header-cell-style="{color:'#818181'}" border
                              fit>
                        <el-table-column label="总爱心" prop="total" align="center"></el-table-column>
                        <el-table-column label="可用爱心" prop="available" align="center"></el-table-column>
                        <el-table-column label="已用爱心" prop="used" align="center"></el-table-column>
                        <el-table-column label="过期爱心" prop="expired" align="center"></el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="user-detail-part2-r">
                <small-title detail-title="会员信息" style="margin-bottom: 30px"></small-title>
                <div class="vip-info">
                    <div>
                        <span>加入会员时间</span>
                        <span>{{member_info.join}}</span>
                    </div>
                    <div>
                        <span>会员过期时间</span>
                        <span>{{member_info.expire}}</span>
                    </div>
                    <div>
                        <span>累计购买会员金额</span>
                        <span style="color: #FF4444;">￥{{member_info.total_pay}}</span>
                    </div>
                </div>
                <small-title detail-title="交易统计" style="margin-bottom: 30px"></small-title>
                <el-table :data="order_statistics" style="width: 90%;" :header-cell-style="{color:'#666666'}" border
                          fit>
                    <el-table-column label="消费总额" prop="total" align="center"></el-table-column>
                    <el-table-column label="消费次数" prop="count" align="center"></el-table-column>
                    <el-table-column label="最近消费时间" prop="latest_time" align="center"></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="user-detail-part3">
            <title-underline-choice :choice-index="userDetailBottomChoiceIndex" :choices="userDetailBottomChoice"
                                    @getChoice="makeChoice"></title-underline-choice>
            <learning-data v-if="userDetailBottomChoiceIndex === 1"></learning-data>
            <my-table v-else :column-num="columnNum[userDetailBottomChoiceIndex]"
                      :table-titles="tableTitles[userDetailBottomChoiceIndex]"
                      :table-props="tableProps[userDetailBottomChoiceIndex]"
                      :isUserDetail="true"
                      :actions="true"
                      :table-data="tableData" style="margin-top: 25px;"></my-table>
        </div>
    </div>
</template>

<script>
    import {getUserDetailData, SetUserTag, SubmitRemark, EditParentsInfo} from "@/api";
    import {GetAllUserTagList, getPageInfo, processTags} from "@/utils";
    import {myChart, myTable, myMasks, smallTitle, titleUnderlineChoice} from '@/components'
    import LearningData from "@/views/usersManage/components/learningData";

    export default {
        name: "usersDetail",
        components: {LearningData, titleUnderlineChoice, myChart, myMasks, myTable, smallTitle,},
        data() {
            return {
                baseInfoKey: ['学号', '注册时间', '绑定时间', '最后登录时间', '电话', '来源课程', '客户等级', '服务课权限',
                    '性别', '生日', '学习喜好', '学历', '职业'],
                getValueFromKey: ['student_id', 'add_time', 'bind_time', 'last_login_time', 'phone', 'from_lesson', 'pyramid_level', 'service_lesson_names', 'gender', 'birthday', 'like', 'education', 'occupation',],
                infoValue: {tag: {}},
                // 用户资产   交易统计   会员信息
                assets: {},
                order_statistics: [],
                member_info: {},
                // 底部模块切换
                userDetailBottomChoice: ['购买记录', '学习数据', '学习进度', '用户评论'],
                userDetailBottomChoiceIndex: 0,
                typeObj: {
                    'LESSON': '课程',
                    'YEAR_MEMBER': '会员',
                    'MEMBER': '会员',
                    'LIVE_REWARD': '直播打赏',
                    'LESSON_SECTION_REWARD': '课程章节打赏',
                    'LIVE': '直播',
                    'GOODS': '商品'
                },
                // 表格
                columnNum: [4, 0, 5, 4],
                tableTitles: [
                    ['购买时间', '购买商品', '类型', '购买价格(元)'], [],
                    ['课 程', '课程类型', '学习时长', '进度', '最近学习时间'],
                    ['评论时间', '评论内容', '课程名称', '课程类型']
                ],
                tableProps: [
                    ['pay_time', 'goods_name', 'type', 'price'], [],
                    ['name', 'type', 'study_duration', 'progress', 'latest_study'],
                    ['add_time', 'msg', 'name', 'lesson_type']
                ],
                tableData: [],
                pageInfo: {},
                // 标签
                tags: {
                    options: [],
                    choosenValue: ''
                },
                // showTags: false,
                // tagBtnStyle: {
                //     padding: '6px 20px',
                //     borderRadius: '26px',
                //     border: '1px solid #B0B0B0',
                //     cursor: 'pointer'
                // },
                // 是否显示填写备注
                showRemarkInput: false,
                editRemarkShow: true,
                // 编辑真实姓名
                editNameFlag: false,
                // 记录修改前的信息，修改失败重新赋值
                tempSaveVarible: {},
                inputOldValue: ''
            }
        },
        mounted() {
            console.log(this.$route.params.user_id);
            let that = this;
            GetAllUserTagList(that).then((res) => {
                that.tags.options = processTags(res)
                that.tags = {...that.$route.params, ...that.tags}
            })
            that.getData(4)
            that.getData(0)
        },
        methods: {
            // 编辑真实姓名
            editRealName() {
                let that = this
                this.editNameFlag = true;
                that.$nextTick(() => {
                    that.$refs['realname'].input.focus()
                })
            },
            // 上传接口，更改姓名
            changeName() {
                let that = this
                that.editNameFlag = false
                EditParentsInfo({...that.$route.params, name: that.infoValue.name}).then((res) => {
                    // console.log(res.data)
                }).catch((e) => {
                    that.infoValue.name = that.tempSaveVarible['realname']
                    that.$message.info('修改失败')
                })
            },

            // 开通服务课
            openService() {
                this.$router.push({name: '权限申请', params: {student_id: this.infoValue['student_id']}})
            },

            // 贴标签
            // editLabel() {
            //     // let that = this
            //     // that.showTags = true
            // },
            // confirmTag() {
            completeTagChoice() {
                let that = this
                if (that.tags.choosenValue === '') {
                    that.infoValue['tag'] = {id: 0, name: ""}
                } else {
                    let i = 0;
                    let l = that.tags.options.length
                    for (; i < l; i++) {
                        if (that.tags.choosenValue === that.tags.options[i].value) {
                            i = that.tags.options[i].label
                            break
                        }
                    }
                    SetUserTag({tag_id: that.tags.choosenValue, ...that.$route.params}).then((res) => {
                        that.infoValue['tag'] = {name: i, id: that.tags.choosenValue}
                        that.$message.success(res.data)
                    }).catch((e) => {
                        that.$message.info(e.msg)
                    })
                }
            },
            addRemark(value) {
                this.editRemarkShow = false
                this.showRemarkInput = true
                this.inputOldValue = value
                this.$nextTick(function() {
                  this.$refs.input.focus()
                })
            },

            // 备注填写完成
            submitRemark() {
                this.editRemarkShow = true
                const that = this
                if (this.inputOldValue == this.infoValue['remark']) {
                    SubmitRemark({
                        id: that.$route.params.user_id,
                        msg: that.infoValue['remarks'],
                        type: 0
                    }).then((res) => {
                      console.log(res);
                        that.$message.success(res.data)
                    }).catch((e) => {
                      console.log(e.msg);
                        that.$message.info(e.msg)
                    })
                } else {
                    that.$message.success('没有做任何修改')
                }
                this.showRemarkInput = false
            },

            // 跳转到新建标签页面
            toCreateTagPage() {
                this.$router.push({name: 'createTag', params: {edit: 0}})
            },

            // 切换底部模块下标
            makeChoice(i) {
                let that = this;
                if (that.userDetailBottomChoiceIndex !== i) {
                    that.userDetailBottomChoiceIndex = i
                    if (i !== 1)
                        that.getData(i)
                }
            },

            // 4-用户信息；0-购买记录；1-学习数据；2-学习进度；3-用户评论
            getData(i) {
                let that = this;
                getUserDetailData(i, {...that.$route.params}).then((res) => {
                  console.log(res);
                    if (i === 4) {
                        // 用户基础详情
                        that.infoValue = res.data.info
                        that.editNameFlag = !that.infoValue.name
                        that.tempSaveVarible['realname'] = that.infoValue.name
                        that.infoValue['service_lesson_names'] = that.infoValue['service_lesson_names'].toString();
                        that.tags.choosenValue = that.infoValue['tag'].id ? that.infoValue['tag'].id : '';
                        that.showRemarkInput = !that.infoValue['remarks']
                        // 用户资产
                        that.assets['coupon'] = [res.data.assets.coupon]
                        that.assets['gift_card'] = [res.data.assets.gift_card]
                        that.assets['love'] = [res.data.assets.love]
                        // 交易统计
                        res.data.order_statistics.total = '￥' + res.data.order_statistics.total
                        that.order_statistics = [res.data.order_statistics]
                        // 会员信息
                        that.member_info = res.data.member_info
                    } else {
                        if (i === 0)
                            that.tableData = that.processPurchaseData(res.data.page_data)
                        else if (i === 2)
                            that.tableData = that.processProgressData(res.data.page_data)
                        else if (i === 3)
                            that.tableData = that.processCommentData(res.data.page_data)
                        else {
                            that.tableData = res.data.page_data
                        }
                        that.pageInfo = getPageInfo(res.data)
                    }
                }).catch((e) => {
                    that.$message.info(e.msg)
                })
            },

            // 处理购买记录数据
            processPurchaseData(data) {
                const that = this
                data.forEach((item) => {
                    item.type = that.typeObj[item.type]
                })
                return data
            },

            // 处理学习进度数据
            processProgressData(data) {
                data.forEach((item) => {
                    item['progress'] = item['progress'] + "%"
                    item['type'] = this.processType(item['type'])
                    item['study_duration'] = item['study_duration'] + '秒'
                })
                return data
            },

            // 处理用户评论数据
            processCommentData(data) {
                data.forEach((item) => {
                    item['lesson_type'] = this.processType(item['lesson_type'])
                })
                return data
            },

            // 处理课程类型
            processType(type) {
                return type === 'audio' ? '音频' : '视频'
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .user-detail-part1,
    .user-detail-part2 .user-detail-part2-l,
    .user-detail-part2 .user-detail-part2-r,
    .user-detail-part3 {
        .shadow-border-bgcolor();
        font-size: 18px;
    }

    /* 用户基础信息 */
    .user-detail-part1 {
        padding: 27px 100px 20px 40px;

        .avatar-name {
            display: flex;
            justify-content: space-between;

            .avatar-name-l {
                .flex-div();

                img {
                    .my-img-wh-mr-bradius(@w: 80px,@h: 80px,@mr: 36px,@b-radius: 50%)
                }

                div span {
                    line-height: 40px;
                    font-size: 18px;
                    color: #9B9B9B;
                }

                .real-name {
                    .flex-div();

                    img {
                        margin-left: 8px;
                        width: 20px;
                        height: 20px;
                    }

                    .my-el-input(@w: 160px,@h: 30px,@backgroundcolor: #fff);

                    .nameExist {
                        ::v-deep(.el-input__inner) {
                            border: none;
                            padding: 0;
                        }
                    }
                }
            }

            .avatar-name-r {
                width: 128px;
                height: 42px;
                line-height: 42px;
                background: #4C88FF;
                border-radius: 5px;
                text-align: center;
                font-size: 18px;
                color: #FFFFFF;
                cursor: pointer;
            }
        }

        .base-info {
            display: flex;
            flex-wrap: wrap;

            .base-info-item:nth-child(4n+1) {
                width: 260px;
            }

            .base-info-item:nth-child(4n+2) {
                width: 400px;
            }

            .base-info-item:nth-child(4n+3) {
                width: 400px;
            }

            .base-info-item:nth-child(4n+4) {
                width: 400px;
            }

            .base-info-item {
                margin: 17px 0;

                span:first-child {
                    margin-right: 17px;
                    color: #222222;
                }

                .my-el-input(@w: 160px,@h: 27px,@fontcolor: #BDBDBD,@backgroundcolor:#FAFAFA);

                ::v-deep(.el-input__inner) {
                    padding: 0 3px;
                    font-size: 18px;
                }
            }
        }
    }

    /* 用户资产   会员信息和交易统计 */
    .user-detail-part2 {
        margin: 20px auto;
        display: flex;
        justify-content: space-between;

        .user-detail-part2-l {
            width: 46%;
            padding: 25px 0 50px 25px;
            box-sizing: border-box;

            .user-equity-item {
                margin-left: 10px;
                display: flex;
                flex-direction: column;

                span:first-child {
                    margin: 14px 0;
                    color: #222222;
                }
            }

            ::v-deep(.el-table) {
                color: #818181;
                font-size: 18px;
            }
        }

        .user-detail-part2-r {
            width: 53%;
            padding: 25px 0 70px 35px;
            box-sizing: border-box;

            .vip-info {
                display: flex;
                flex-wrap: wrap;

                div {
                    margin: 0 60px 40px 0;

                    span:first-child {
                        margin-right: 30px;
                        color: #222222;
                    }
                }
            }

            ::v-deep(.el-table) {
                color: #666666;
                font-size: 18px;
            }
        }
    }

    .user-detail-part3 {
        padding: 25px 25px 70px;
    }

    .input-text {
        width: 160px;
        height: 30px;
        padding: 0 5px;
        line-height: 30px;
        font-size: 16px;
        background: #fff;
        border: 1px solid #ccc;
        color: #8f8f8f;
        outline: none;
        border-radius: 5px;
    }
</style>