<template>
    <task-system-template :homework-or-correct="0"></task-system-template>
</template>

<script>
    import TaskSystemTemplate from "./taskSystemTemplate.vue";

    export default {
        name: "parentsWork",
        components: {TaskSystemTemplate},
    }
</script>

<style scoped></style>