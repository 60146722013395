<template>
    <div class="contacts-wrap">
        <el-table
                :data="tableData"
                :span-method="objectSpanMethod"
                border
                style="width: 100%;">
            <el-table-column v-for="(item, i) in tableTitles" :prop="tableProps[i]" :label="item" fit></el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {GetContacts} from '@/api/index.js'

    export default {
        name: "contacts",
        data() {
            return {
                tableTitles: ['地区', '姓名', '联系方式'],
                tableProps: ['area', 'name', 'phone'],
                tableData: [],

                rowArr:[],  // 辅助数组记录从哪一行合并多少个
                numArr:[]
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                let that = this
                let count = 0
                GetContacts().then((res) => {
                    res.data.forEach((item, index) => {
                        that.rowArr.push(count)
                        that.numArr.push(item.list.length)
                        item.list.forEach((data, i) => {
                            count ++;
                            that.tableData.push({'area':item.area,...data})
                        })
                    })
                })
            },
            // 合并图表单元格
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    if (this.rowArr.indexOf(rowIndex) !== -1) {
                        return {
                            rowspan: this.numArr[this.rowArr.indexOf(rowIndex)],
                            colspan: 1
                        }
                    }else{
                        return [0,0]
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";
    .contacts-wrap{
        .shadow-border-bgcolor();
        padding: 20px 30px;
    }
</style>