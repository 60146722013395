<template>
    <div>
        <div class="mask" @click="closeMask"></div>
        <!-- 家长作业预览 -->
        <div class="element" v-if="pos === 0" :style="fullscreenFlag?'':styles">
            <div class="main-tit">{{homeworkData.top}}</div>
            <div style="max-height: 620px;overflow-y: scroll;padding: 20px;box-sizing: border-box;"
                 :style="{'maxHeight':fullscreenFlag?'':maxHeight}" class="scroll-none">
                <div class="homework-tit">{{homeworkData.title}}</div>
                <div v-html="homeworkData.content" style="padding: 0 10px;box-sizing: border-box"></div>
            </div>
            <div class="right-top-icon">
                <img src="../assets/icon_fullscreen.png" @click="processFullScreenOrNot" alt="" v-if="!fullscreenFlag">
                <img src="../assets/icon_smallscreen.png" @click="processFullScreenOrNot" alt="" v-else>
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
        </div>
        <!-- 海报生成 -->
        <div class="element element1" v-else-if="pos === 1" :style="styles">
            <div ref="imageWrapper" class="absolute-ele box-div">
                <img style="width: 100%;" id="poster-bg"
                 src="../assets/icon_poster_bg.png" alt>
              <div id="poster-div" class="absolute-ele poster-div" :style="{height:posterBgH}">
                  <img class="poster-avatar" :src="posterData.icon" alt>
                  <div>{{posterData.nickname}}</div>
                  <div class="flex contact-item" style="margin-top: 25px">
                      <img src="../assets/icon_phone.png" alt>
                      <span class="contact-label">手机号码</span>
                      <span>{{posterData.phone}}</span>
                  </div>
                  <div class="margin-t10 flex contact-item" style="margin-bottom: 25px">
                      <img src="../assets/icon_wx.png" alt>
                      <span class="contact-label">微信号</span>
                      <span>{{posterData.wechat}}</span>
                  </div>
                  <div>{{posterData.intro}}</div>
                  <div ref="qrcodeContainer" class="code-content1"></div>
              </div>
            </div>
            
            <div class="btns">
                <!-- 保存功能稍后推出哦~~~ -->
                <div class="btn-div" @click="closeMask">取消</div>
                <div class="btn-div" @click="savePoster">保存</div>
            </div>
            <div class="right-top-icon">
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
        </div>
        <!-- 二维码展示 -->
        <div class="element element2" v-else-if="pos === 2" :style="styles">
            <div class="main-tit">{{qrcodeInfo.tit}}</div>
            <div class="qrcode" ref="qrcodeContainer"></div>
            <span>手机扫码预览</span>
            <div class="right-top-icon">
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
        </div>
        <!-- 物流信息 -->
        <div class="element" v-else-if="pos === 3" :style="styles">
            <div class="main-tit">查看物流</div>
            <div class="flex-div from-to">
                <div class="flex-div from-to-element" v-for="i in 2" :key="i"
                     :style="{borderRight:i === 1 ? '1px solid #C8C8C8' : ''}">
                    <div class="flex-div">
                        <img src="../assets/icon_logistics.png">
                        <span>{{ i === 1 ? '发货' : '收货'}}地址</span>
                    </div>
                    <span class="pos-text">{{i === 1 ? logisticsInfo.startDes : logisticsInfo.endDes}}</span>
                </div>
            </div>
            <div class="logistics-detail" style="display: flex;">
                <div style="margin-right: 10px;font-size: 14px;color: #686868;display: flex;flex-direction: column;">
                    <span v-for="(item, index) in logisticsInfo.progress" :key="index" style="margin-bottom: 48px;">{{item.time}}</span>
                </div>

                <el-timeline style="margin-top: 3px;">
                    <el-timeline-item v-for="(item, index) in logisticsInfo.progress" :key="index">{{item.pos}}</el-timeline-item>
                </el-timeline>
            </div>
            <div class="btns"
                 style="position: absolute;bottom: 0;width:100%;height:90px;border-top:1px solid #E5E5E5;padding:0 20px;box-sizing:border-box;justify-content: space-around;">
                <div class="btn-div" @click="closeMask">取消</div>
                <div class="btn-div" @click="closeMask">确认</div>
            </div>
            <div class="right-top-icon">
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
        </div>
        <!-- 弹框备注 -->
        <div class="element" v-else-if="pos === 4" :style="styles">
            <div class="main-tit">备注</div>
            <div class="remark-div">
                <el-input type="textarea" v-model="remarkInput" placeholder="请填写你的备注信息......"></el-input>
            </div>
            <div class="btns" style="margin: 40px 90px 0 90px;">
                <div class="btn-div" @click="closeMask">取消</div>
                <div class="btn-div" @click="submitRemark">确认</div>
            </div>
            <div class="right-top-icon">
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
        </div>
        <!-- 导出表格数据，页码选择 -->
        <div class="element element6" v-else-if="pos === 5" :style="styles">
            <div class="main-tit">请选择您要导出的页码</div>
            <div class="page-range">
                <span style="margin-right: 10px">从</span>
                <el-input-number size="mini" v-model="pages.start" :min="1" :max="pages.max"></el-input-number>
                <span style="margin: 0 10px">至</span>
                <el-input-number size="mini" v-model="pages.end" :min="pages.start" :max="pages.max"></el-input-number>
            </div>
            <div class="flex-div cancel-confirm-div">
                <span @click="closeMask">取消</span>
                <span @click="confirmPage" style="background-color:#4B78EA;color: #ffffff">确定</span>
            </div>
        </div>
        <!-- 图片预览 -->
        <div class="element" v-else-if="pos === 6"
             :style="{width:previewImgItem.width+'px',height:previewImgItem.height+'px'}">
            <img class="pre-img" :src="previewImgItem.url" alt="">
        </div>
        <!-- 公告详情 可以考虑和图文教程一样使用 pos = 0  -->
        <div class="element" v-else-if="pos === 7" :style="styles">
            <div style="padding: 20px;box-sizing: border-box;">
                <div class="homework-tit" style="margin-bottom: 10px;">{{announceDetail.title}}</div>
                <div v-html="announceDetail.content" class="scroll-none"
                     style="max-height: 620px;overflow-y: scroll;padding: 0 10px;box-sizing: border-box"></div>
            </div>
            <div class="right-top-icon">
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
        </div>
        <!-- 用户列表 - 贴标签 -->
        <div class="element element9" v-else-if="pos === 8" :style="styles" style="text-align: center">
            <div class="right-top-icon">
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
            <div style="line-height: 50px">贴标签</div>
            <el-select v-model="tags.choosenValue" placeholder="请选择">
                <el-option v-for="item in tags.options" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
            </el-select>
            <div class="element9-confirm-btn" @click="confirmTag">确定</div>
        </div>
        <div class="element element10" v-else-if="pos === 9" :style="styles">
            <div class="right-top-icon">
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
            <div class="font18 fontbold" style="text-align: center">直播步骤</div>
            <div>
                <br>
                <div>第一步：下载讲博瑞智讲师app</div>
                <br>
                <div>1.安卓手机用户请扫描下方二维码下载</div>
                <br>
                <div class="qrcode" style="display: flex;justify-content: center" ref="qrcodeContainer"></div>
                <br>
                <div>2.苹果手机用户请在"apple store(苹果商店)"内下载"博瑞智讲师"app</div>
                <br>
                <div>第二步：申请/确定开通了所属讲师的讲课权限，若需申请，请先确定好讲师的电话和学号，再去找群内的宫老师申请开通</div>
                <br>
                <div>第三步：登录“博瑞智讲师”app，选择要直播的课程，开启直播即可（建议直播前10 分钟提前开启直播进行调试）</div>
            </div>
            <!--<div style="white-space: pre-wrap">{{liveTips}}</div>-->
        </div>
        <div class="element element11" v-else-if="pos===10" :style="styles">
            <div class="right-top-icon">
                <i class="el-icon-close" @click="closeMask"></i>
            </div>
            <div class="main-tit">选择操作</div>
            <div v-for="(item,i) in actions" :key="i" :class="actionIndex===i?'active-action-item':'action-item'"
                 @click="chooseAction(i)">{{item}}
            </div>
            <!--<div class="action-btn">-->
            <!--    <span>取消</span>-->
            <!--    <span>确定</span>-->
            <!--</div>-->
        </div>
    </div>
</template>

<script>
    import {SetUserTag} from "@/api";
    import {getLocalStorage, getQRCode} from "@/utils";
    import html2canvas from "html2canvas"

    export default {
        name: "myMasks",
        // pos: 0 - 家长作业预览  1 - 个人信息生成海报 2 - 二维码 3 - 物流信息 4 - 备注
        props: ['pos', 'posterData', 'homeworkData', 'qrcodeInfo', 'logisticsInfo', 'remarkInput', 'pages', 'previewImgItem', 'announceDetail', 'tags'],
        data() {
            return {
                fullscreenFlag: false,
                maxHeight: '620px',
                maxW: ['950px', '630px', '540px', '800px', '720px', '400px', '', '950px', '400px', '600px', '300px'],
                maxH: ['700px', '690px', '445px', '640px', '480px', '200px', '', '700px', '200px', '500px', '280px'],
                styles: {},
                posterBgH: 0,
                // 导出数据 - 选择页数
                exportPages: {start: 1, end: 1, max: 0},
                liveTips: `\n第一步：下载“博瑞智讲师”app\n\n第二步：申请/确定开通了所属讲师的讲课权限，若需申请，请先确定好讲师的电话和学号，再去找群内的宫老师申请开通\n\n第三步：登录“博瑞智讲师”app,选择所属直播课，进行直播调试\n\n若在操作中遇到问题，请联系宫老师电话：15319781771  服务时间：9:00-18:00`,
                actions: ['打开', '管理聊天室', '去直播', '修改', '删除'],
                // actionIndex:0
            }
        },
        mounted() {
            let that = this;
            that.styles = {
                'maxWidth': that.maxW[that.pos],
                'maxHeight': that.maxH[that.pos],
            }
            if (that.pos === 1) {
                document.getElementById('poster-bg').addEventListener('load', () => {
                    that.posterBgH = document.getElementById('poster-bg').clientHeight + 'px'
                    console.log(that.posterBgH)
                })
                getQRCode(that.$refs.qrcodeContainer, 80, 80, that.posterData.qr)
            } else if (that.pos === 2) {
                getQRCode(that.$refs.qrcodeContainer, 205, 200, that.qrcodeInfo.url)
            } else if (that.pos === 9) {
                getQRCode(that.$refs.qrcodeContainer, 160, 160, JSON.parse(getLocalStorage('someInfo')).link_teacher_app_download)
            }
            this.exportPages = this.pages
        },
        methods: {
            // 关闭弹框
            closeMask() {
                this.$emit('closeMask')
            },

            // 全屏小屏切换
            processFullScreenOrNot() {
                this.fullscreenFlag = !this.fullscreenFlag
            },

            // 保存海报
            savePoster() {
                // this.$message.info('保存功能正在研究中，请稍等哦~~')
                html2canvas(this.$refs.imageWrapper, {
                  useCORS: true
                }).then(canvas => {
                  const link = document.createElement('a')
                  link.href = canvas.toDataURL()
                  link.setAttribute('download', this.qrCodeTitle + '.png')
                  link.style.display = 'none'
                  document.body.appendChild(link)
                  link.click()
                });
            },

            // 提交remarkInput
            submitRemark() {
                this.$emit('makeRemark', this.remarkInput)
                this.closeMask();
            },

            // 确定导出数据
            confirmPage() {
                this.$emit('confirmExportPages', this.exportPages)
            },

            // 确定贴标签
            confirmTag() {
                let that = this
                if (that.tags.choosenValue === '') {
                    that.$emit('setTagSuccess', {id: 0, name: ""}, that.tags.user_id)
                } else {
                    let i = 0;
                    let l = that.tags.options.length;
                    for (; i < l; i++) {
                        if (that.tags.options[i].value === that.tags.choosenValue) {
                            i = that.tags.options[i].label
                            break
                        }
                    }
                    SetUserTag({user_id: that.tags.user_id, tag_id: that.tags.choosenValue}).then((res) => {
                        that.$message.info(res.data)
                        that.closeMask()
                        that.$emit('setTagSuccess', {id: that.tags.choosenValue, name: i}, that.tags.user_id)
                    })
                }
            },

            // 选中操作
            chooseAction(i) {
                this.$emit('chooseAction', i)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../styles/common";

    .mask {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background-color: #999999;
        opacity: 0.4;
    }

    .element {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1000;
        background: #FFFFFF;
        border-radius: 10px;
        text-align: left;
    }

    .element1 {
        padding: 40px 120px;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        justify-content: center;

        /* 推广海报   */

        .absolute-ele {
            position: absolute;
        }

        .fixed-ele {
            position: fixed;
        }

        .code-content1 {
            margin: 20px auto;
            width: 105px;
            height: 105px;
            padding: 8px;
            background-color: #fff;
            display: flex;
            justify-content: center;
        }

        .box-div {
          margin: 0 auto;
        }

        .poster-div {
            width: 100%;
            left: 0;
            top: 0;
        }

        .box-div,
        .poster-bg {
          width: 48%;
        }

        .poster-div {
          margin: 0;
        }

        .poster-avatar {
            margin-top: 30%;
            width: 73px;
            height: 73px;
            border-radius: 50%;
        }

        .contact-item {
            margin: 5px 20%;
            width: 60%;
            text-align: left;
        }

        .contact-item img {
            width: 20px;
            height: 20px;
        }

        .contact-item .contact-label {
            margin: 0 6px;
            width: 60px;
        }

        .btns {
            position: absolute;
            bottom: 4%;
            left: 50%;
            transform: translateX(-50%);
            /*width: 66%;*/
        }
    }

    .element2 {
        text-align: center;

        .qrcode {
            margin: 30px auto;
            width: 225px;
            height: 220px;
            padding: 10px;
            box-sizing: border-box;
        }

        span {
            margin-top: 40px;
            font-size: 20px;
            color: #4D78EB;
        }
    }

    .btns {
        margin-top: 50px;
        .flex-div();
        justify-content: space-between;

        .btn-div:first-child {
            background-color: #ffffff;
            color: #959595;
            border: 1px solid #C8C8C8;
            box-sizing: border-box;
        }
    }

    .element {
        .homework-tit {
            padding-left: 10px;
            font-size: 18px;
            color: #000000;
        }

        .from-to {
            padding: 10px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #C8C8C8;

            .from-to-element {
                width: 50%;
                padding-left: 40px;
                box-sizing: border-box;

                div {
                    margin-right: 35px;
                    flex-direction: column;

                    img {
                        .my-img-wh-mr-bradius(@w: 50px,@h: 50px)
                    }

                    span {
                        color: #4D78EB;
                    }
                }

                .pos-text {
                    max-width: 190px;
                    white-space: pre-wrap;
                    font-size: 14px;
                    color: #7B7B7B;
                }
            }
        }

        .logistics-detail {
            margin-top: 40px;
            margin-left: 45px;
            max-height: 350px;
            overflow-y: scroll;

            ::v-deep(.el-timeline-item) {
                padding-bottom: 40px;
            }

            ::v-deep(.el-timeline-item__content) {
                color: #464646;
            }
        }

        .remark-div {
            margin: 30px 35px;
            .my-el-textarea(@w: 650px,@h: 250px)
        }
    }

    .element6 {
        text-align: center;

        .page-range {
            margin: 26px auto;
        }

        .cancel-confirm-div {
            justify-content: space-evenly;

            span {
                padding: 4px 16px;
                border: 1px solid #4B78EA;
                border-radius: 25px;
                cursor: pointer;
            }
        }
    }

    .element9 {
        ::v-deep(.el-select) {
            margin-top: 20px;
        }

        .my-el-select()
    }

    .element9-confirm-btn {
        position: absolute;
        bottom: 20px;
        right: 20px;
        float: right;
        text-align: center;
        color: #fff;
        padding: 6px 20px;
        background-color: #2C8EFC;
        border-radius: 20px;
        cursor: pointer;
    }

    .element10 {
        padding: 20px;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    .element10::-webkit-scrollbar {
        width: 0;
    }

    .element11 {
        text-align: center;
    }

    .action-item {
        padding: 10px 0;
        cursor: pointer;
    }

    .active-action-item {
        padding: 10px 0;
        color: #F42F2F;
    }

    .action-btn {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .action-btn span {
        padding: 5px 20px;
        border: 1px solid #D2D2D2;
        border-radius: 20px;
    }

    .action-btn span:nth-child(2) {
        background-color: #2C8EFC;
        color: #fff;
    }

    .element11::-webkit-scrollbar {
        width: 0;
    }

    .main-tit {
        margin: 0 15px;
        text-align: center;
        height: 59px;
        line-height: 59px;

        border-bottom: 1px solid #E5E5E5;
        font-size: 22px;
        color: #000000;
    }

    .right-top-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        .flex-div();

        img {
            .my-img-wh-mr-bradius(@w: 15px,@h: 15px)
        }

        i {
            font-size: 22px;
            cursor: pointer
        }
    }

    .scroll-none::-webkit-scrollbar {
        width: 2px;
    }

    .scroll-none::-webkit-scrollbar-thumb {
        background-color: #B0B0B0;
    }
</style>