<template>
  <el-row :gutter="24">
    <el-col :span="24"><div class="grid-content bg-purple">
      <div class="notice-part">
            <div class="part-top flex-div">
                <big-title :img-src="noticeImg" title="公告"></big-title>
                <!-- <div class="more-div flex-div" @click="clickMore(noticeImg,'公告')"> -->
                <div class="more-div flex-div" @click="$router.push('/announcement')">
                    <span>更多</span>
                    <img src="../../assets/more_row.png" alt="">
                </div>
            </div>
            <ul>
                <li class="flex-div" style="line-height: 26px;" v-for="(item, i) in noticeList" :key="i" @click="clickItem(item.id)">
                    <div class="flex-div">
                        <span class="list-dot"></span>
                        <span style="color: #FF4444">{{item.type == 0 ? '【系统更新】' : item.type == 1 ? '【内部培训】' : ''}}</span>
                        <span>{{item.title}}</span>
                    </div>
                    <span style="color: #909090">{{item.add_time}}</span>
                </li>
            </ul>
        </div>
    </div></el-col>
    <!-- <el-col :span="6"><div class="grid-content bg-purple">
      <div class="message-part">
           <div class="part-top flex-div">
               <big-title :img-src="messageImg" title="消息提醒"></big-title>
               <div class="more-div flex-div" @click="clickMore(messageImg,'消息提醒')">
                   <span>更多</span>
                   <img src="../../assets/more_row.png" alt="">
               </div>
           </div>
           <ul>
               <li class="flex-div" style="line-height: 26px;" v-for="i in 3">
               <li style="line-height: 26px;" v-for="i in 3">
                   <span class="list-dot"></span>
                   <span style="color: #5A5A5A;">新绑定一位家长</span>
               </li>
           </ul>
        </div>
    </div></el-col> -->
  </el-row>

    <div class="module-wrap-top">
        
        
        <my-masks v-if="showAnnouceDetail" :pos="7" :announce-detail="announceDetail"
                  @closeMask="showAnnouceDetail=false"></my-masks>
    </div>
</template>

<script>
    import bigTitle from "./components/big-title.vue"
    import {GetAnnouncement, GetAnnouncementDetail} from "@/api";
    import MyMasks from "@/components/myMasks";

    export default {
        name: "topTwoPart",
        components: {
            MyMasks,
            bigTitle
        },
        data() {
            return {
                noticeList: [],
                noticeImg: require("@/assets/icon_notice.png"),
                messageImg: require("@/assets/icon_message.png"),
                announceDetail: {},
                showAnnouceDetail: false,
            }
        },
        mounted() {
            let that = this
            GetAnnouncement().then((res) => {
              console.log(res);
                that.noticeList = res.data.page_data.slice(0, 3)
            })
        },
        methods: {
            clickMore(img, tit) {
                this.$emit('clickMore', img, tit);
            },

            clickItem(id) {
                let that = this
                GetAnnouncementDetail({id: id + ''}).then((res) => {
                    that.announceDetail = res.data
                    that.showAnnouceDetail = true
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/variable";
    @import "/src/styles/common";
    @import "./style/overviewCommon";

    /*.module-wrap-top {*/
    /*    display: flex;*/
    /*    justify-content: space-between;*/
    /*}*/

    .notice-part,
    .message-part {
        .shadow-border-bgcolor();
        padding: 20px 35px 20px 20px;
        /*width: 53%;*/

        .part-top {
            margin-bottom: 16px;
            justify-content: space-between;
        }

        ul li {
            list-style: none;
            font-size: 14px;
            justify-content: space-between;
            cursor: pointer;

            div {
                width: 90%;

                span:last-child {
                    .text-over-hidden(@w: 80%);
                }
            }
        }
    }

    // .message-part {
        // width: 38%;

        /*ul li {*/
        /*    span:last-child {*/
        /*        width: auto;*/
        /*    }*/
        /*}*/
    // }
</style>