<template>
    <div class="permission">
        <title-underline-choice :choices="choices" :choice-index=choiceIndex
                                @getChoice="makeChoice"></title-underline-choice>
        <div>
            <div class="flex-div" style="margin-top: 45px;">
                <span class="item-name">学号</span>
                <el-input v-model="sId" placeholder="请填写学号"></el-input>
            </div>
            <div class="flex-div" style="margin-top: 42px;">
                <span class="item-name">服务课</span>
                <div v-for="(item,i) in serviceIDs" :key="i" class="flex-div service-id-item" @click="chooseOrNot(i)">
                    <span class="service-item" :style="{backgroundColor:item.choosen ?'#2C8EFC':''}">{{item.name}}</span>
                    <img src="../../assets/icon_choosen.png" v-if="item.choosen">
                    <span class="choose-place" v-else></span>
                </div>
            </div>
        </div>
        <div class="btn-div" @click="submitApply">提交申请</div>
    </div>
</template>

<script>
    import {OpenService, OpenServiceLog} from "@/api";
    import TitleUnderlineChoice from "@/components/titleUnderlineChoice.vue";
    import {getLocalStorage} from "@/utils";

    export default {
        name: "apply",
        components: {TitleUnderlineChoice},
        data() {
            return {
                choices: ['权限申请', '冲突申诉'],
                choiceIndex: 0,

                sId: '',
                serviceIDs: [],
            }
        },
        mounted() {
            if (this.$route.params.student_id)
                this.sId = this.$route.params.student_id
            this.getData()
        },
        methods: {
            makeChoice(i) {
                if (this.choiceIndex !== i) {
                    this.$router.push('/conflict')
                }
            },

            chooseOrNot(i) {
                this.serviceIDs[i].choosen = !this.serviceIDs[i].choosen;
            },
            submitApply() {
                let that = this
                if (that.sId === '') {
                    this.$message.info('请填写学号')
                    return
                }
                let temp = []
                that.serviceIDs.forEach((item) => {
                    if (item.choosen)
                        temp.push(item.id)
                })
                temp = temp.toString();
                OpenService({student_id: that.sId, id_list: temp}).then((res) => {
                    that.$message.success(res.data)
                    that.getData()
                    that.sId = ''
                }).catch((e)=>{
                    that.$message.info(e.msg)
                })
            },

            // 获取数据
            getData() {
                let that = this
                if (JSON.stringify(that.$store.state.services) === '{}') {
                    that.$store.commit('setSomeInfo', JSON.parse(getLocalStorage('someInfo')))
                }
                let temp = [];
                let temp1 = []
                Object.assign(temp1,that.$store.state.services)
                temp1.forEach((item,i)=>{
                    temp[i] = {id:item.value,name:item.label,choosen:false}
                })
                that.serviceIDs = temp
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./style/permissionCommon";

    .service-id-item {
        margin-right: 30px;
        height: 30px;
        border: 1px solid #B8B8B8;
        border-radius: 3px;

        .service-item {
            display: inline-block;
            padding: 3px 10px;
            box-sizing: border-box;
            background-color: #B8B8B8;
            color: #fff;
        }

        img {
            padding: 10px 12px;
            width: 20px;
            height: 20px;
        }

        .choose-place {
            width: 20px+12px*2;
            height: 20px+10px*2;
        }
    }

    .btn-div {
        margin-top: 130px;
        margin-left: 80px;
    }
</style>
