<template>
    <div class="flex-div num-refresh-export">
        <span>数量：{{totalNum}}个</span>
        <div class="refresh-btn" @click="refresh">刷新</div>
        <div class="export-btn" @click="exportData">导出</div>
    </div>
</template>

<script>
    export default {
        name: "numRefreshExport",
        props:['totalNum'],
        methods:{
            refresh(){
                this.$emit('refreshTable')
            },
            exportData(){
                this.$emit('exportTableData')
            }
        }
    }
</script>

<style lang="less" scoped>
    .num-refresh-export{
        margin-bottom: 26px;
        span{
            font-size: 16px;
            font-weight: 400;
            color: #545454;
        }
        div{
            width: 90px;
            height: 36px;
            line-height: 36px;
            border-radius: 18px;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
        }
        .refresh-btn{
            margin: 0 35px;
            text-align: center;
            background: #4B78EA;
            color: #FFFFFF;
        }
        .export-btn{
            text-align: center;
            background: #FFFFFF;
            border: 1px solid #4C88FF;
            color: #4C88FF;
        }
    }
</style>