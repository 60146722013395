import {GetPromotionChart, GetSalesChart, GetUsersChart, GetVipChart} from "@/api";

/**
 * 数据中心/地区数据 - 推广分析 - 折线数据处理
 */

export function processPromotionData(_that, params, loading) {
    return new Promise(function (resolve, reject) {
        GetPromotionChart({
          ...params,
          version: 'v2'
        }).then((res) => {
            console.log(res);
            let rdata = res.data.chart
            _that.statistics[0].num = rdata[0].user_count
            _that.statistics[1].num = rdata[0].brow_count
            _that.statistics[2].num = rdata[0].share_count
            _that.statistics[3].num = rdata[0].comment_count
            _that.statistics[4].num = res.data.total_brow_count
            _that.chartData = tempProcessPromotionData(rdata)
            if (loading) loading.close()
            resolve()
        }).catch((e) => {
            if (loading) loading.close()
            // _that.$message.info(e.msg)
            console.log(e);
            reject(e)
        })
    })
}

function tempProcessPromotionData(data) {
    let temp = {xAxisData: [], series: []}
    temp.series = [{
        name: '素材访客数',
        data: []
    }, {
        name: '素材浏览量',
        data: []
    }, {
        name: '分享素材数',
        data: []
    }, {
        name: '素材评论数',
        data: []
    }]
    data.forEach((item, index) => {
        temp.xAxisData[index] = item.date
        temp.series[0].data[index] = item.user_count
        temp.series[1].data[index] = item.brow_count
        temp.series[2].data[index] = item.share_count
        temp.series[3].data[index] = item.comment_count
    })
    return temp
}

/**
 * 数据中心/地区数据 - 销售分析 - 折线数据处理
 */

export function processSalesData(_that, params, loading) {
    return new Promise(function (resolve, reject) {
        GetSalesChart({
          ...params,
          version: 'v2'
        }).then((res) => {
          console.log(res);
            let rdata = res.data
            _that.statistics[0].num = rdata[0].pay_user
            _that.statistics[1].num = rdata[0].submit_user
            _that.statistics[2].num = rdata[0].order_count
            _that.statistics[3].num = rdata[0].amount
            _that.statistics[4].num = rdata[0].total_amount
            _that.chartData = tempProcessSalesData(rdata)
            if (loading) loading.close()
            resolve()
        }).catch((e) => {
            if (loading) loading.close()
            _that.$message.info(e.msg)
            reject()
        })
    })
}

function tempProcessSalesData(data) {
    let temp = {xAxisData: [], series: []}
    temp.series = [{
        name: '下单用户',
        data: []
    }, {
        name: '付费用户',
        data: []
    }, {
        name: '订单数',
        data: []
    }, {
        name: '销售额',
        data: []
    }]
    data.forEach((item, index) => {
        temp.xAxisData[index] = item.date
        temp.series[0].data[index] = item.submit_user
        temp.series[1].data[index] = item.pay_user
        temp.series[2].data[index] = item.order_count
        temp.series[3].data[index] = item.amount
    })
    return temp
}

/**
 * 数据中心/地区数据 - 用户分析 - 折线数据处理
 */

export function processUsersChartData(_that, params, loading) {
    return new Promise(function (resolve, reject) {
        GetUsersChart(params).then((res) => {
          console.log(res);
            let rdata = res.data
            _that.statistics[0].num = rdata.today._new
            _that.statistics[1].num = rdata.today.active
            _that.statistics[2].num = rdata.pay_user
            _that.statistics[3].num = rdata.total
            _that.chartData = tempProcessUsersChartData(rdata.chart)
            if (loading) loading.close()
            resolve()
        }).catch((e) => {
            if (loading) loading.close()
            _that.$message.info(e.msg)
            reject()
        })
    })
}

function tempProcessUsersChartData(data) {
    let temp = {xAxisData: [], series: []}
    temp.series = [{
        name: '新增用户',
        data: []
    }, {
        name: '活跃用户',
        data: []
    }]
    data.forEach((item, index) => {
        temp.xAxisData[index] = item.date
        temp.series[0].data[index] = item.data._new
        temp.series[1].data[index] = item.data.active
    })
    return temp
}

/**
 * 数据中心/地区数据 - 会员分析 - 折线数据处理
 */

export function processVipChartData(_that, params, loading) {
    GetVipChart(params).then((res) => {
        let rdata = res.data
        _that.statistics[0].num = rdata.today.sale
        _that.statistics[1].num = rdata.today._new
        _that.statistics[2].num = rdata.today.active
        _that.statistics[3].num = rdata.today.expire
        _that.statistics[4].num = rdata.total_member
        _that.chartData = tempProcessVipChartData(rdata.chart)
        loading.close()
    }).catch((e) => {
        // _that.$messageBox(e.msg,'',{
        //     type:'info',
        //     showCancelButton:false,
        //     showConfirmButton:false
        // })
        _that.$message.info(e.msg)
        loading.close()
    })
}

function tempProcessVipChartData(data) {
    let temp = {xAxisData: [], series: []}
    temp.series = [{
        name: '新增会员数',
        data: []
    }, {
        name: '活跃会员数',
        data: []
    }, {
        name: '过期会员数',
        data: []
    }, {
        name: '会员销售额',
        data: []
    }]
    data.forEach((item, index) => {
        temp.xAxisData[index] = item.date
        temp.series[0].data[index] = item.data._new
        temp.series[1].data[index] = item.data.active
        temp.series[2].data[index] = item.data.expire
        temp.series[3].data[index] = item.data.sale
    })
    return temp
}


/**
 * 判断选择的日期是否存在
 */
export function getConfirmDate(_that, date) {
    return date ? {
        start_time: date[0],
        end_time: date[1]
    } : _that.$store.state.recentMonthDate;
}