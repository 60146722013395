import router from "/src/router";
import QRcode from "qrcodejs2";
import {GetUserTagList} from "@/api";
import * as echart from "echarts";

export const setLocalStorage = (sName, value) => {
    localStorage.setItem(sName, value)
}
export const getLocalStorage = (sName) => {
    return localStorage.getItem(sName)
}
export const removeLocalStorage = (sName) => {
    localStorage.removeItem(sName)
}

export const getSidebarList = () => {
    let routes = router.options.routes;
    let l = [];
    routes.forEach((item) => {
        if (item.meta && !item.meta.hideInSidebar) {
            item.children = item.children.filter((sitem) => {
                if (sitem.meta && !sitem.meta.hideInSidebar) {
                    return sitem
                }
            });
            l.push(item)
        }
    })
    return l;
}

export const getBreadCrumb = (route, _that) => {
    let routeMatched = route.matched;
    let bl = [];
    let nameArr = []
    let len = routeMatched.length;
    console.log(routeMatched)
    for (let i = 0; i < len; i++) {
        if (routeMatched[i].name) {
            bl.push(routeMatched[i])
            nameArr[i] = routeMatched[i].name
        }
    }
    _that.$store.commit('setBreadCrumbNameArr', nameArr)
    _that.$store.commit('setBreadCrumbList', bl)
}

export function getLoading(_that, obj, text) {
    return _that.$loading({
        text: text ? text : '加载中，请稍等...',
        // target: document.querySelector(obj),
    })
}

export function getExportLoading(_that, text) {
    return _that.$loading({
        text: text,
    })
}


export function getQRCode(item, w, h, url) {
    new QRcode(item, {
        width: w,
        height: h,
        text: url,
        colorDark: '#000',
        colorLight: "#fff",
        correctLevel: QRcode.CorrectLevel.H
    })
}

export function getPageInfo(rdata) {
    return {total: rdata.total, current_page: rdata.current_page, last_page: rdata.last_page, per_page: rdata.per_page}
}


/**
 *  Excel 表格导出
 */
let {export_json_to_excel} = require('../excel/Export2Excel.js')

export function exportExcel(tableTitles, tableProps, tableData, tableName) {
    require.ensure([], () => {
        const data = formatJson(tableProps, tableData)
        export_json_to_excel(tableTitles, data, tableName)  // 标题， 数据， 文件名
    })
}

function formatJson(filterVal, jsonData) {
    return jsonData.map(v => filterVal.map(j => v[j]))
}

/**
 * 时间戳转换时间
 */
export function timestampToTime(timestamp) {
    let temp = new Date(timestamp * 1000)
    let y = temp.getFullYear()
    let m = temp.getMonth() + 1
    let d = temp.getDate()
    let h = temp.getHours()
    let mm = temp.getMinutes()
    let s = temp.getSeconds()
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm)
}

export function add0(num) {
    return num < 10 ? '0' + num : num
}

/**
 * 滚动到容器底部
 * @param containerId 容器id
 * @param check 是否检测滑动位置,用当用户手动滑动查看历史消息时禁止自动滑动到最小面
 * @param onCheckNotMatch 当滑动到历史消息时执行的回调
 */
export function scrollToBottom(containerId, check, onCheckNotMatch) {
    const useCheck = arguments[1] ? arguments[1] : false;
    const checkNotMatch = arguments[2] ? arguments[2] : function () {
        toast('有新消息')
    };
    let el;
    setTimeout(function () {
        el = document.getElementById(containerId);
        run();
    }, 500);
    
    function run() {
        const top = el.scrollTop;
        const h = el.scrollHeight;
        if (useCheck) {
            if (top + getViewPortHeight() + 300 >= h) {
                el.scrollTop = h + 10000;
            } else {
                if (typeof checkNotMatch === 'function') checkNotMatch();
            }
        } else {
            el.scrollTop = h + 10000;
        }
    }
}

/**
 * 获取浏览器窗口的可视区域的高度
 * @returns {number}
 */
function getViewPortHeight() {
    return screen.availHeight;
    //return document.documentElement.clientHeight || document.body.clientHeight;
}

/**
 * 获取 所有的用户标签
 */
export function GetAllUserTagList(_that) {
    let temp = []
    return new Promise(function (resolve, reject) {
        if (_that.$store.state.userTags.length !== 0) {
            return resolve(_that.$store.state.userTags)
        } else
            GetUserTagList().then((res) => {
                let totalPage = res.data.last_page
                temp = [...temp, ...res.data.page_data]
                if (totalPage === 1) {
                    _that.$store.commit('setUserTags', res.data.page_data)
                    resolve(temp)
                }
                for (let i = 2; i <= totalPage; i++) {
                    GetUserTagList(i).then((r) => {
                        temp = [...temp, ...r.data.page_data]
                        if (r.data.last_page === totalPage) {
                            _that.$store.commit('setUserTags', res.data.page_data)
                            resolve(temp)
                        }
                    })
                }
            })
    })
}

/**
 * 处理用户标签 - 下拉
 */
export function processTags(data) {
    let temp = []
    data.forEach((ele, i) => {
        temp[i] = {value: ele.id, label: ele.name}
    })
    return temp
}

let myChart;

/**
 * echarts 初始化
 */
export function initChart(_that, chartData) {
    // console.log(myChart)
    if (myChart) {
        myChart.dispose()
    }
    // console.log(myChart)
    // if(myChart){
    //     console.log("&&&&&&&")
    // }
    myChart = echart.init(document.getElementById('my-chart'));
    let options = {};
    if (chartData && chartData.length !== 0) {
        let series = []
        chartData.series.forEach((item, index) => {
            series[index] = {
                name: item.name,
                type: 'line',
                symbolSize: 13,   // 折线图数据点的大小
                lineStyle: {
                    width: '4',
                    // color: item.color
                },
                data: item.data
            }
        })
        options = {
            grid: {
                right: '5%',
                left: '5%'
            },
            legend: {
                show: true,
                right: '5%',
                icon: 'roundRect',
                itemWidth: 21,
                itemHeight: 21,
                itemGap: 50,
                textStyle: {
                    fontSize: 18,
                    color: '#8A8F99',
                    padding: [0, 0, 0, 8]
                }
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    let str = ``
                    if (chartData.series[0].name)
                        params.forEach((item, i) => {
                            str += chartData.series[i].name + ":" + item.value + "<br>"
                        })
                    else
                        str = params[0].value + ''
                    return str
                }
            },
            xAxis: {
                name: '(日期)',
                nameTextStyle: {
                    color: '#999999',
                    fontSize: 13,
                    padding: [10, 0, 0, 0]
                },
                data: chartData.xAxisData,
                boundaryGap: 0,    // 标签在分割线正下方
                axisLine: {
                    lineStyle: {
                        color: '#DCDCDC'
                    },
                },
                axisLabel: {
                    fontSize: 15,
                    color: '#999999'
                },
                splitLine: {
                    lineStyle: {
                        // dashOffset:9
                    }
                }
            },
            yAxis: {
                name: '(数量)',
                // interval:1,  // 当前是自动调整间隔分段
                minInterval: 1,
                nameTextStyle: {
                    color: '#999999',
                    fontSize: 13
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#DCDCDC'
                    },
                    onZero: 0
                },
                splitLine: {
                    lineStyle: {
                        type: [20, 24],  //'dashed'
                        color: '#DFDFDF',
                    }
                },
                axisLabel: {
                    fontSize: 13,
                    color: '#999999'
                }
            },
            series: series
        };
    }
    _that.$nextTick(() => {
        myChart.resize()
    })
    myChart.setOption(options, true);
    window.addEventListener("resize", function () {
        myChart.resize()
    })
}

/**
 * 获取上个月
 */
export function getPreMonth(date) {
    const arr = date.split('-');
    const year = arr[0]; //获取当前日期的年份
    const month = arr[1]; //获取当前日期的月份
    const day = arr[2]; //获取当前日期的日
    let days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    let year2 = year;
    let month2 = parseInt(month) - 1;
    if (month2 === 0) {
        year2 = parseInt(year2) - 1;
        month2 = 12;
    }
    let day2 = day;
    let days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    return year2 + '-' + month2 + '-' + day2;
}
