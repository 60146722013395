<template>
    <div class="overview-wrap" v-if="!showMore">
        <top-two-part @clickMore="getClickMore"></top-two-part>
        <data-overview></data-overview>
        <lastest-resource @clickMore="getClickMore"></lastest-resource>
        <sent-resource></sent-resource>
    </div>
    <router-view v-else></router-view>
    <!--<more v-else :img-src="img" :title="tit"></more>-->
</template>

<script>
    import dataOverview from "@/views/overview/dataOverview.vue";
    import lastestResource from "@/views/overview/lastestResource.vue";
    import more from "@/views/overview/more.vue";
    import sentResource from "@/views/overview/sentResource.vue";
    import topTwoPart from './topTwoPart.vue'
    import {getLoading} from "@/utils";

    export default {
        name: "overview",
        components: {
            topTwoPart,
            dataOverview,
            lastestResource,
            sentResource,
            more
        },
        data() {
            return {}
        },
        computed: {
            showMore() {
                return this.$route.matched.length === 3
            }
        },
        mounted() {
            let loading = getLoading(this, '.overview-wrap')
            setTimeout(() => {
                loading.close()
            }, 300)
        },
        methods: {
            getClickMore(img, tit) {
                this.$router.push({name: 'more', params: {imgSrc: img, title: tit}})
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/variable";
    @import "../../styles/common";

    .module-wrap {
        margin: 20px 0;
        padding: 20px;
        .shadow-border-bgcolor();
    }
</style>