<template>
    <div class="order-wrap">
        <title-underline-choice :choices="choices" :choice-index=choiceIndex
                                @getChoice="makeChoice"></title-underline-choice>
        <div class="filter-part">
            <div class="flex-div" style="flex-wrap: wrap">
                <div class="flex-div filter-item" style="margin-right: 0">
                    <span>关键字</span>
                    <el-input v-model="filterValue.keyword" placeholder="请输入昵称、学号、手机号"></el-input>
                </div>
                <div class="flex-div filter-item">
                    <span>下单时间</span>
                    <el-date-picker
                            v-model="payTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" :unlink-panels="true" value-format="YYYY-MM-DD">
                    </el-date-picker>
                </div>
                <div class="flex-div filter-item">
                    <span>订单金额</span>
                    <div class="flex-item-money-range flex-div">
                        <el-input v-model="filterValue.price_start"></el-input>
                        <span style="margin:0 5px;">至</span>
                        <el-input v-model="filterValue.price_end"></el-input>
                    </div>
                </div>
                <div class="flex-div filter-item">
                    <span>渠道来源</span>
                    <el-select v-model="filterValue.from_client" placeholder="请选择">
                        <el-option v-for="(item, i) in clientOptions" :key="i" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <!--<div class="flex-div filter-item">-->
                <!--    <span>订单状态</span>-->
                <!--    <el-select></el-select>-->
                <!--</div>-->
            </div>
            <div class='filter-or-not'>
                <div class="filter-btn" @click="getData()">
                    <img src="@//assets/icon_filter.png" alt>
                    <span>筛选</span>
                </div>
                <span class="clear-btn" @click="clearFilter">清空所有筛选项</span>
            </div>
        </div>
        <num-refresh-export :total-num="pageInfo.total" @refreshTable="clickRefresh"
                            @exportTableData="clickExport" style="margin-top: 30px;"></num-refresh-export>
        <my-table :column-num=columnNum :table-titles=tableTitles :table-props=tableProps :table-data=tableData
                  :actions="actions" :page-info="pageInfo" @makeActionChoice="getActionChoice"
                  @pageIndexChange="changePageIndex"></my-table>
        <my-masks v-if="showRemarkMask" :pos="4" :remark-input="operateItem.remark" @closeMask="showRemarkMask=false"
                  @makeRemark="submitRemark"></my-masks>
        <my-masks v-if="showLogistics" :pos="3" :logistics-info="logisticsInfo"
                  @closeMask="showLogistics=false"></my-masks>
        <my-masks v-if="showExportPage" :pos="5" :pages="pages" @closeMask="showExportPage=false"
                  @confirmExportPages="confirmExportPages"></my-masks>
    </div>
</template>

<script>
    import {GetOrdersList, SubmitRemark} from "@/api";
    import {myMasks, myTable, numRefreshExport, titleUnderlineChoice} from '@/components'
    import {exportExcel, getExportLoading, getLoading, getPageInfo} from "@/utils";

    let clientObj = {'h5': '公众号', 'app': 'APP', 'mini': '小程序'}

    export default {
        name: "commodityPage",
        components: {
            myMasks,
            myTable,
            titleUnderlineChoice,
            numRefreshExport
        },
        props: ['choiceIndex'],
        data() {
            return {
                choices: ['知识商品', '实物商品', '报名信息'],
                // 筛选选项 和 筛选值
                clientOptions: [{value: 'h5', label: '微信公众号'}, {value: 'app', label: 'app'}, {
                    value: 'mini',
                    label: '微信小程序'
                }],
                filterValue: {
                    keyword: '',
                    is_physical: 0,
                    price_start: '',
                    price_end: '',
                    from_client: '',
                },
                payTime: '',
                // 表格信息
                columnNum: 12,
                tableTitles: ['订单编号', '昵称', '电话', '学号', '商品名称', '来源渠道', '订单金额', '订单状态', '下单时间', '结算状态', '备注'],
                tableProps: ['code', 'nickname', 'phone', 'student_id', 'goods_name', 'client', 'price', 'order_status', 'pay_time', 'is_settle', 'remark'],
                tableData: [],
                actions: ['备注'],
                pageInfo: {},
                // 备注 或者 物流 当前正在操作的资源
                operateItem: {},
                // 是否显示备注弹框
                showRemarkMask: false,
                // 物流信息相关
                showLogistics: false,
                logisticsInfo: {
                    startDes: '西安丈八北路唐延路北段144号北沈新世界4号楼1403室',
                    endDes: '西安丈八北路唐延路北段144号北沈新世界4号楼1403室',
                    progress: [{time: '11-28 07:33 ', pos: '[汕头市]在广东揭阳分拨中心进行装车扫描，发往:上海分拨中心'}, {
                        time: '11-28 07:33 ',
                        pos: '[汕头市]在分拨中心广东揭阳分拨中心进行称重扫描'
                    }, {time: '11-27 11:33 ', pos: '[揭阳市]已离开广东普宁南公司学院分部;发往上海分拨中心'}, {
                        time: '11-27  09:33 ',
                        pos: '[揭阳市]广东普宁南公司学院分部已揽收'
                    }, {time: '11-27 07:33 ', pos: '您的订单由第三方卖家拣货完成，待出库'},]
                },
                // 刷新
                refreshFlag: false,
                // 导出
                showExportPage: false,
                exportData: [],      // 导出数据集合
                exportLoading: {},   // 导出loading
                pages: {start: 1, end: 1, max: 0},
            }
        },
        mounted() {
            let that = this;
            if (that.choiceIndex === 1) {
                that.actions = ['备注', '查看物流']
            }
            that.initData()
            that.getData()
        },
        methods: {
            // 筛选数据
            getFilterValue() {
                let that = this
                let temp = {}
                let keys = Object.keys(that.filterValue)
                keys.forEach((key) => {
                    if (that.filterValue[key] !== '')
                        temp[key] = that.filterValue[key]
                })
                temp['is_physical'] = that.choiceIndex
                if (that.payTime !== '') {
                    temp['pay_time_start'] = that.payTime[0]
                    temp['pay_time_end'] = that.payTime[1]
                }
                return temp
            },
            // 从接口获取数据
            getData(curPage) {
                let that = this;
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                temp = {...temp, ...that.getFilterValue()}
                let loading = getLoading(that, '.order-wrap')
                GetOrdersList(that.choiceIndex, {...temp}).then((res) => {
                  // console.log(res.data.page_data);
                    if (res.data.page_data && res.data.page_data .length) {
                        that.processData(res.data.page_data)
                        that.pageInfo = getPageInfo(res.data)
                        if (that.refreshFlag) {
                            that.$message.success('刷新完成!')
                            // that.$notify
                            that.refreshFlag = false;
                        }
                    }
                    loading.close()
                })
            },
            // 将从接口获取的数据做一些基本处理
            processData(data) {
                data.forEach((item) => {
                    item['is_settle'] = item['is_settle'] ? '已结算' : '未结算'
                    item['order_status'] = '已支付'
                    item["client"] = item["client"] !== "" ? clientObj[item["client"]] : ""
                })
                this.tableData = data
            },

            // 切换表格页码
            changePageIndex(curPage) {
                this.getData(curPage)
            },

            // 切换顶部选项
            makeChoice(i) {
                if (this.choiceIndex !== i) {
                    this.$router.push(i === 0 ? '/knowledgeCommodity' : i === 1 ? '/physicalCommodity' : '/registration')
                }
            },

            // 刷新
            clickRefresh() {
                this.refreshFlag = true;
                this.initData()
                this.getData()
            },

            // 导出
            clickExport() {
                if (this.tableData.length !== 0) {
                    this.pages = {start: 1, end: 1, max: this.pageInfo.last_page}  // start <= end
                    this.showExportPage = true
                } else {
                    this.$message.info('还没有数据哦')
                }
            },
            confirmExportPages(p) {
                let that = this
                that.showExportPage = false;
                that.exportData = []
                that.loadingData(false, p.start, p.start, p.end, 1, p.end - p.start + 1, that.getFilterValue())
            },
            // 某页导出失败标识,页码，最小值，最大值，当前正在导出第几页，总共多少页，请求参数，
            loadingData(failFlag, i, min, max, cur, total, params) {
                let that = this
                if (failFlag) {
                    that.exportLoading.close()
                    that.$message.error('导出失败！')
                    return
                }
                if (i !== min)
                    that.exportLoading.setText(`导出页码：${min}-${max}，当前进度：${cur}/${total}`)
                else
                    that.exportLoading = getExportLoading(that, `导出页码：${min}-${max}，当前进度：${cur}/${total}`)
                GetOrdersList(that.choiceIndex, {'page': i, ...that.getFilterValue()}).then((response) => {
                    response.data.page_data.forEach((item) => {
                        item['is_settle'] = item['is_settle'] ? '已结算' : '未结算'
                        item['order_status'] = '已支付'
                        that.exportData.push(item)
                    })
                    if (i === max) {
                        exportExcel(that.tableTitles, that.tableProps, that.exportData, that.choiceIndex === 0 ? '知识商品数据' : '实物商品数据')
                        that.exportLoading.setText(`加载完成`)
                        that.exportLoading.close()
                    } else {
                        (function () {
                            setTimeout(function () {
                                that.loadingData(false, i + 1, min, max, cur + 1, total, params)
                            }, 200)
                        })()
                    }
                }).catch(() => {
                    that.loadingData(true, i + 1, min, max, cur + 1, total, params)
                })
            },

            // 表格操作:备注 / 查看物流
            getActionChoice(item, ai) {
                this.operateItem = item
                // console.log(this.operateItem)
                if (ai === 0)
                    this.showRemarkMask = true
                else {
                    // this.showLogistics = true
                    this.$message.info('暂无物流信息')
                }
            },

            // 提交备注
            submitRemark(remark) {
                let that = this
                SubmitRemark({id: that.operateItem.id, msg: remark, type: 1}).then((res) => {
                    that.$message.success(res.data)
                    let l = that.tableData.length
                    for (let i = 0; i < l; i++) {
                        if (that.operateItem.id === that.tableData[i].id) {
                            that.tableData[i].remark = remark
                            break
                        }
                    }
                }).catch((e) => {
                    that.$message.info(e.msg)
                })
            },

            // 初始化参数
            initData() {
                this.filterValue = {
                    keyword: '',
                    is_physical: '',
                    price_start: '',
                    price_end: '',
                    from_client: '',
                }
                this.payTime = ''
            },

            clearFilter() {
                this.initData()
                this.getData()
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../style/ordersManageCommon";
</style>