<template>
    <div>
        <analysisi-chart title="用户概况及趋势" :statistics="statistics" :chart-data="chartData"
                         @confirmDate="confirmDate"></analysisi-chart>
        <div class="analysis-table-wrap">
            <small-title detail-title="用户统计" style="margin-bottom: 38px;"></small-title>
            <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}" fit
                      highlight-current-row empty-text="暂无数据">
                <el-table-column v-for="i in 4" :key="i" :label="titles[i-1]" :prop="propName[i-1]"
                                 align="center">
                    <template #default="scope">
                        <span v-if="scope.row[propName[i-1]] !== '自定义时间'">{{scope.row[propName[i-1]]}}</span>
                        <div class="flex-div customed-date" v-else>
                            <span style="margin-right: 20px;">{{scope.row[propName[i-1]]}}</span>
                            <el-date-picker v-model="pickDate"
                                            type="daterange"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期" :unlink-panels="true" value-format="YYYY-MM-DD"
                                            @change="dateChange">
                            </el-date-picker>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import {myTable, smallTitle, analysisiChart} from "@/components";
    import {getLoading} from "@/utils";
    import {GetDataCenterUsersTable} from "@/api";
    import {processUsersChartData, getConfirmDate} from "@/utils/dataProcess";

    export default {
        name: "dataCenterUserAnalysis",
        components: {myTable, smallTitle, analysisiChart},
        data() {
            return {
                statistics: [
                  {typename: '新增用户', num: 0},
                  {typename: '活跃用户', num: 0},
                  {typename: '累计付费用户', num: 0},
                  {typename: '累计用户', num: 0}
                ],
                chartData: [],

                titles: ['时间', 'B级用户', 'C级用户', '合计'],
                propName: ['name', 'b', 'c', 'total'],
                tableData: [],
                pickDate: ''
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            // 获取数据
            getData() {
                let that = this
                let loading = getLoading(that, '.analysis-wrap')
                Promise.all([processUsersChartData(that), that.getTableData(that.$store.state.recentMonthDate)]).then((res) => {
                    loading.close()
                }).catch((e) => {
                    loading.close()
                })
            },

            // 获取表格数据
            getTableData(date, loading) {
                let that = this
                return new Promise(function (resolve, reject) {
                    GetDataCenterUsersTable(date).then((res) => {
                        that.tableData = res.data
                        if (loading) loading.close()
                        resolve()
                    }).catch((e) => {
                        that.$message.info(e.msg)
                        if (loading) loading.close()
                        reject()
                    })
                })
            },

            // 表格自定义时间
            dateChange(date) {
                let loading = getLoading(this, '.analysis-table-wrap')
                this.getTableData(getConfirmDate(this, date), loading)
            },

            // 时间选择
            confirmDate(date) {
                let loading = getLoading(this, '.analysis-chart-wrap')
                processUsersChartData(this, getConfirmDate(this, date), loading)
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .customed-date {
        .my-el-datepicker(@w: 280px)
    }
</style>
