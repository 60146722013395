<template>
    <div class="personal-info-wrap">
        <div class="row-div">
            <div class="flex-div">
                <img src="@/assets/personal-smile.png" alt="">
                <span>温馨提示：你填写的个人信息将展示给所绑定的家长，便于家长联系你，请谨慎填写！</span>
            </div>
            <div @click="createPoster">
                <img src="@/assets/personal-haibao-img.png" alt="">
                <span style="font-size: 16px;font-family: BoldFont;cursor: pointer;">生成个人海报</span>
            </div>
        </div>
        <div class="row-div underline">
            <span>头像</span>
            <img :src="userinfo.icon" alt="">
            <!--<el-upload class="upload" :file-list="fileList" list-type="picture" :on-change="handleChange" :limit="1"-->
            <!--           :action="$store.state.uploadUrl">-->
            <!--    更换头像-->
            <!--</el-upload>-->
        </div>
        <div class="row-div underline input-no-border">
            <span style="margin-right:230px;">姓名</span>
            <!--<el-input :placeholder="userinfo.name" disabled></el-input>-->
            <span>{{userinfo.name}}</span>
            <!--<el-input v-model="userinfo.name" ref="name" @blur="changeInput('name')"-->
            <!--          v-if="!disableVarible['name']"></el-input>-->
            <!--<template v-else>-->
            <!--    <el-input v-model="userinfo.name" @blur="changeInput('name')" class="varibleExist"></el-input>-->
            <!--    <img :style="{marginLeft: disableVarible['name'] ? '24px' : '40px'}" src="@/assets/personal-edit.png"-->
            <!--         @click="edit('name')" alt="">-->
            <!--</template>-->
        </div>
        <div class="row-div underline input-no-border">
            <span style="margin-right:230px;">手机号码</span>
            <el-input v-model="userinfo.phone" ref="phone" @blur="changeInput('phone')"
                      v-if="!disableVarible['phone']"></el-input>
            <template v-else>
                <el-input v-model="userinfo.phone" @blur="changeInput('phone')" class="varibleExist"></el-input>
                <img :style="{marginLeft: disableVarible['phone'] ? '24px' : '40px'}" src="@/assets/personal-edit.png"
                     @click="edit('phone')" alt="">
            </template>
        </div>
        <div class="row-div underline input-no-border">
            <span :style="{marginRight:disableVarible['wx'] ? '230px' : '214px'}">微信号</span>
            <el-input v-model="userinfo.wechat" ref="wx" @blur="changeInput('wx')"
                      v-if="!disableVarible['wx']"></el-input>
            <template v-else>
                <el-input v-model="userinfo.wechat" @blur="changeInput('wx')" class="varibleExist"></el-input>
                <img :style="{marginLeft: disableVarible['wx'] ? '24px' : '40px'}" src="@/assets/personal-edit.png"
                     @click="edit('wx')" alt="">
            </template>
        </div>
        <div class="row-div input-div">
            <span style="margin-right: 50px;">自我介绍</span>
            <el-input type="textarea" placeholder="请填写你的擅长领域、服务家庭数等......" v-model="userinfo.intro"></el-input>
        </div>
        <div class="row-div">
            <span style="margin-right: 120px;">我的推广码</span>
            <div class="qrcode-img">
                <div ref="qrcodeContainer"></div>
            </div>
        </div>
        <div class="btn-div" @click="submit" style="font-size: 18px;">保存</div>
    </div>
    <my-masks v-if="showPoster" :pos=1 :poster-data="posterData" @closeMask="showPoster=false"></my-masks>

</template>

<script>
    import {EditUserinfo, GetUserinfo, GetPosterData} from "@/api";
    import {myMasks} from "@/components";
    import {getQRCode} from "@/utils";

    export default {
        name: "personalInfo",
        components: {myMasks},
        data() {
            return {
                showPoster: false,
                posterData: {},
                // fileList:[{name:'',url:''}],  // 文件显示在其它位置
                fileList: [],
                userinfo: {},
                // 手机号微信号 编辑
                disableVarible: {
                    name: true,
                    phone: true,
                    wx: true
                }
            }
        },
        mounted() {
            let that = this;
            GetUserinfo().then((response) => {
              console.log(response);
                that.userinfo = response.data
                // that.disableVarible['name'] = !!that.userinfo['name']
                // that.disableVarible['phone'] = !!that.userinfo['phone']
                // that.disableVarible['wx'] = !!that.userinfo['wechat']
                that.getQrcode(that.userinfo.spread_qr);
            })
        },
        methods: {
            createPoster() {
                const that = this
                GetPosterData().then((res) => {
                    that.posterData = res.data
                    that.posterData.nickname = that.posterData.nickname.length > 5 ? that.posterData.nickname.substring(0, 5) + '...' : that.posterData.nickname
                    // new QRCode(document.getElementById('poster-code'), {
                    //     text: that.posterData.qr,
                    //     width: 80,
                    //     height: 80,
                    //     colorDark: '#000',
                    //     colorLight: '#FFF',
                    //     correctLevel: QRCode.CorrectLevel.H
                    // })
                    // todo
                    // html2canvas(document.querySelector("#poster-container")).then(function (canvas) {
                    //     document.body.appendChild(canvas)
                    // })
                    that.showPoster = true

                }).catch((e) => {
                    that.$message.error(e.msg)
                })
            },

            // handleChange(e) {
            //     if (e.status === 'success') {
            //         // 传值给接口修改
            //         this.userinfo.icon = e.response.data
            //     }
            // },
            // 编辑姓名或手机号或微信号
            edit(str) {
                let that = this
                that.disableVarible[str] = false;
                that.$nextTick(() => {
                    that.$refs[str].input.focus()
                })
            },
            changeInput(str) {
                this.disableVarible[str] = true;
            },
            // 生成二维码
            getQrcode(url) {
                getQRCode(this.$refs.qrcodeContainer, 120, 120, url)
            },
            // 提交修改的数据
            submit() {
                let that = this
                EditUserinfo(that.userinfo).then((response) => {
                    that.$message.success(response.data)
                }).catch((e) => {
                    that.$message.info(e.msg)
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/common";

    .personal-info-wrap {
        .shadow-border-bgcolor();
        padding: 30px 0 50px 140px;

        font-size: 14px;
        color: #545454;
    }

    .row-div {
        .flex-div();

        span:first-child {
            margin-right: 230px;
            width: 100px;
        }
    }

    /* 顶部提示 */
    .row-div:nth-child(1) {
        div {
            padding: 12px;
            border-radius: 5px;

            img {
                .my-img-wh-mr-bradius();
            }
        }

        div:nth-child(1) {
            margin-right: 40px;
            padding: 8px 200px 8px 8px;
            background: #FFF4E8;
            border: 1px solid #FDE0BF;
            color: #8A8F99;
        }

        div:nth-child(2) {
            padding: 15px;
            background: #E7EEFE;
            color: #406AD5;
        }
    }

    .underline {
        width: 860px;
        padding: 26px 0;
        border-bottom: 2px solid #F1F1F1;
    }

    .input-no-border {
        .my-el-input(@w: 160px,@fontsize:14px,@fontcolor:#545454,@backgroundcolor: #fff);

        img {
            .my-img-wh-mr-bradius(@w: 15px,@h: 15px);
        }

        .varibleExist {
            ::v-deep(.el-input__inner) {
                border: none;
                padding: 0;
            }
        }
    }

    /*头像*/
    .row-div:nth-child(2) {
        img {
            .my-img-wh-mr-bradius(@w: 73px,@h: 73px,@mr: 110px,@b-radius: 50%);
        }

        ::v-deep(.el-upload) {
            padding: 12px 24px;
            background: #E7EEFE;
            border-radius: 25px;
            color: #406AD5;
        }
    }

    /* 自我介绍 */
    .input-div {
        padding: 15px 0;
        .my-el-textarea(@w: 700px,@h: 190px,@fontsize: 14px, @fontcolor: #9F9F9F,@backgroundcolor: #F6F6F6);
    }

    /* 推广码 */
    .qrcode-img {
        margin-top: 38px;
        width: 140px;
        height: 140px;
        border: 1px solid #BCB9B9;
        padding: 10px;
        box-sizing: border-box;
    }

    .btn-div {
        margin: 80px 0 0 210px;
    }
</style>