<template>
    <div class="module-wrap">
        <big-title :img-src="imgSrc" :title="title"></big-title>
        <ul style="margin-top: 30px;">
            <li class="flex-div" v-for="(item, i) in dataList" :key="i">
                <div class="flex-div">
                    <span class="list-dot"></span>
                    <!--<span style="color: #FF4444">【系统更新】</span>-->
                    <span :style="{cursor: title==='公告' ?'pointer':''}"
                          @click="clickItem(item.id)">{{item.title}}</span>
                </div>
                <span style="color: #909090">{{item.add_time}}</span>
            </li>
        </ul>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
        <my-masks v-if="showDetail" :pos="7" :announce-detail="detail"
                  @closeMask="showDetail=false"></my-masks>
    </div>
</template>

<script>
    import bigTitle from "./components/big-title.vue"
    import {GetAnnouncement, GetResourceDetail, GetAnnouncementDetail, GetOneTagList} from "@/api";
    import MyMasks from "@/components/myMasks";

    export default {
        name: "more",
        components: {
            MyMasks,
            bigTitle
        },
        props: ['imgSrc', 'title'],
        data() {
            return {
                dataList: [],
                pageInfo: {},
                showDetail: false,
                detail: {}
            }
        },
        mounted() {
            this.$store.state.breadCrumbList[this.$store.state.breadCrumbList.length - 1].meta.title = this.title
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData(curPage) {
                let that = this
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                if (this.title === '公告')
                    GetAnnouncement(temp).then((res) => {
                        that.dataList = res.data.page_data
                        that.pageInfo = res.data
                    })
                else if (this.title === '最新素材')
                    GetOneTagList({type: 0, ...temp}).then((res) => {
                        that.dataList = res.data.page_data
                        that.pageInfo = res.data
                    })
            },

            // 查看更多
            clickItem(id) {
                // let that = this
                // if (that.title === "公告") {
                //     GetAnnouncementDetail({id: id + ''}).then((res) => {
                //         that.announceDetail = res.data
                //         that.showAnnouceDetail = true
                //     })
                // }
                console.log(this.title);
                if (this.title == '最新素材') {
                    GetResourceDetail({id: id}).then(res => {
                        console.log(res);
                        this.detail = res.data
                        this.showDetail = true
                    })
                }
            },

            // 翻页
            changePageIndex(curPage) {
                this.getData(curPage)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";
    @import "./style/overviewCommon";

    ul li {
        font-size: 14px;
        line-height: 40px;
        justify-content: space-between;

        div {
            width: 90%;

            span:last-child {
                .text-over-hidden(@w:80%)
            }
        }
    }

    /*  消息提醒没有时间显示 */
</style>