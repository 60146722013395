<template>
    <div class="users-list-wrap" v-if="!showView">
        <div class="filter-part">
            <div class="flex-div" style="flex-wrap: wrap">
                <div class="flex-div filter-item" style="margin-right: 0">
                    <span>关键词</span>
                    <el-input v-model="filterValue.keyword" placeholder="请输入昵称、学号、手机号"></el-input>
                </div>
                <div class="flex-div filter-item">
                    <span>选择标签人群</span>
                    <el-select v-model="filterValue.tag_id" placeholder="请选择">
                        <el-option v-for="(item, index) in tagOptions" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="flex-div filter-item">
                    <span>手机号码</span>
                    <el-select v-model="filterValue.has_phone" placeholder="请选择">
                        <el-option v-for="(item, index) in phoneOptions" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="flex-div filter-item">
                    <span>是否会员</span>
                    <el-select v-model="filterValue.is_member" placeholder="请选择">
                        <el-option v-for="(item, index) in vipOptions" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="flex-div filter-item">
                    <span>服务课权限</span>
                    <el-select v-model="filterValue.service_level" placeholder="请选择">
                        <el-option v-for="(item, index) in serviceOptions" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="flex-div filter-item">
                    <span>最近消费时间</span>
                    <el-date-picker
                            v-model="payTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" :unlink-panels="true" value-format="YYYY-MM-DD">
                    </el-date-picker>
                </div>
                <div class="flex-div filter-item">
                    <span>累计消费金额</span>
                    <div class="filter-price">
                        <el-input type="number" v-model="filterValue.consumption_start"></el-input>
                        <span style="margin: 0 12px;color: #545454;">至</span>
                        <el-input type="number" v-model="filterValue.consumption_end"></el-input>
                    </div>
                </div>
                <div class="flex-div filter-item">
                    <span>绑定时间</span>
                    <el-date-picker
                            v-model="bindTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" :unlink-panels="true" value-format="YYYY-MM-DD">
                    </el-date-picker>
                </div>
            </div>
            <div class='filter-or-not'>
                <div class="filter-btn" @click="getData()">
                    <img src="../../assets/icon_filter.png" alt>
                    <span>筛选</span>
                </div>
                <span class="clear-btn" @click="clearFilter">清空所有筛选项</span>
            </div>
        </div>
        <div class="list-part">
            <num-refresh-export :total-num="pageInfo.total" @refreshTable="clickRefresh"
                                @exportTableData="clickExport"></num-refresh-export>
            <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}" fit
                      highlight-current-row empty-text="暂无数据" :default-sort="defaultSort" @sort-change="sortColumn">

                <el-table-column v-if="slots" :label="slots.name" :width="slots.w">
                    <template #default="scope">
                        <table-slots :i="slots.i" :columnData="scope.row"></table-slots>
                    </template>
                </el-table-column>
                <el-table-column v-for="i in columnNum-1" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                                 align="center" :sortable="sortableColumns[i]" @sort="sortColumn(i)"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <div class="action-class">
                            <span @click="getActionChoice(scope.row,0)">{{actions[0]}}</span>
                            <template v-if="actions.length > 1">
                                <template v-for="j in actions.length-1" :key="j">
                                  <span class="line">|</span>
                                  <span @click="getActionChoice(scope.row,j)">{{actions[j]}}</span>
                                </template>
                            </template>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                           :page-size="pageInfo.per_page"
                           :page-count="pageInfo.last_page" :current-page="pageInfo.current_page"
                           :total="pageInfo.total"
                           class="page-div" @current-change="changePageIndex"></el-pagination>

            <my-masks v-if="showExportPage" :pos="5" :pages="pages" @closeMask="showExportPage=false"
                      @confirmExportPages="confirmExportPages"></my-masks>
            <my-masks v-if="showTags" :pos="8" :tags="tags" @confirmTag="confirmTag"
                      @closeMask="showTags=false" @setTagSuccess="setTagSuccess"></my-masks>
        </div>
    </div>
    <router-view v-else></router-view>
</template>

<script>
    import {GetParentsList} from '@/api/index.js'
    import {myMasks, numRefreshExport} from '@/components'
    import {
        exportExcel,
        GetAllUserTagList,
        getLoading,
        getExportLoading,
        getLocalStorage,
        getPageInfo,
        processTags
    } from "@/utils";
    import TableSlots from "@/components/tableSlots";

    export default {
        name: "usersList",
        components: {TableSlots, myMasks, numRefreshExport},
        computed: {
            showView() {
                return this.$route.matched.length === 3
            }
        },
        data() {
            return {
                // 筛选选项 和筛选值
                tagOptions: [],
                phoneOptions: [{value: 1, label: '有'}, {value: 0, label: '无'},],
                vipOptions: [{value: 1, label: '会员'}, {value: 0, label: '非会员'},],
                serviceOptions: [],
                filterValue: {
                    keyword: '',
                    tag_id: '',
                    has_phone: '',
                    is_member: '',
                    service_level: '',
                    // todo 待处理
                    consumption_start: '',
                    consumption_end: ''
                },
                payTime: '',
                bindTime: '',
                tatalPay: '',
                // 表格
                columnNum: 11,
                tableTitles: ['学号', '手机号码(按时间)', '是否会员', '服务课权限', '最近消费时间', '消费金额', '绑定时间', '最近活跃时间', '会员剩余时长', '学习时长'],
                tableProps: ['student_id', 'phone', 'is_member', 'service_lesson_names', 'latest_consumption_time', 'total_consumption_amount', 'bind_time', 'last_login_time', 'member_expire', 'study_time'],
                tableData: [],
                // Array.fill(value,start,end)
                sortableColumns: [false, false, 'custom', false, false, 'custom', 'custom', 'custom', 'custom', 'custom', 'custom'],
                defaultSort:{prop:'phone',order:'descending'},
                sortParams:{
                    order_field: '',
                    order: ''
                },
                actions: ['详情', '贴标签', '开通服务课'],
                pageInfo: {},
                slots: {name: '用户', i: 1},
                // 刷新 导出
                refreshFlag: false,
                showExportPage: false,
                exportData: [],      // 导出数据集合
                exportLoading: {},   // 导出loading
                pages: {start: 1, end: 1, max: 0},   // 导出页码选择 从start 到 end 页,最大不超过 列表项最大页数max
                // 标签
                tags: {
                    options: [],
                    choosenValue: ''
                },
                showTags: false,
            }
        },
        mounted() {
            let that = this
            GetAllUserTagList(that).then((res) => {
                that.tags.options = processTags(res)
                that.tagOptions = that.tags.options
            })
            if (JSON.stringify(that.$store.state.services) === '{}')
                that.$store.commit('setSomeInfo', JSON.parse(getLocalStorage('someInfo')))
            that.serviceOptions = that.$store.state.services

            this.initData();
            this.getData();
        },
        watch: {

        },
        methods: {
            // 按照某列排序
            sortColumn(column) {
                const that = this
                console.log(column)
                console.log(column.prop,column.order)
                // 将需要排序的信息以【全局变量】形式展示  比如切换页码仍然会伴随着排序信息
                if (column.order == null) {
                    that.sortParams.order_field = 'add_phone_time'
                    that.sortParams.order = 'desc'
                } else {
                    if(column.prop==='phone'){
                        that.sortParams.order_field = 'add_phone_time'
                    } else {
                        that.sortParams.order_field = column.prop
                    }
                    that.sortParams.order = column.order == 'descending' ? 'desc' : 'asc'
                }
                that.getData()
            },

            // 筛选数据
            getFilterValue() {
                let that = this
                let temp = {}
                let keys = Object.keys(that.filterValue)
                keys.forEach((key) => {
                    if (that.filterValue[key] !== '')
                        temp[key] = that.filterValue[key]
                })
                if (that.payTime !== '') {
                    temp['pay_time_start'] = that.payTime[0]
                    temp['pay_time_end'] = that.payTime[1]
                }
                if (that.bindTime !== '') {
                    temp['bind_time_start'] = that.bindTime[0]
                    temp['bind_time_end'] = that.bindTime[1]
                }
                return temp
            },
            // 获取数据
            getData(curPage) {
                let that = this;
                let loading = getLoading(that, '.list-part')
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                temp = {...temp, ...that.getFilterValue(),...that.sortParams}
                console.log(temp)
                GetParentsList({
                  ...temp,
                  'version': 'v2',
                }).then((res) => {
                  console.log(res);
                    if (res.data.page_data && res.data.page_data .length) {
                        that.processData(res.data.page_data)
                        that.pageInfo = getPageInfo(res.data)
                        if (that.refreshFlag) {
                            that.refreshFlag = false
                        }
                    }
                    loading.close()
                })
            },
            processData(data) {
                data.forEach((item) => {
                    item['is_member'] = item['is_member'] ? '是' : '否'
                    item['phone'] = item['phone'] + '(' + item.add_phone_time + ')'
                })
                this.tableData = data
            },

            // 刷新
            clickRefresh() {
                this.refreshFlag = true
                this.initData()
                this.getData();
            },

            // 导出
            clickExport() {
                if (this.tableData !== 0) {
                    this.pages = {start: 1, end: 1, max: this.pageInfo.last_page}  // start <= end
                    this.showExportPage = true
                } else {
                    this.$message.info('还没有数据哦')
                }
            },
            confirmExportPages(p) {
                let that = this
                that.showExportPage = false;
                that.exportData = []
                that.loadingData(false, p.start, p.start, p.end, 1, p.end - p.start + 1, this.getFilterValue())
            },
            // 某页导出失败标识,页码，最小值，最大值，当前正在导出第几页，总共多少页，请求参数，
            loadingData(failFlag, i, min, max, cur, total, params) {
                let that = this
                if (failFlag) {
                    that.exportLoading.close()
                    that.$message.error('导出失败！')
                    return
                }
                if (i !== min)
                    that.exportLoading.setText(`导出页码：${min}-${max}，当前进度：${cur}/${total}`)
                else
                    that.exportLoading = getExportLoading(that, `导出页码${min}-${max}，当前进度${cur}/${total}`)
                GetParentsList({
                    'page': i,
                    'version': 'v2',
                    ...params,
                    ...this.sortParams
                }).then((response) => {
                    response.data.page_data.forEach((item) => {
                        item['is_member'] = item['is_member'] ? '是' : '否'
                        item['phone'] = item['phone'] + '(' + item.add_phone_time + ')'
                        that.exportData.push(item)
                    })
                    if (i === max) {
                        exportExcel(['用户', ...that.tableTitles], ['nickname', ...that.tableProps], that.exportData, '用户列表')
                        that.exportLoading.setText(`加载完成`)
                        that.exportLoading.close()
                    } else {
                        (function () {
                            setTimeout(function () {
                                that.loadingData(false, i + 1, min, max, cur + 1, total, params)
                            }, 200)
                        })()
                    }
                }).catch(() => {
                    that.loadingData(true, i + 1, min, max, cur + 1, total, params)
                })
            },

            // 翻页
            changePageIndex(curPage) {
                this.getData(curPage)
            },

            // 表格操作
            getActionChoice(item, ai) {
              console.log(item)
                let that = this
                if (ai === 0) {
                    that.$router.push({name: 'usersDetail', params: {user_id: parseInt(item.id)}})   // path query | name params
                } else if (ai === 1) {
                    that.tags.choosenValue = item.tag.id ? item.tag.id : ""
                    that.tags['user_id'] = item.id
                    that.showTags = true
                } else {
                    that.$router.push({name: '权限申请', params: {student_id: item['student_id']}})
                }
            },

            // 标签设置成功
            setTagSuccess(item, userId) {
                let l = this.tableData.length;
                for (let i = 0; i < l; i++) {
                    if (this.tableData[i].id === userId) {
                        this.tableData[i].tag = item
                        break
                    }
                }
            },

            // 初始化数据
            initData() {
                let that = this
                that.filterValue = {
                    keyword: '',
                    tag_id: '',
                    has_phone: '',
                    is_member: '',
                    service_level: '',
                    consumption_start: '',
                    consumption_end: '',
                }
                that.payTime = ''
                that.bindTime = ''
                that.sortParams={
                    order_field: 'add_phone_time',
                    order: 'desc'
                }
            },

            clearFilter() {
                this.initData()
                this.getData()
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    /*  筛选模块 */
    .filter-part {
        .shadow-border-bgcolor();
        padding: 27px 35px;

        .filter-item {
            margin: 0 50px 40px 0;

            span:first-child {
                margin-right: 15px;
                white-space: nowrap;
                color: #545454;
            }

            .my-el-input(@w: 300px,@fontcolor: #BCBABA,@backgroundcolor: #fff,@bordercolor: #BFBFBF);

            .my-el-select(@w: 120px,@fontcolor: #BCBABA,@backgroundcolor: #fff);

            ::v-deep(.el-select .el-input__suffix) {
                margin-right: -5px;
                background: #EAEAEA;
                border-radius: 0 5px 5px 0;
            }

            .my-el-datepicker(@fontcolor:#BCBABA);
        }

        .filter-price {
            .my-el-input(@w: 120px,@fontcolor: #BCBABA,@backgroundcolor: #fff,@bordercolor: #BFBFBF);
        }
    }

    .list-part {
        .shadow-border-bgcolor();
        margin: 30px auto;
        padding: 20px 20px 30px 20px;
    }
</style>