<template>
    <div class="order-wrap">
        <title-underline-choice :choices="choices" :choice-index=choiceIndex
                                @getChoice="makeChoice"></title-underline-choice>
        <div class="filter-part flex-div">
            <div class="filter-item flex-div" style="margin: 0;">
                <span style="white-space: nowrap;margin-right: 12px;">关键字</span>
                <el-input v-model="keyword" placeholder="请输入昵称、学号、手机号"></el-input>
            </div>
            <div class='filter-or-not'>
                <div class="filter-btn" @click="getData()">
                    <img src="@//assets/icon_filter.png" alt>
                    <span>筛选</span>
                </div>
                <span class="clear-btn" @click="clearFilter">清空所有筛选项</span>
            </div>
        </div>
        <num-refresh-export :total-num="pageInfo.total" @refreshTable="clickRefresh" @exportTableData="clickExport"
                            style="margin-top: 30px;"></num-refresh-export>
        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'16px'}" fit
                  highlight-current-row empty-text="暂无数据" style="font-size:16px;color: #666666;">
            <el-table-column v-for="i in columnNum-1" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                             align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <div class="action-class">
                        <span @click="getActionChoice(scope.row,0)">备注</span>
                        <span class="line">|</span>
                        <span style="color: #B5B5B5" v-if="scope.row.is_contact">已联系</span>
                        <el-popover placement="top" :width="100" :visible="popVisible[scope.row.id]===true" v-else>
                            <span style="display:inline-block;margin-bottom: 5px;">确定标记为已联系？</span>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="popVisible[scope.row.id] = false">取消
                                </el-button>
                                <el-button type="primary" size="mini" @click="confirmContact(scope.row)">确定</el-button>
                            </div>
                            <template #reference>
                                <span @click="getActionChoice(scope.row,1)">未联系</span>
                            </template>
                        </el-popover>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>

        <my-masks v-if="showRemarkMask" :pos="4" :remark-input="operateItem.remark" @closeMask="showRemarkMask=false"
                  @makeRemark="submitRemark"></my-masks>
        <my-masks v-if="showExportPage" :pos="5" :pages="pages" @closeMask="showExportPage=false"
                  @confirmExportPages="confirmExportPages"></my-masks>
    </div>
</template>

<script>
    import {ConfirmContact, GetEnrollList, SubmitRemark} from "@/api";
    import {myMasks, myTable, numRefreshExport, titleUnderlineChoice} from '@/components'
    import {exportExcel, getExportLoading, getLoading, getPageInfo} from "@/utils";

    export default {
        name: "registration",
        components: {
            myMasks,
            myTable,
            titleUnderlineChoice,
            numRefreshExport
        },
        data() {
            return {
                choices: ['知识商品', '实物商品', '报名信息'],
                choiceIndex: 2,
                // 表格信息
                columnNum: 7,
                tableTitles: ['姓名', '电话', '孩子年龄', '来源课程', '填表时间', '备注', '操作'],
                tableProps: ['name', 'phone', 'child_age', 'from_material', 'add_time', 'remark', 'actions'],
                tableData: [],
                pageInfo: {},
                // 当前操作对象
                operateItem: {},
                // 是否显示备注弹框
                showRemarkMask: false,
                // 刷新和导出
                refreshFlag: false,
                showExportPage: false,
                exportData: [],      // 导出数据集合
                exportLoading: {},   // 导出loading
                pages: {start: 1, end: 1, max: 0},
                // 是否弹出确认已联系的提示框
                popVisible: {},
                // 筛选关键字
                keyword: ''
            }
        },
        mounted() {
            this.showRemarkMask = false;
            this.keyword = ''
            this.getData()
        },
        methods: {
            // 从接口获取数据
            getData(curPage) {
                let that = this;
                Object.assign(that.popVisible,{})
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                if (that.keyword)
                    temp['keyword'] = that.keyword
                let loading = getLoading(that, '.order-wrap')
                GetEnrollList({...temp}).then((res) => {
                    if (res.data.page_data && res.data.page_data .length) {
                        that.tableData = res.data.page_data
                        that.pageInfo = getPageInfo(res.data)
                        if (that.refreshFlag) {
                            that.$message.success('刷新完成!')
                            that.refreshFlag = false
                        }
                    }
                    loading.close()
                })
            },

            // 切换表格页码
            changePageIndex(curPage) {
                this.getData(curPage)
            },

            // 切换顶部选项
            makeChoice(i) {
                if (this.choiceIndex !== i) {
                    this.$router.push(i === 0 ? '/knowledgeCommodity' : '/physicalCommodity')
                }
            },
            // 刷新
            clickRefresh() {
                this.refreshFlag = true;
                this.keyword = ''
                this.getData()
            },
            // 清空所有筛选项
            clearFilter() {
                this.keyword = ''
                this.getData()
            },
            // 导出
            clickExport() {
                if (this.tableData !== 0) {
                    this.pages = {start: 1, end: 1, max: this.pageInfo.last_page}  // start <= end
                    this.showExportPage = true
                } else {
                    this.$message.info('还没有数据哦')
                }
            },
            confirmExportPages(p) {
                let that = this
                that.showExportPage = false;
                that.exportData = []
                that.loadingData(false, p.start, p.start, p.end, 1, p.end - p.start + 1)
            },

            // 某页导出失败标识,页码，最小值，最大值，当前正在导出第几页，总共多少页
            loadingData(failFlag, i, min, max, cur, total) {
                let that = this
                if (failFlag) {
                    that.exportLoading.close()
                    that.$message.error('导出失败！')
                    return
                }
                if (i !== min)
                    that.exportLoading.setText(`导出页码：${min}-${max}，当前进度：${cur}/${total}`)
                else
                    that.exportLoading = getExportLoading(that, `导出页码：${min}-${max}，当前进度：${cur}/${total}`)
                GetEnrollList({'page': i, keyword: that.keyword}).then((response) => {
                    that.exportData = [...that.exportData, ...response.data.page_data]
                    if (i === p.end) {
                        exportExcel(that.tableTitles, that.tableProps, that.exportData, '报名信息数据')
                        that.exportLoading.setText(`加载完成`)
                        that.exportLoading.close()
                    } else {
                        (function () {
                            setTimeout(function () {
                                that.loadingData(false, i + 1, min, max, cur + 1, total)
                            }, 200)
                        })()
                    }
                }).catch(() => {
                    that.loadingData(true, i + 1, min, max, cur + 1, total, params)
                })
            },

            // 表格操作:备注 / 是否已联系
            getActionChoice(item, ai) {
                this.operateItem = item
                if (ai === 0)
                    this.showRemarkMask = true   // 备注标记为哪一个条目的备注
                else {
                    this.popVisible[item.id] = true
                }
            },

            // 提交备注
            submitRemark(remark) {
                let that = this
                SubmitRemark({id: that.operateItem.id, msg: remark, type: 2}).then((res) => {
                    that.$message.success(res.data)
                    let l = that.tableData.length
                    for (let i = 0; i < l; i++) {
                        if (that.operateItem.id === that.tableData[i].id) {
                            that.tableData[i].remark = remark
                            break
                        }
                    }
                }).catch((e) => {
                    that.$message.info(e.msg)
                })
            },

            // 确认已联系
            confirmContact(item) {
                let that = this
                ConfirmContact({id: item.id}).then((response) => {
                    that.$message.success(response.data)
                    that.popVisible[item.id] = false
                    that.getData()
                }).catch((e) => {
                    that.$message.info(e.msg)
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "style/ordersManageCommon";
</style>