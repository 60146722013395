export function getEmojis(){
    // let temp = [{},
    //     {name:'',url:''}
    //     ]   // 代码生成  map 形式
    let temp = [
        '',
        '微笑','撇嘴','色','发呆','得意','流泪','害羞','闭嘴','睡','大哭','尴尬','发怒','调皮','呲牙',
        '惊讶','难过','酷','囧','抓狂','吐','偷笑','愉快','白眼','傲慢','馋','困','惊恐','汗',
        '憨笑','悠闲','奋斗','咒骂','疑问','嘘','晕','疯了','衰','骷髅','敲打','再见','擦汗','抠鼻',
        '鼓掌','糗大了','坏笑','左哼哼','右哼哼','打哈欠','鄙视','委屈','快哭了','阴险','亲亲','天啊','吓','菜刀',
        '西瓜','啤酒','蓝球','乒乓','咖啡','饭','猪头','玫瑰','凋谢','嘴唇','爱心','心碎','蛋糕','闪电',
        '炸弹','刀','足球','瓢虫','便便','月亮','太阳','礼物','拥抱','强','弱','握手','胜利','抱拳',
        '勾引','拳头','差劲','爱你','NO','OK','爱情','飞吻','跳跳','发抖','怄火','转圈','磕头','回头',
        '跳绳','投降','激动','乱舞','献吻','左太极','右太极','喜','鞭炮','灯笼','麻将','唱歌','文件','信',
        '帅','庆祝','蜡烛','棒棒糖','奶瓶','面','香蕉','飞机','汽车','车头','车上','车尾','云朵',
        '下雨','钞票','熊猫','灯泡','风车','闹钟','雨伞','气球','戒指','沙发','纸','药','手枪','青蛙',
    ]
    let emojis = [];
    temp.forEach((item,i)=>{
        emojis.push({
            name:item,
            url:'/emotions/'+i+'.gif'
        })
    })
    emojis.splice(0,1)
    // console.log(emojis)
    return emojis
}