<template>
    <div class="module-wrap">
        <div class="top-div">
            <big-title :img-src="resourceIconImg" title="最新素材"></big-title>
            <!-- <div class="more-div flex-div" @click="clickMore(resourceIconImg,'最新素材')"> -->
            <div class="more-div flex-div" @click="$router.push('/promotionArticle')">
                <span>更多</span>
                <img src="../../assets/more_row.png">
            </div>
        </div>
        <div class="resource-data">
            <ul class="flex-div" style="justify-content: space-between;flex-wrap: wrap">
                <li class="flex-div" style="line-height: 26px;" v-for="(item, i) in dataList" :key="i" @click="clickItem(item)">
                    <div class="flex-div">
                        <span class="list-dot"></span>
                        <span style="color: #FF4444">{{item.type == 0 ? '【线上素材】' : item.type == 1 ? '【个人创作】' : '【线下素材】'}}</span>
                        <span style="cursor: pointer">{{item.title}}</span>
                    </div>
                    <span style="color: #686868;text-align: right">{{item.add_time}}</span>
                </li>
            </ul>
        </div>
        <my-masks v-if="showResourceDetail" :pos="7" :announce-detail="resourceDetail"
                  @closeMask="showResourceDetail=false"></my-masks>
    </div>
</template>

<script>
    import bigTitle from "./components/big-title.vue"
    import {GetOneTagList, GetResourceDetail} from "@/api";
    import MyMasks from "@/components/myMasks";

    export default {
        name: "lastestResource",
        components: {
            MyMasks,
            bigTitle
        },
        // props:[]
        data() {
            return {
                dataList: [],
                resourceIconImg: require("@/assets/icon_dataOverview.png"),
                resourceDetail: {},
                showResourceDetail: false,
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                let that = this
                GetOneTagList({type: 0}).then((res) => {
                    console.log(res.data);
                    that.dataList = res.data.page_data
                })
            },
            clickMore(img, tit) {
                this.$emit('clickMore', img, tit);
            },
            clickItem(item) {
                GetResourceDetail({id: item.id}).then(res => {
                    this.resourceDetail = res.data
                    this.showResourceDetail = true
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";
    @import "./style/overviewCommon";

    .top-div {
        display: flex;
        justify-content: space-between;
    }

    .resource-data {
        margin: 25px 0 0 3px;

        ul li {
            margin: 5px 0;
            width: 46%;

            div {
                width: 80%;

                span:last-child {
                    .text-over-hidden(@w: 80%)
                }
            }
        }
    }
</style>