<template>
    <div class="bottom-part flex-div" v-if="!searching">
        <i class="el-icon-search" @click="searchUser"></i>
        <!--<i class="el-icon-circle-plus-outline" @click="addFriends"></i>-->
        <i class="el-icon-bell" @click="viewMessages"></i>
    </div>
    <div class="bottom-part flex-div" style="padding: 0 6px;box-sizing: border-box;" v-else>
        <el-input v-model="searchTxt" autofocus>
            <template #suffix>
                <i class="el-icon-error" style="line-height: 40px;" @click="clearSearch"></i>
            </template>
        </el-input>
    </div>
</template>

<script>
    export default {
        name: "chatBottom",
        data() {
            return {
                searching: false,
                searchTxt: ''
            }
        },
        watch: {
            searchTxt(n, o) {
                if (n !== o)
                    this.$emit('searchUser', true, n)
            }
        },
        methods: {

            // 查找用户
            searchUser() {
                this.searching = true
            },
            clearSearch() {
                this.searching = false
                this.searchTxt = ''
                this.$emit('searchUser', false)
            }
        }
    }
</script>

<style lang="less" scoped>
    .bottom-part {
        height: 10%;
        background: #f1eaea;
        justify-content: space-around;

        i {
            font-size: 26px;
            cursor: pointer;
        }
    }
</style>