<template>
    <div id="drag2" :style="{width: chatUsers.users.length > 1 ? '760px' : '600px'}">
        <div class="current-chat" v-if="chatUsers.users.length > 1">
            <div class="chat-item flex-div" v-for="(item,i) in chatUsers.users" :key="i"
                 :class="i === chatUsers.curIndex ? 'chat-active':''" @click="clickChatItem(i)">
                <img :src="item.icon" alt="">
                <span :title="item.nickname" style="width: 80%">{{item.nickname}}</span>
                <i class="el-icon-circle-close" @click.stop="deleteChatUserItem(i)"></i>
            </div>
        </div>
        <div class="chatting">
            <div style="cursor:move;height: 10px;width: 100%;background-color: rgba(128, 185, 255, 0.99)" v-drag></div>
            <div class="part1">
                <img :src="currentUser.icon" alt="">
                <span>{{currentUser.nickname}}</span>
                <span style="margin-left: 10px" v-if="currentUser.phone">({{currentUser.phone}})</span>
                <i class="el-icon-close" @click="closeDragWindow"></i>
            </div>
            <div class="part2" id="msg" @scroll="scrollEvent">
                <template v-for="(item, i) in chatList" :key="i">
                    <div class="counterpart" v-if="!item.is_self">
                        <img :src="item.icon" style="margin-right: 20px;" alt="">
                        <div style="align-items: flex-start;">
                            <span>{{item.nickname}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.time}}</span>
                            <img v-if="item.msg_type === 'img'" style="width: 200px;height: auto;border-radius: 6px;"
                                 :src="item.content">
                            <span class="flex-div" v-else-if="item.msg_type==='text'" v-html="item.content"></span>
                        </div>
                    </div>
                    <div class="myselfpart" v-else>
                        <div style="align-items: flex-end;">
                            <span class="name-time" style="text-align: right">{{item.time}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{userSelf.nickname}}</span>
                            <img v-if="item.msg_type === 'img'" style="width: 200px;height: auto;border-radius: 6px;"
                                 :src="item.content" alt="">
                            <span class="flex-div" style="background-color: #0ccf88;color: #fff;"
                                  v-else-if="item.msg_type==='text'" v-html="item.content"></span>
                        </div>
                        <img :src="userSelf.icon" style="margin-left: 20px;" alt="">
                    </div>
                </template>
            </div>
            <div class="part3">
                <div class="input-top flex-div" style="justify-content: space-between">
                    <div class="flex-div">
                        <img :src="require('/src/assets/emotions/1.gif')"
                             style="margin-right:30px;width: 22px;height: 22px"
                             @click="popEmotions" ref="emoji1" alt="">
                        <el-upload :show-file-list=false :on-change="handleChange" :action="$store.state.uploadUrl">
                            <i class="el-icon-picture-outline"></i>
                        </el-upload>
                        <!--<i class="el-icon-folder"></i>-->
                        <div class="expression-outer" v-if="emotionsMask" :style="aniStyle" ref="emoji">
                            <div class="expression-inner">
                                <!-- title="提示"  表情名 -->
                                <img v-for="(e,i) in emojis" :key="i" :src="require('/src/assets' + e.url)"
                                     @click="clickEmoj(i)" :title="e.name" alt="">
                            </div>
                        </div>
                    </div>
                    <div>聊天记录</div>
                </div>
                <el-input type="textarea" ref="myInput" v-model="inputTxt" @keyup.enter="sendMsg('text')"></el-input>
                <div class="input-bot flex-div">
                    <div style="margin-right: 20px" @click="closeDragWindow">关闭</div>
                    <div @click="sendMsg('text')">发送</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {IMgetMsgLog} from '@/api'
    import {timestampToTime} from "@/utils";
    import {getEmojis} from "@/utils/emoji";

    export default {
        name: "chatWindow",
        props: ['counterpartUser', 'newMsgItem'],
        data() {
            return {
                inputTxt: '',
                chatList: [],
                chatUsers: {users: [], curIndex: -1},     // counterpartUser
                currentUser: {},

                emotionsMask: false,
                aniStyle: {animation: 'smallToBig 200ms ease-out'},

                userSelf: {},
                emojis: [],
            }
        },
        mounted() {
            let that = this
            document.addEventListener('click', (e) => {
                if (that.$refs.emoji) {   // 点击表情框外的区域，关闭表情框
                    let isSelf = that.$refs.emoji.contains(e.target)
                    let isEmoji1 = e.target === that.$refs.emoji1
                    if (!isSelf && !isEmoji1) {  // 不包含表情div
                        that.popEmotions()
                    }
                }
                // todo 要不要添加点击除聊天框以外的区域但是不包含IM框，关闭聊天框？
            })
        },
        computed: {
            scrollEvent() {
                let that = this
                let msg = document.getElementById('msg')
                // let vh = msg.clientHeight
                let count = 0;
                let h1 = msg.scrollHeight
                msg.onscroll = () => {
                    // if (msg.scrollTop === 0) {
                    //     // 加载数据，判断是否加载完成
                    //     if (count < 2) {
                    //         console.log('加载数据,要保留滚动条位置')
                    //         // that.msgList = that.chatList.concat(that.temp)
                    //         let h2 = msg.scrollHeight
                    //         setTimeout(() => {
                    //             msg.scrollTop = count++ ? h2 - h1  : h2
                    //             h1 = h2
                    //         }, 0)
                    //     } else {
                    //         console.log("加载完成，没有更多数据了")
                    //     }
                    // }
                }
            }
        },
        watch: {
            newMsgItem(n) {
                if (n.msg_type === 'text') {
                    n.content = this.processEmoji(n.content)
                }
                // todo 图片，将宽高设置为默认的宽高，图片可预览大图
                n.timestamp = n.time
                n.time = timestampToTime(n.time)
                this.chatList.push(n)
                this.toBottom()
            },
            'counterpartUser.curIndex'(n, o) {
                if (n !== o) {
                    this.chatUsers = this.counterpartUser
                    this.initData()
                }
            }
        },
        mounted() {
            this.userSelf = this.$store.state.loginUserinfo
            this.chatUsers = this.counterpartUser
            this.emojis = getEmojis()
            this.initData()
        },
        methods: {
            initData() {
                let that = this
                that.chatList = []
                that.inputTxt = ''
                that.emotionsMask = false
                that.animation = {animation: 'smallToBig 200ms ease-out'}
                that.currentUser = that.chatUsers.users[that.chatUsers.curIndex]
                that.$nextTick(() => {
                    that.$refs['myInput'].textarea.focus()
                })
                that.getData()
            },
            // 获取历史聊天记录
            getData() {
                let that = this
                IMgetMsgLog({to_uid: that.currentUser.id}).then((res) => {
                    that.chatList = res.data.page_data
                    res.data.page_data.forEach((item) => {
                        item.timestamp = item.time
                        item.time = timestampToTime(item.time)
                        if (item.msg_type === 'text')
                            item.content = that.processEmoji(item.content)
                    })
                    this.toBottom()
                })
            },

            deleteChatUserItem(i){
                this.$emit('deleteChatUserItem',i)
            },

            clickChatItem(i) {
                this.chatUsers.curIndex = i
                this.initData()
            },

            // 关闭聊天框
            closeDragWindow() {
                this.$emit('closeDrag')
            },

            // 发送消息
            sendMsg(msgType, content) {
                let that = this
                if (msgType === 'text') {
                    if(that.inputTxt.trim())
                        that.$emit('sendMsg', msgType, that.inputTxt)
                    else {
                        that.$message.info('发送消息不能为空')
                    }
                    that.inputTxt = ''
                } else if (msgType === 'img') {
                    that.$emit('sendMsg', msgType, content)
                }
                that.$nextTick(() => {
                    that.$refs['myInput'].textarea.focus()
                })
            },

            // 滚动到聊天页面底部
            toBottom() {
                this.$nextTick(() => {
                    let msg = document.getElementById('msg') // 获取对象
                    msg.scrollTop = msg.scrollHeight // 滚动高度
                })
            },

            // 弹出表情框，以动画形式
            popEmotions() {
                if (this.emotionsMask) {
                    this.aniStyle = {animation: 'bigToSmall 200ms ease-in'}
                    setTimeout(() => {
                        this.emotionsMask = false
                    }, 200)
                } else {
                    this.aniStyle = {animation: 'smallToBig 200ms ease-out'}
                    this.emotionsMask = true
                }
            },

            // 点击表情
            clickEmoj(i) {
                this.inputTxt += '[' + this.emojis[i].name + ']'
                // 添加一个node?
                this.popEmotions()
            },

            // 点击上传图片
            handleChange(e) {
                if (e.status === 'success') {
                    this.sendMsg('img', e.response.data)
                }
            },

            // 处理表情
            processEmoji(str) {
                // let str = "测试表情[微[微笑]笑]，哈哈哈[刀]"
                if (!(/\[(.+?)\]/g).test(str))
                    return str
                let tempArr = Array.from(new Set(str.match(/\[(.+?)\]/g)))
                let emojisL = this.emojis.length
                tempArr.forEach((item, index) => {
                    let t = item.replace('[', '').replace(']', '')
                    let i;
                    for (i = 0; i < emojisL; i++) {
                        if (t === this.emojis[i].name)
                            break
                    }
                    if (i !== emojisL) {
                        str = str.replaceAll(item, `<img style="width: 28px;height:28px;" src="https://brz-mini.oss-cn-beijing.aliyuncs.com/emotions/${i + 1}.gif" alt="">`)
                    }
                })
                return str
            }
        },

        directives: {
            drag(el) {
                el.onmousedown = (e) => {
                    let elem = document.getElementById('drag2')
                    // 算出鼠标相对元素的位置
                    let disX = e.pageX - elem.offsetLeft;
                    let disY = e.pageY - elem.offsetTop;
                    document.onmousemove = (e) => {
                        // 用鼠标位置减去鼠标相对元素的位置，得到元素的位置
                        let l = e.pageX - disX;
                        let t = e.pageY - disY;
                        // 判断超出视觉范围
                        if (l < 0) l = 0
                        if (t < 0) t = 0
                        if (l + elem.clientWidth > e.view.innerWidth) l = e.view.innerWidth - elem.clientWidth
                        if (t + elem.clientHeight > e.view.innerHeight) t = e.view.innerHeight - elem.clientHeight
                        // 绑定元素位置到 positionX 和 positionY 上面，移动当前元素
                        elem.style.left = l + 'px'
                        elem.style.top = t + 'px'
                        elem.style.margin = '0'
                    }
                    document.onmouseup = () => {
                        document.onmousemove = document.onmouseup = null;
                    }
                }
            },
            // clickoutside
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    #drag2 {
        position: fixed;
        background: #fff;
        box-shadow: 0 0 6px 2px lightblue;
        bottom: 0;
        right: 0;

        z-index: 988;
        top: 0;
        left: 0;
        margin: auto;
        height: 500px;
        display: flex;

        .current-chat {
            width: 160px;
            background-color: rgb(124, 180, 248);
            overflow-y: scroll;

            .chat-item {
                margin: 4px;
                height: 50px;
                padding: 10px;
                box-sizing: border-box;
                border-radius: 3px;
                cursor: pointer;

                img {
                    .my-img-wh-mr-bradius(@w: 30px,@h: 30px,@mr: 20px,@b-radius: 50%)
                }

                span {
                    .text-over-hidden()
                }
            }

            .chat-active {
                background-color: #fff;
            }
        }

        .current-chat::-webkit-scrollbar {
            width: 0;
        }
    }

    .chatting {
        width: 600px;
        height: 100%;
    }

    .part1 {
        position: relative;
        height: 16%;
        padding: 0 20px;
        box-sizing: border-box;
        background-color: rgba(128, 185, 255, 0.99);
        display: flex;
        align-items: center;

        img {
            .my-img-wh-mr-bradius(@w: 40px,@h: 40px,@mr: 20px,@b-radius: 50%)
        }

        i {
            position: absolute;
            top: 0;
            right: 10px;
            font-size: 26px;
            color: #fff;
        }
    }

    .part2 {
        height: 55%;
        overflow-y: scroll;
        padding: 10px 20px;
        box-sizing: border-box;

        img {
            .my-img-wh-mr-bradius(@w: 40px,@h: 40px,@mr: 0)
        }

        .counterpart,
        .myselfpart {
            margin-bottom: 10px;
            display: flex;

            div {
                max-width: 84%;
                display: flex;
                flex-direction: column;

                span {
                    display: inline-block;
                    width: auto;
                }

                span:first-child {
                    margin-top: -5px;
                    line-height: 30px;
                }

                span:last-child {
                    word-break: normal;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    overflow: hidden;

                    padding: 8px;
                    background-color: #e2e0e0;
                    border-radius: 5px;
                    position: relative;

                    .emoji-img {
                        .my-img-wh-mr-bradius(@w: 28px,@h: 28px,@mr: 0);
                    }
                }
            }
        }

        .counterpart {
            span:last-child::before {
                position: absolute;
                left: -5px;
                content: '';
                width: 12px;
                height: 12px;
                background-color: #e2e0e0;
                transform: rotate(144deg);
            }
        }

        .myselfpart {
            justify-content: flex-end;

            span:last-child::before {
                position: absolute;
                right: -5px;
                content: '';
                width: 12px;
                height: 12px;
                background-color: #0ccf88;
                transform: rotate(36deg);
            }
        }
    }

    .part3 {
        height: 29%;
        border-top: 0.1px solid #f1f1f1;

        .input-top {
            padding: 8px 20px;
            box-sizing: border-box;

            i {
                font-size: 22px;
                margin-right: 26px;
            }
        }

        ::v-deep(.el-textarea__inner) {
            border: none;
            resize: none; // 去掉右下角
        }

        .input-bot {
            padding-right: 20px;
            box-sizing: border-box;
            justify-content: flex-end;

            div {
                padding: 6px 20px;
                background-color: #4B78EA;
                color: #fff;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }

    .part2::-webkit-scrollbar {
        width: 0;
    }

    .part3 {
        position: relative;
        @h: 210px;

        /*::v-deep(.el-popover.el-popper){*/

        .expression-outer {
            position: absolute;
            left: 0;
            top: -@h;
            height: @h;
            width: 413px;
            overflow-y: scroll;
            padding: 10px;
            box-sizing: border-box;
            background-color: #c6f9f9;

            .expression-inner {
                border-top: 1px solid #B0B0B0;
                border-left: 1px solid #B0B0B0;

                img {
                    .my-img-wh-mr-bradius(@w: 28px,@h: 28px,@mr: 0);
                    border-right: 1px solid #B0B0B0;
                    border-bottom: 1px solid #B0B0B0;
                    padding: 3px;
                    box-sizing: border-box;
                    vertical-align: bottom; // 解决换行后有空隙的问题
                }
            }
        }

        .expression-outer::-webkit-scrollbar {
            width: 0;
        }

        /*}*/
    }
</style>