import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import App from './App.vue'
import router from './router/index'
import store from './store/index.js'
import "@/styles/index.less"
import 'element-plus/lib/theme-chalk/index.css';
// import 'dayjs/locale/zh-cn'
import locale from "element-plus/lib/locale/lang/zh-cn.js";
// import '@/styles/common.less'
import 'video.js/dist/video-js.css'
// import Blob from './excel/Blob.js'
// import Export2Excel from './excel/Export2Excel.js'

router.beforeEach((to,from,next)=>{
    document.title = to.meta.title
    if (to.path === '/login') return next()
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) return next('/login')
    next()
})

createApp(App).use(ElementPlus, { locale }).use(store).use(router).mount('#app')