<template>
    <div class="analysis-wrap">
        <title-underline-choice :choices="choices" :choice-index=choiceIndex
                                @getChoice="makeChoice"></title-underline-choice>
        <!--<router-view></router-view>-->
        <data-center-promotion v-if="choiceIndex === 0"></data-center-promotion>
        <data-center-sales-analysis v-else-if="choiceIndex === 1"></data-center-sales-analysis>
        <data-center-user-analysis v-else-if="choiceIndex === 2"></data-center-user-analysis>
        <data-center-vip-analysis v-else></data-center-vip-analysis>
    </div>
</template>

<script>
    import titleUnderlineChoice from "@/components/titleUnderlineChoice.vue";
    import DataCenterPromotion from "./dataCenterPromotion.vue";
    import DataCenterSalesAnalysis from "./dataCenterSalesAnalysis.vue";
    import DataCenterUserAnalysis from "./dataCenterUserAnalysis.vue";
    import DataCenterVipAnalysis from "./dataCenterVipAnalysis.vue";

    export default {
        name: "dataCenterIndex",
        components: {
            DataCenterVipAnalysis,
            DataCenterUserAnalysis,
            DataCenterSalesAnalysis,
            DataCenterPromotion,
            titleUnderlineChoice
        },
        data() {
            return {
                choices: ['推广分析', '销售分析', '用户分析', '会员分析'],
                choiceIndex: 0
            }
        },
        mounted() {
            // 用户列表，评论列表进入路由也会走这里
            if (this.$route.matched.length === 2) {
                let temp = this.$route.matched[0].children
                let l = temp.length
                let i = 0;
                for (; i < l; i++)
                    if (temp[i].path === this.$route.path)
                        this.choiceIndex = i
            } else {
                this.choiceIndex = 0
            }
            this.$store.commit('setRecentMonthDate')
        },
        methods: {
            makeChoice(i) {
                this.$router.push(this.$route.matched[0].children[i].path)
            }
        }
    }
</script>

<style scoped>

</style>