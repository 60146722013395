<template>
    <div class="correct-page-wrap">
        <div class="correct-content">
            <div class="parent-info">家长信息</div>
            <div class="flex-div base-parent-info">
                <img :src="user.icon">
                <div>
                    <span class="item-l">学号：<span>{{user.student_id}}</span></span>
                    <span class="item-l">姓名：<span>{{user.name}}</span></span>
                    <span class="item-l">电话：<span>{{user.phone}}</span></span>
                </div>
            </div>
            <div class="text-div">
                <small-title detail-title="家长答案"></small-title>
                <div class="answer-div content_answer" v-if="parentsAnswer.text">{{parentsAnswer.text}}</div>
                <div class="answer-div img_answer flex-div" style="flex-wrap: wrap" v-if="parentsAnswer.img_list">
                    <img v-for="(img, index) in parentsAnswer.img_list" :key="index" :src="img" alt="">
                </div>
                <div class="answer-div video_answer" v-if="parentsAnswer.video_url">
                    <video :src="parentsAnswer.video_url"></video>
                </div>
                <div class="answer-div audio_answer" v-if="parentsAnswer.audio_list">
                    <audio v-for="(audio,i) in parentsAnswer.audio_list" :key="i" :src="audio"></audio>
                </div>
            </div>
            <div class="my-rate flex-div">
                <small-title detail-title="我的评分" style="margin-right: 30px"></small-title>
                <i v-for="i in 5" :key="i" :class="i <= myCorrect.score ? 'el-icon-star-on':'el-icon-star-off'"
                :style="{color:'#FFB800',fontSize: i <= myCorrect.score ? '30px':'25px',marginRight:'10px'}" @click="myCorrect.score = i"></i>
            </div>
            <div class="text-div">
                <small-title detail-title="我的评语"></small-title>
                <el-input type="textarea" v-model="myCorrect.comment" placeholder="请填写你的评语"></el-input>
                <!--<div>-->
                <!--    很好，定规则后把一件事做好孩子的习惯就能规则意识跟习惯都能建立起来，孩子做到的，做好的，做对的，做了的，及时确认表扬，不好的只要-->
                <!--    不是故意的不用提，只看好的帮助孩子固化良性行为，通过持续学习帮助孩子全方位成长起来！-->
                <!--</div>-->
            </div>
            <div class="course-info-form">
                <small-title detail-title="参课信息"></small-title>
                <div class="course-info-item-wrap">
                    <div class="course-info-item">
                        <span>参课类别</span>
                        <el-input v-model="courseInfoForm.type" placeholder="请填写参课类别"></el-input>
                    </div>
                    <div class="course-info-item">
                        <span>参课城市</span>
                        <el-select v-model="courseInfoForm.join_city" placeholder="请选择">
                            <el-option v-for="(item,i) in area_list" :key="item" :label="item" :value="i"></el-option>
                        </el-select>
                    </div>
                    <div class="course-info-item">
                        <span>家长姓名</span>
                        <el-input v-model="courseInfoForm.parents_name" placeholder="请填写家长姓名"></el-input>
                    </div>
                    <div class="course-info-item">
                        <span>参课人姓名</span>
                        <el-input v-model="courseInfoForm.join_name" placeholder="请填写孩子姓名"></el-input>
                    </div>
                    <div class="course-info-item">
                        <span>参课日期</span>
                        <el-input v-model="courseInfoForm.join_date" placeholder="请填写参课日期"></el-input>
                    </div>
                </div>
                <div class="btn-div" @click="submit">提交批阅</div>
            </div>
        </div>
        <div class="task-content">
            <small-title detail-title="作业内容" style="padding-bottom:20px;border-bottom: 1px solid #E5E5E5;"></small-title>
            <div v-html="taskContent" style="margin-top: 20px"></div>
        </div>
    </div>
</template>

<script>
    import {GetTaskSystemDetail,SubmitCorrect} from '@/api'
    import {smallTitle} from "@/components";
    export default {
        name: "correctPage",
        components: {smallTitle},
        data(){
            return {
                correctItem:{},
                area_list:[],
                user:{},
                parentsAnswer:{},
                myCorrect:{},
                taskContent:'',
                courseInfoForm:{
                    type:'',
                    join_city:0,
                    parents_name:'',
                    join_name:'',
                    join_date:''
                },
                submitItem:{},
            }
        },
        mounted(){
            this.getData()
        },
        methods:{
            getData(){
                let that = this
                that.submitItem = {...that.$route.params};
                GetTaskSystemDetail({...that.$route.params,mode:'correcting'}).then((res) => {
                    that.user = res.data.user
                    that.area_list = res.data.area_list
                    that.parentsAnswer = res.data.homework
                    that.myCorrect = res.data.correcting
                    that.taskContent = res.data.content
                    that.courseInfoForm.type = res.data.classify_name
                    that.courseInfoForm.parents_name = res.data.user.name
                })
            },
            submit(){
                let that = this
                that.submitItem['score'] = that.myCorrect.score
                that.submitItem['comment'] = that.myCorrect.comment
                that.submitItem['parents_name'] = that.courseInfoForm.parents_name
                that.submitItem['join_city'] = that.area_list[that.courseInfoForm.join_city]
                that.submitItem['join_name'] = that.courseInfoForm.join_name
                that.submitItem['join_date'] = that.courseInfoForm.join_date
                SubmitCorrect(that.submitItem).then((res) => {
                    that.$message.success(res.data)
                    that.$router.push('/correct')
                }).catch((e)=>{
                    that.$message.success(e.msg)
                })
            }
        }
    }
</script>

<style lang="less" scoped>
@import "/src/styles/common";
    .correct-page-wrap{
        .shadow-border-bgcolor();
        padding: 20px 16px 60px 35px;
    }
    .parent-info{
        padding: 0 0 20px 16px;
        border-bottom: 2px solid #F1F1F1;

        color: #8A8F99;
        font-size: 18px;
    }
    .base-parent-info{
        margin-top: 32px;
        padding-left: 16px;
        img{
            .my-img-wh-mr-bradius(@w: 80px,@h: 80px,@mr: 40px,@b-radius: 50%);
        }
        span{
            font-size: 18px;
        }
        .item-l{
            display: block;
            color: #8A8F99;
            span{
                color: #353535;
            }
        }
    }

    .text-div{
        max-width: 1313px;
        margin-top: 30px;
        div:last-child{
            margin-top: 13px;
        }
        .my-el-textarea(@w: 1300px;@backgroundcolor: #F6F6F6)
    }
    .answer-div{
        margin-top: 13px;
    }
    .content_answer{
        width: 1300px;
        padding: 20px;
        box-sizing: border-box;
        background: #f6f6f6;
        border: 2px solid #E9E9E9;
    }
    .img_answer{
        img{
            .my-img-wh-mr-bradius(@w: 160px,@h: 100px,@mr: 30px)
        }
    }
    .video_answer{

    }
    .audio_answer{

    }

    .my-rate{
        margin-top: 28px;
        width: 1300px;
        height: 60px;
        border: 1px solid #EDEDED;
    }
    .course-info-form{
        margin-top: 30px;
    }
    .course-info-item-wrap{
        .course-info-item{
            margin-top: 18px;
            .flex-div();
            span:first-child{
                margin-right: 20px;
                display: inline-block;
                min-width: 80px;
                text-align: right;
                font-size: 16px;
                color: #404040;
            }

            .my-el-input(540px,40px);
        }
    }
    .btn-div{
        margin: 45px 0 0 230px;
    }

    .task-content{
        max-width: 1313px;
        margin-top: 20px;
        padding: 20px;
        background: #F6F6F6;
        border: 2px solid #E9E9E9;
    }

</style>