<template>
    <div>
        <analysisi-chart title="推广概况及趋势分析" :statistics="statistics" :chart-data="chartData"
                         @confirmDate="confirmDate"></analysisi-chart>
        <div class="analysis-table-wrap">
            <small-title detail-title="已发推广素材" style="margin-bottom: 38px;"></small-title>
            <my-table :column-num="titles.length" :table-titles="titles" :table-props="propName"
                      :table-data="tableData" :actions="actions" :page-info="pageInfo" :cursor="'pointer'"
                      @makeActionChoice="getActionChoice" @pageIndexChange="changePageIndex" @getDetail="getDetail"></my-table>
        </div>
        <my-masks v-if="showDetail" :pos="7" :announce-detail="detail"
                  @closeMask="showDetail=false"></my-masks>
    </div>
</template>

<script>
    import {myTable, smallTitle, analysisiChart, myMasks} from "@/components";
    import {GetSentResource, GetResourceDetail} from "@/api";
    import {getLoading} from "@/utils";
    import {processPromotionData, getConfirmDate} from "@/utils/dataProcess";

    export default {
        name: "dataCenterPromotion",
        components: {myTable, smallTitle, analysisiChart, myMasks},
        data() {
            return {
                statistics: [
                  {typename: '素材访客数', num: 0},
                  {typename: '素材浏览量', num: 0},
                  {typename: '分享素材数', num: 0},
                  {typename: '素材评论数', num: 0},
                  {typename: '累计素材浏览量', num: 0}
                ],
                chartData: [],

                titles: ['素材名称', '素材类型', '访客数', '浏览量', '评论数', '分享方式', '分享时间'],
                propName: ['title', 'cabinet_type', 'brow_count', 'total_brow_count', 'comment_count', 'share_type', 'add_time'],
                tableData: [],
                actions: ['用户列表'],
                pageInfo: {},
                showDetail: false,
                detail: {}
            }
        },
        // computed: {
        //     showView() {
        //         return this.$route.matched.length === 3
        //     }
        // },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                // 两个全部加载完成才能close
                let that = this
                let loading = getLoading(that, '.analysis-wrap')
                Promise.all([processPromotionData(that), that.getTableData()]).then((res) => {
                    loading.close()
                }).catch((e) => {
                    loading.close()
                })
            },

            getTableData(curPage) {
                let that = this
                let temp = []
                let loading;
                if (curPage) {
                    temp['page'] = curPage
                    loading = getLoading(that, '.analysis-table-wrap')
                }
                return new Promise(function (resolve, reject) {
                    GetSentResource(temp).then((res) => {
                        that.tableData = that.processTableData(res.data.page_data)
                        that.pageInfo = res.data
                        if (loading) loading.close()
                        resolve()
                    }).catch((e) => {
                        if (loading) loading.close()
                        reject(e)
                    })
                })
            },

            // 处理素材类型
            getCabinetType(type) {
                switch (type) {
                    case 0:
                        return '推广好文'
                    case 1:
                        return '个人创作'
                    case 2:
                        return '推广海报'
                    default:
                        return '推广好文'
                }
            },

            // 处理表格数据
            processTableData(data) {
                let that = this
                data.forEach((item) => {
                    item['cabinet_type'] = that.getCabinetType(item['cabinet_type'])
                })
                return data
            },

            // 时间选择
            confirmDate(date) {
                let loading = getLoading(this, '.analysis-chart-wrap')
                processPromotionData(this, getConfirmDate(this, date), loading)
            },

            // 切换页码
            changePageIndex(curPage) {
                this.getTableData(curPage)
            },

            // 表格操作
            getActionChoice(item, ai) {
              console.log(item, ai);
                if(ai === 0)
                    this.$router.push(`/dataUserList?id=${item.id}`)
                // this.$message.info("啊哦，暂时没有数据呢~")
            },

            getDetail(id) {
                GetResourceDetail({id: id}).then(res => {
                    this.detail = res.data
                    this.showDetail = true
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";
</style>