<template>
    <div class="resource-wrap">
        <title-underline-choice :choice-index="cIndex" :choices="choices"
                                @getChoice="makeChoice"></title-underline-choice>
        <div class="filter-online-offline">
            <orange-option :option-index="categoryIndex" :options="categoryList"
                           @getClickOption="clickOption"></orange-option>
            <div class="flex-div search-div" style="margin: 28px 0 21px 0;">
                <el-input placeholder="输入素材名称" v-model="searchKeyword"></el-input>
                <el-button @click="getData">搜索</el-button>
            </div>
        </div>

        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}"
                  :cell-style="{cursor:'pointer'}" fit
                  highlight-current-row empty-text="暂无数据" @row-click="clickItem">
            <el-table-column label="标题">
                <template #default="scope">
                      <span v-if="cIndex == 0" style="color: #FF4444">{{scope.row.type == 0 ? '【线上】' : scope.row.type == 1 ? '【个人创作】' : '【线下】'}}</span>
                      <span v-if="cIndex == 1" style="color: #FF4444">{{scope.row.type == 0 ? '【研修班】' : scope.row.type == 1 ? '【会员】' : '【直播】'}}</span>
                      {{cIndex == 0 ? scope.row.title : cIndex == 1 ? scope.row.name : ''}}
                </template>
            </el-table-column>
            <el-table-column label="添加时间" prop="add_time" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <div class="action-class">
                        <span @click.stop="getActionChoice(scope.row,0)">{{actions[0]}}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
        <my-masks v-if="showQRCode" :pos="2" :qrcode-info="qrcodeInfo" @closeMask="showQRCode=false"></my-masks>
        <my-masks v-if="showResourceDetail" :pos="7" :announce-detail="resourceDetail"
                  @closeMask="showResourceDetail=false"></my-masks>
    </div>
</template>

<script>
    import {GetOneTagList, GetResourceDetail, GetPromotionPoster, GetPromotionTags} from "@/api";
    import {myMasks, titleUnderlineChoice, orangeOption, myTable} from '@/components'
    import {getLoading, getLocalStorage, getPageInfo} from "@/utils";

    export default {
        name: "promotion",
        props: ['choiceIndex'],
        components: {myMasks, titleUnderlineChoice, orangeOption, myTable},
        data() {
            return {
                choices: ['推广好文', '推广海报', '赠送课程', '我的创作'],
                cIndex: 0,    // 对应choices的id
                caTagid: 0,   // 类别自带id

                categoryList: [],
                categoryIndex: 0,

                searchKeyword: '',

                columnNum: 3,
                tableTitles: ['标题', '添加时间'],
                tableProps: ['title', 'add_time'],
                actions: ['立即推广'],
                tableData: [],

                pageInfo: {},

                showQRCode: false,  // 展示二维码弹框
                qrcodeInfo: {tit: '立即推广'},

                resourceDetail: {},
                showResourceDetail: false,
            }
        },
        mounted() {
            let that = this;
            that.searchKeyword = ""
            that.cIndex = that.choiceIndex;
            that.caIndex = that.categoryIndex;
            that.getTag();
        },
        watch: {
            choiceIndex(newValue, oldValue) {
                let that = this;
                if (newValue !== oldValue) {
                    that.cIndex = newValue
                    that.getData();
                }
            },
        },
        methods: {
            makeChoice(i) {
                this.cIndex = i;
                this.categoryIndex = 0;
                this.caTagid = 0;
                this.$router.push(this.$router.options.routes[3].children[i].path)
            },

            changePageIndex(curPage) {
                this.getData(curPage)
            },

            clickOption(i, id) {
                this.categoryIndex = i;
                this.caTagid = id
                this.tableData = []
                this.getData()
            },

            clickItem(row) {
                if (this.cIndex === 0) {
                    // this.announceDetail = {
                    //     title: row.title,
                    //     content: row.content
                    // };
                    GetResourceDetail({id: row.id}).then(res => {
                        this.resourceDetail = res.data
                        this.showResourceDetail = true
                    })
                }
            },

            getData(curPage) {
                let that = this;
                let loading = getLoading(that, '.resource-wrap')
                if (that.cIndex > 1) {
                    loading.close()
                    return
                }
                let temp = {}
                if (that.searchKeyword)
                    temp['title'] = that.searchKeyword
                if (curPage)
                    temp['page'] = curPage
                if (that.cIndex === 1) {  // 推广海报
                    that.categoryList = that.$store.state.posterTagList
                    that.tableProps = ['name', 'add_time']
                    GetPromotionPoster({...temp, cid: that.caTagid}).then((res) => {
                        console.log(res);
                        if (res.data.page_data && res.data.page_data .length) {
                            that.tableData = res.data.page_data
                            that.pageInfo = getPageInfo(res.data)
                        }
                        loading.close()
                    })
                } else {   // 推广好文
                    that.categoryList = that.$store.state.articleTagList
                    that.tableProps = ['title', 'add_time']
                    temp['type'] = 0
                    GetOneTagList({...temp, tag_id: that.caTagid}).then((res) => {
                      console.log(res);
                        if (res.data.page_data && res.data.page_data .length) {
                          that.tableData = res.data.page_data
                          that.pageInfo = getPageInfo(res.data)
                        }
                        loading.close()
                    })
                }
            },

            // 获取到初始标签分类
            getTag() {
                let that = this
                if (that.cIndex === 0 && that.$store.state.articleTagList.length !== 0) {
                    that.categoryList = that.$store.state.articleTagList;
                } else if (that.cIndex === 1 && that.$store.state.posterTagList.length !== 0) {
                    that.categoryList = that.$store.state.posterTagList;
                } else {
                    GetPromotionTags(that.cIndex).then((res) => {
                        that.categoryList = [{id: 0, name: "全部"}, ...res.data];
                        that.$store.commit(that.cIndex ? 'setPosterTagList' : 'setArticleTagList', that.categoryList);
                    })
                }
                that.getData()
            },

            getActionChoice(item) {
              console.log(item);
                if (!this.$store.state.promotionCodeUrl) {
                    this.$store.commit('setSomeInfo', JSON.parse(getLocalStorage('someInfo')))
                }
                let temp = this.$store.state.loginUserinfo.encrypted_uid
                

                if (this.cIndex == 0) {
                    this.qrcodeInfo.url = `https://mini.dongjinyu.com/wechat/personal_office_v2/article_detail?id=${item.id}`
                } else if (this.cIndex == 1) {
                    this.qrcodeInfo.url = `https://mini.dongjinyu.com/wechat/personal_office_v2/poster_detail?id=${item.id}`
                }
                this.showQRCode = true
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../style/resourceCenterCommon";
</style>