<template>
    <div class="tutorial-wrap">
        <el-input type="textarea" v-model="content" placeholder="请输入内容"></el-input>
        <div class="btn-div" @click="submit">提交</div>
    </div>
</template>

<script>
    import {GetFeedback} from "@/api";

    export default {
        name: "feedback",
        data() {
            return {
                content: ''
            }
        },
        methods: {
            submit() {
                let that = this
                if (that.content) {
                    GetFeedback({msg: that.content}).then((res) => {
                        that.$message.success(res.data)
                        that.content = '';
                    })
                } else {
                    that.$message.success('请输入反馈内容')
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./style/tutorialCommon";

    .tutorial-wrap {
        padding-left: 30px;
        text-align: left;
        .my-el-textarea(@backgroundcolor: '#FAFAFA');
    }

    .btn-div {
        margin-top: 50px;
        margin-left: 300px;
    }
</style>