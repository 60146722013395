<template>
    <div class="module-wrap">
        <big-title :img-src="dataIconImg" title="数据概览"></big-title>
        <div class="data-overview-main">
            <div class="flex-div" style="flex-direction: column;" v-for="(idata, i) in dataList" :key="i">
                <span style="font-size: 14px;color: #717171;">{{idata.title}}</span>
                <span style="font-size: 55px;color: #2C2C2C;font-family: BoldFont">{{idata.totayNum}}</span>
                <span style="color: #717171;">昨日<span
                        style="display:inline-block;margin-left: 10px;color: #F58B52;">{{idata.yesterdayNum}}</span></span>
            </div>
        </div>
    </div>
</template>

<script>
    import bigTitle from "./components/big-title.vue"
    import {GetDataOverview} from "@/api";

    export default {
        name: "dataOverview",
        components: {
            bigTitle
        },
        // props:[] / {},
        data() {
            return {
                dataIconImg: require("@/assets/icon_dataOverview.png"),
                dataList: [
                    {title: '今日新增家长'},
                    {title: '今日新增订单'},
                    {title: '今日销售额'},
                    {title: '今日素材访客数'},
                    {title: '今日新增作业'},
                    {title: '总家长数'},]
            }
        },
        mounted(){
            this.getData()
        },
        methods:{
            getData(){
                let that = this
                GetDataOverview().then((res) => {
                    that.dataList[0]['totayNum'] = res.data.today_parents
                    that.dataList[0]['yesterdayNum'] = res.data.yesterday_parents
                    that.dataList[1]['totayNum'] = res.data.today_orders
                    that.dataList[1]['yesterdayNum'] = res.data.yesterday_orders
                    that.dataList[2]['totayNum'] = res.data.today_sale
                    that.dataList[2]['yesterdayNum'] = res.data.yesterday_sale
                    that.dataList[3]['totayNum'] = res.data.today_material_view
                    that.dataList[3]['yesterdayNum'] = res.data.yesterday_material_view
                    that.dataList[4]['totayNum'] = res.data.today_homework
                    that.dataList[4]['yesterdayNum'] = res.data.yesterday_homework
                    that.dataList[5]['totayNum'] = res.data.total_parents
                    that.dataList[5]['yesterdayNum'] = res.data.yesterday_total_parents
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .data-overview-main {
        margin-top: 40px;
        display: flex;
        justify-content: space-around;

        div span {
            display: block;
        }
    }
</style>