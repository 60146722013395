<template>
    <div class="record-wrap">
        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'16px'}" fit
                  highlight-current-row empty-text="暂无数据" style="font-size:16px;color: #666666;">
            <el-table-column :label="slots.name" :width="slots.w">
                <template #default="scope">
                    <table-slots :i="slots.i" :columnData="scope.row"></table-slots>
                </template>
            </el-table-column>
            <el-table-column :label="tableTitles[1]" align="center">
                <template #default="scope">
                    <div class="receiver-div flex-div">
                        <img :src="scope.row.icon">
                        <div>
                            <span style="margin-bottom: 23px;">{{scope.row.to_nickname}}</span>
                            <span>学号:{{scope.row.to_student_id}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="tableTitles[2]" :prop="tableProps[2]" align="center"></el-table-column>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
    </div>
</template>

<script>
    import MyTable from "/src/components/myTable.vue";
    import {GetGivingRecord} from "@/api";
    import TableSlots from "@/components/tableSlots.vue";
    import {getLoading, getPageInfo} from "@/utils";

    export default {
        name: "givingRecords",
        components: {TableSlots, MyTable},
        data() {
            return {
                columnNum: 3,
                tableTitles: ['课程', '领取人', '领取时间'],
                tableProps: ['title', 'to_nickname', 'get_time'],
                tableData: [],
                pageInfo: {},
                slots: {name: '课程', i: 0, w: 800},
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData(curPage) {
                let that = this
                let loading = getLoading(that,'.record-wrap')
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                GetGivingRecord({...temp}).then((res => {
                    if (res.data.page_data && res.data.page_data .length) {
                      that.tableData = res.data.page_data
                      that.pageInfo = getPageInfo(res.data)
                    }
                    loading.close()
                }))
            },
            changePageIndex(curPage) {
                this.getData(curPage)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .record-wrap {
        .shadow-border-bgcolor();
        padding: 23px 30px;
    }

    .receiver-div {
        img {
            .my-img-wh-mr-bradius(@w: 61px,@h: 61px,@mr: 24px,@b-radius: 50%)
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
</style>