<template>
    <div class="msg-tag-wrap">
        <title-underline-choice :choices="titleChoices" :choice-index="choiceIndex"
                                @getChoice="makeChoice"></title-underline-choice>
        <router-view v-if="showTagPage"></router-view>
        <template v-else>
            <router-view v-if="showView"></router-view>
            <template v-else>
                <small-title detail-title="重点人群" style="margin:37px 0 28px 15px;"></small-title>
                <div class="focus-group">
                    <div class="focus-group-item" v-for="(item, i) in focusGroupDes" :key="i">
                        <div class="focus-group-item-t flex-div">
                            <img :src="require('@/assets/icon_focus_group' + i + '.png')" class="icon-img" alt="">
                            <span>{{item.tit}}</span>
                        </div>
                        <div class="focus-group-item-m">
                        <span>
                            {{item.des}}
                        </span>
                            <div class="send-btn" @click="clickSendMsg(i)">群发消息</div>
                        </div>
                    </div>
                </div>
                <div class="create-new-msg" @click="clickSendMsg('n')">
                    <img src="@/assets/icon_create_new_message.png" class="icon-img" alt="">
                    <span>新建群发</span>
                </div>
                <my-table v-if="pageInfo" :column-num="columnNum" :table-titles="tableTitles" :table-props="tableProps"
                          :table-data="tableData"></my-table>
            </template>
        </template>
    </div>
</template>

<script>
    import {myTable, smallTitle,titleUnderlineChoice} from "@/components";

    export default {
        name: "groupMessage",
        components: {titleUnderlineChoice, myTable, smallTitle},
        data() {
            return {
                titleChoices: ['群发消息', '标签管理'],
                choiceIndex: 0,
                pageInfo: '',
                columnNum: 7,
                tableTitles: ['群发标题', '群发内容', '目标人群', '覆盖人数', '群发方式', '群发时间', '状态'],
                tableProps: ['title', 'content', 'targetPeople', 'coverNumbers', 'massWay', 'massTime', 'status'],
                // tableData
                tableData: [],

                focusGroupDes: [{
                    tit: '无手机号码客户',
                    des: '领取平台或好友赠送的短期体验会员的用户',
                }, {
                    tit: '高活跃客户',
                    des: '近7天内访问大于3天的用户',
                }, {
                    tit: '会员用户',
                    des: '有过消费记录但近30天未访问过的平台的用户',
                }, {
                    tit: '服务课程客户',
                    des: '近7天内，存在未付款订单的用户',
                }, {
                    tit: '已购专栏客户',
                    des: '对收藏过店铺任意商品的用户进行激励',
                },],

            }
        },
        computed: {
            showView() {
                return this.$route.meta.title === '新建群发'
            },
            showTagPage() {
                return this.$route.meta.title === '标签管理' || this.$route.meta.title === '新建标签'
            }
        },
        mounted() {
            if (this.$route.meta.title === '标签管理' || this.$route.meta.title === '新建标签')
                this.choiceIndex = 1
        },
        methods: {
            // 新建群发
            clickSendMsg(i) {
                this.$alert('3.0客户端未上线，暂不支持')
                // this.$router.push({name: 'createGroupMsg', params: {w: i}})
            },

            makeChoice(i) {
                if (this.choiceIndex !== i) {
                    this.choiceIndex = i;
                    console.log(i);
                    if (i === 0) {
                        this.$router.push('/groupMessage')
                    } else {
                        this.$router.push('/tagManage')
                    }
                }
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .msg-tag-wrap {
        .shadow-border-bgcolor();
        padding: 32px 20px 60px 40px;
    }

    .focus-group {
        display: flex;
        justify-content: space-between;

        .icon-img {
            .my-img-wh-mr-bradius()
        }

        .focus-group-item {
            width: 290px;
            height: 190px;
            border: 2px solid #F0F0F0;
        }

        .focus-group-item-t {
            padding: 0 15px;
            height: 50px;
            background: #F0F0F0;
            border-left: 4px solid #4C88FF;

            span {
                font-size: 19px;
                color: #181818;
            }
        }

        .focus-group-item-m {
            padding: 15px 20px;
            box-sizing: border-box;
            height: 140px;
            .flex-div();
            flex-direction: column;
            justify-content: space-between;
            font-size: 18px;

            span {
                line-height: 30px;
            }

            .send-btn {
                width: 128px;
                height: 37px;
                line-height: 37px;
                text-align: center;
                background: #4C88FF;
                border-radius: 5px;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }

    .create-new-msg {
        margin: 25px 0;
        .event-btn-div(@fontsize: 18px,@backgroundcolor: #F58B52,@bordercolor: #ED582B)
    }

    /* 新建群发页面 */
    .top-tips {
        margin-top: 37px;
        padding-left: 23px;
        width: 660px;
        height: 37px;
        line-height: 37px;
        background: #FFF4E8;
        border-radius: 5px;
        font-size: 18px;
        color: #4F545D;
    }

    .send-item {
        margin-top: 30px;

        .my-el-input();
        .my-el-textarea(@h: 240px);

        span:first-child {
            color: #404040;
        }

        .focus-group-option-div {
            padding-left: 20px;
            box-sizing: border-box;
            width: 510px;
            height: 60px;
            background: #F2F5F8;

            ::v-deep(.el-radio__label) {
                font-size: 16px;
                color: #4D4D4D;
            }

            .my-el-select();
        }

        .my-el-select(@w: 260px,@h: 45px);
    }

    .btn-div {
        margin-left: 250px;
    }

</style>