<template>
    <div class="flex-div">
        <img :src="imgSrc" alt="">
        <span>{{title}}</span>
    </div>
</template>

<script>
    export default {
        name: "bigTitle",
        props: ['imgSrc', 'title']
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    div {
        img {
            .my-img-wh-mr-bradius(@w: 20px,@h: 20px,@mr: 6px);
        }

        span {
            font-family: BoldFont;
            font-size: 24px;
            color: #2C8EFC;
        }
    }
</style>