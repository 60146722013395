import {getLocalStorage, removeLocalStorage} from "@/utils/index.js";
import axios from 'axios'
import router from '../router/index.js'
import { ElLoading } from 'element-plus'

const service = axios.create({
    baseURL: 'https://api.dongjinyu.com/',
    //baseURL: 'http://brz-server.cc/',
    method: 'post',
    timeout: 30000,
})

service.interceptors.request.use(config => {
    let token = getLocalStorage('token');
    if (token)
        config.headers.token = token;
    return config
}, error => {
    ElLoading.close()
    return Promise.reject(error)
})

service.interceptors.response.use(response => {
    if (response.data.code === 200) {
        return Promise.resolve(response.data)
    }
}, error => {
    console.log(error)
    switch (error.response.data.code) {
        case 500: {  // 操作失败
            return Promise.reject(error.response.data)
        }
        case 404: {  // 参数校验失败
            break;
        }
        case 401: {  // token失效
            // this.$message.fail('登录失效，请重新登录')
            removeLocalStorage('token')
            router.push({path: '/'}).then();
            ElLoading.close()
            break;
        }
        case 403: {  // 权限不足
            break;
        }
        default: {
            console.log('可能超时')
            break;
        }
    }
})

export default service