<template>
    <div class="analysis-wrap">
        <title-underline-choice :choices="choices" :choice-index=choiceIndex
                                @getChoice="makeChoice"></title-underline-choice>
        <area-data-promotion v-if="choiceIndex === 0"></area-data-promotion>
        <area-data-sales-analysis v-else-if="choiceIndex === 1"></area-data-sales-analysis>
        <area-data-user-analysis v-else-if="choiceIndex === 2"></area-data-user-analysis>
        <area-data-vip-analysis v-else></area-data-vip-analysis>
    </div>
</template>

<script>
    import titleUnderlineChoice from "@/components/titleUnderlineChoice.vue";
    import areaDataPromotion from "./areaDataPromotion.vue";
    import areaDataSalesAnalysis from "./areaDataSalesAnalysis.vue";
    import areaDataUserAnalysis from "./areaDataUserAnalysis.vue";
    import areaDataVipAnalysis from "./areaDataVipAnalysis.vue";

    export default {
        name: "areaDataIndex",
        components: {
            areaDataVipAnalysis,
            areaDataUserAnalysis,
            areaDataSalesAnalysis,
            areaDataPromotion,
            titleUnderlineChoice
        },
        data() {
            return {
                choices: ['推广分析', '销售分析', '用户分析', '会员分析'],
                choiceIndex: 0
            }
        },
        mounted() {
            if (this.$route.matched.length === 3) {
                let temp = this.$route.matched[1].children
                let l = temp.length
                let i = 0;
                for (; i < l; i++)
                    if (temp[i].path === this.$route.path)
                        this.choiceIndex = i
            } else {
                this.choiceIndex = 0
            }
            this.$store.commit('setRecentMonthDate')
            this.$store.commit('setIsAreaAdmin', this.$store.state.loginUserinfo.area_admin.id !== 0)
        },
        methods: {
            makeChoice(i) {
                this.$router.push(this.$route.matched[1].children[i].path)
            }
        }
    }
</script>

<style scoped>

</style>