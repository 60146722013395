<template>
    <div class="main-part">
        <div class="msg-item" v-if="sTxt" :style="{textAlign:searchRes.length === 0 ? 'center':''}">
            <span v-if="searchRes.length === 0">暂无搜索结果</span>
            <div class="user-item flex-div" v-for="(user,j) in searchRes" @click="clickUser(j)" v-else
                 :title="user.nickname">
                <img :src="user.icon" alt="">
                <div>
                    <span>{{user.nickname}}</span>
                    <span>{{user.name}}</span>
                </div>
            </div>
        </div>
        <div class="msg-item" v-else-if="tabi === 0" :style="{textAlign:recentUsers.length === 0 ? 'center':''}">
            <span v-if="recentUsers.length === 0">暂无最近聊天记录</span>
            <div class="user-item flex-div" v-for="(user,j) in recentUsers" @click="clickUser(j)"
                 :title="user.nickname">
                <img :src="user.icon" alt="">
                <div>
                    <span>{{user.nickname}}</span>
                    <span>{{user.name}}</span>
                </div>
            </div>
        </div>
        <div class="group-item" v-for="(a,areaIndex) in area" v-else-if="tabi === 1">
            <div class="group-item-total" @click="clickGroupItem(areaIndex)">
                <i class="el-icon-arrow-down" v-if="groupUnfold[areaIndex]"></i>
                <i class="el-icon-arrow-right" v-else></i>
                <span>{{a.area}}</span>
            </div>
            <div class="group-item-user" v-if="groupUnfold[areaIndex]">
                <div class="user-item flex-div" v-for="(user,userIndex) in users[areaIndex]"
                     @click="clickUser(userIndex,areaIndex)" :title="user.nickname">
                    <img :src="user.icon" alt="">
                    <div>
                        <span>{{user.nickname}}</span>
                        <span>{{user.newContent}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {IMgetAreaList, IMgetRecentContactedList, IMgetUserListByAreaId} from '@/api'

    export default {
        name: "chatMain",
        props: ['tabIndex', 'searchTxt'],
        data() {
            return {
                tabi: 0,
                area: [],
                groupUnfold: [],
                // 消息页面
                users: {},
                recentUsers: [],
                // 搜索的关键字
                sTxt: '',
                // 搜索结果
                searchRes: [],
            }
        },
        watch: {
            tabIndex(n) {
                this.tabi = n
                if (n === 1) {
                    this.getAreaData()
                } else {
                    this.getRecentData()
                }
            },
            searchTxt(n) {
                this.sTxt = n;
                this.searchUser(n)
            },
        },
        mounted() {
            this.tabi = this.tabIndex
            this.sTxt = this.searchTxt
            this.getRecentData()
            if (this.sTxt)
                this.searchUser(this.sTxt)
        },
        methods: {
            // 获取最近聊天记录
            getRecentData() {
                let that = this
                that.$store.commit('IMstore/setRecentUsers',null)
            },

            // 获取地区分组
            getAreaData() {
                let that = this
                IMgetAreaList().then((res) => {
                    that.area = res.data
                })
            },

            // 获取地区联系人
            clickGroupItem(i) {
                let that = this
                that.groupUnfold[i] = !that.groupUnfold[i]
                if (that.groupUnfold[i] && !that.users[i]) {   // 点击完后是展开状态，并且暂时没有该地区数据
                    IMgetUserListByAreaId({area_id: that.area[i].id}).then((res) => {
                        that.users[i] = res.data
                    })
                }
            },

            // 点击和某人聊天
            clickUser(j, i) {
                this.$emit('dragWindow', i === undefined ? this.sTxt ? this.searchRes[j] : this.recentUsers[j] : this.users[i][j])
            },

            // 查找用户
            searchUser(s) {
                let that = this
                if (s) {
                    IMgetUserListByAreaId({name: s}).then((res) => {
                        that.searchRes = res.data
                    })
                } else {
                    if (that.tabi === 1) {
                        this.getAreaData()
                    } else {
                        this.getRecentData()
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .main-part::-webkit-scrollbar {
        width: 3px;
        background-color: #f6f6f6;
    }

    .main-part::-webkit-scrollbar-thumb {
        background-color: rgba(128, 185, 255, 0.99);
        border-radius: 2px;
    }

    .main-part {
        height: 70%;
        overflow-y: scroll;
        background: #fff;
        padding: 10px;
        box-sizing: border-box;

        .group-item {
            .group-item-total {
                height: 30px;
                line-height: 30px;
                cursor: pointer;
            }
        }

        .user-item {
            padding: 6px 0;
            box-sizing: border-box;
            cursor: pointer;

            img {
                .my-img-wh-mr-bradius(@w: 50px,@h: 50px,@mr: 10px,@b-radius: 50%)
            }

            div {
                width: 80%;
                display: flex;
                flex-direction: column;

                span {
                    width: 90%;
                    display: block;
                    .text-over-hidden()
                }

                span:last-child {
                    font-size: 14px;
                    color: #B5B5B5;
                    line-height: 20px;
                }
            }
        }
    }
</style>