<template>
    <router-view v-if="showView"></router-view>
    <div class="task-template-wrap" v-else>
        <title-underline-choice :choices="choices" :choice-index="choiceIndex"
                                @getChoice="makeChoice"></title-underline-choice>
        <div v-if="choiceIndex == 0">
          <div class="no-data">
            暂无数据
          </div>
        </div>
        <div v-if="choiceIndex == 1">
          <orange-option :option-index="chooseCourseIndex" :options="courseList" @getClickOption="clickOption"
                       style="margin-top: 27px;"></orange-option>
          <div class="flex-div" style="margin-top: 27px;justify-content: space-between;">
            <small-title detail-title="作业列表"></small-title>
            <!-- <div class="flex-div get-course-info" v-if="homeworkOrCorrect === 0">
                <el-input placeholder="https://www.dongjinyu.com/"></el-input>
                <div style="margin: 0 30px;" class="get-info-btn" @click="copyLink">复制链接</div>
                <div class="get-info-btn" @click="showQRCode=true">
                    <img src="">
                    <span>二维码</span>
                </div>
            </div> -->
        </div>
        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}"
                  :cell-style="{cursor:'pointer'}" fit
                  highlight-current-row empty-text="暂无数据" style="margin-top: 34px;" @row-click="clickItem">
            <el-table-column v-for="i in columnNum-1" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                             align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <div class="action-class">
                        <i class="el-icon-view" style="color: #2C8EFC;margin-right: 6px;"></i>
                        <span @click.stop="getActionChoice(scope.row,0)">{{actions[0]}}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
        </div>
        <my-masks v-if="showHomeworkDetail" :pos="0" :homework-data="homeworkData"
                  @closeMask="showHomeworkDetail=false"></my-masks>
        <my-masks v-if="showQRCode" :pos="2" :qrcode-info="qrcodeInfo" @closeMask="showQRCode=false"></my-masks>
    </div>
</template>

<script>
    import {GetTaskClassifyList, GetTaskSystemDetail, GetTaskSystemList} from "@/api";
    import {myMasks, orangeOption, smallTitle, titleUnderlineChoice} from "@/components"
    import {getLoading, getPageInfo} from "@/utils";
    import CorrectPage from "@/views/taskSystem/correctPage.vue";

    export default {
        name: "taskSystemTemplate",
        components: {myMasks, orangeOption, smallTitle, titleUnderlineChoice, CorrectPage},
        props: ['homeworkOrCorrect'],  // 0-家长作业   1-作业批改
        data() {
            return {
                choices: ['线上课程', '线下课程'],
                choiceIndex: 0,
                chooseCourseIndex: 0,
                courseList: [],

                columnNum: 2,
                tableTitles: ['作业名称'],
                tableProps: ['title'],
                tableData: [],
                actions: ['预览'],
                pageInfo: {},

                showHomeworkDetail: false,
                homeworkData: {},

                showQRCode: false,
                qrcodeInfo: {
                    tit: '家长作业'
                },
                temp: {}

            }
        },
        mounted() {
            this.GetClassifyList()
        },
        computed: {
            showView() {
                return this.$route.matched.length === 3
            }
        },
        methods: {
            GetClassifyList() {
                GetTaskClassifyList().then(res => {
                    this.courseList = res.data
                    console.log(this.courseList[0].id);
                    this.temp.tag_id = this.courseList[0].id
                    this.getData()
                })
            },
            getData(curPage=1) {
                let that = this
                // let temp = {}
                if (curPage)
                    this.temp['page'] = curPage
                if (that.homeworkOrCorrect === 1) {
                    // that.courseList = [{name: '专业课《家长如何教会孩子学习》'}, {name: '中训'}, {name: '家训'}];
                    // 表格
                    that.columnNum = 3;
                    that.tableTitles = ['作业名称', '状态'];
                    that.tableProps = ['title', 'status'];
                    that.actions = ['查看']
                }
                let loading = getLoading(that, '.task-template-wrap')
                GetTaskSystemList(that.homeworkOrCorrect, {...this.temp}).then((res) => {
                    if (res.data.page_data && res.data.page_data .length) {
                      if (that.homeworkOrCorrect === 1) {
                        res.data.page_data.forEach((item, index) => {
                            item['status'] = item.correcting.enable ? '已批改' : '未批改'
                        })
                      }
                      that.tableData = res.data.page_data
                      that.pageInfo = getPageInfo(res.data)
                    }
                    loading.close()
                })
            },
            changePageIndex(curPage) {
                this.getData(curPage)
            },

            makeChoice(i) {
                // todo 考虑添加到路由
                if (this.choiceIndex !== i) {
                    this.choiceIndex = i  // 线上作业或者线下作业，重新赋值
                }
            },

            clickOption(i, id) {
                this.tableData = []
                this.pageInfo = {}
                this.chooseCourseIndex = i;
                this.temp.tag_id = id
                this.getData()
            },

            copyLink() {
                this.$message.success(`复制链接`)
            },

            clickItem(row) {
                this.getActionChoice(row)
            },

            getActionChoice(item, ai) {
                let that = this;
                if (that.homeworkOrCorrect === 0) {
                    GetTaskSystemDetail({id: item.id, mode: 'preview'}).then((res) => {
                        that.showHomeworkDetail = true;
                        that.homeworkData = res.data
                        that.homeworkData['top'] = '预览作业'
                    })
                } else {
                    that.$router.push({name: 'correctPage', params: {id: item.id, parents_id: item.parents_id}})
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .task-template-wrap {
        .shadow-border-bgcolor();
        padding: 32px 16px 100px 40px;

        .get-course-info {

            .my-el-input(@w: 347px,@h: 36px,@fontcolor: '#757575',@backgroundcolor:'#F0F0F0');

            .get-info-btn {
                width: 111px;
                height: 36px;
                line-height: 36px;
                background: #2C8EFC;
                border-radius: 5px;
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .no-data {
      height: 200px;
      line-height: 200px;
      text-align: center;
    }
</style>