<template>
    <div>
        <div class="flex-div new-tag-module">
            <span>标签名称</span>
            <span class="required-option">*</span>
            <el-input v-model="newTag" placeholder="请填写标签名称"></el-input>
        </div>
        <div class="btn-div" @click="saveNewTag">保存</div>
    </div>
</template>

<script>
    import {AddOrEditUserTag} from "@/api";

    export default {
        name: "createTag",
        data() {
            return {
                newTag: ''
            }
        },
        mounted() {
            if (this.$route.params.edit === '1')
                this.newTag = this.$store.state.editTag.name
        },
        methods: {
            // 保存新建标签
            saveNewTag() {
                let that = this
                let temp = {name: that.newTag}
                if (that.$route.params.edit) {
                    temp['id'] = that.$store.state.editTag.id
                }
                AddOrEditUserTag(temp).then((res) => {
                    that.$message.info(res.data)
                    if(that.$store.state.userTags){
                        that.$store.commit('setUserTags',[])
                    }
                    that.$router.push('/tagManage')
                }).catch(err => {
                    that.$message.warning(err.msg)
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .new-tag-module {
        margin: 76px 0 130px;

        span:first-child {
            color: #545454;
        }

        .my-el-input(@h: 40px,@fontcolor:#BDBDBD,@backgroundcolor:'#FAFAFA');
    }

    .btn-div {
        margin-left: 180px;
    }
</style>