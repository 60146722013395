<template>
    <div id="my-chart" :style="{width:chartWidth+'%',height:chartHeight+'px'}"></div>
</template>

<script>
    import * as echart from "echarts"
    import {initChart} from "@/utils";

    export default {
        name: "myChart",
        props: ['chartWidth', 'chartHeight', 'seriesData'],
        mounted() {
            let that = this
            that.$nextTick(() => {
                setTimeout(()=>{
                    initChart(that,that.seriesData)
                },200)
            })
        },
        methods: {}
    }
</script>

<style lang="less" scoped>
    #my-chart {
        width: 700px;
        height: 300px;
    }
</style>