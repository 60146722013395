<template>
    <div class="permission">
        <title-underline-choice :choices="choices" :choice-index=choiceIndex
                                @getChoice="makeChoice"></title-underline-choice>
        <router-view ></router-view>
    </div>
</template>

<script>
    import {titleUnderlineChoice} from "@/components";

    export default {
        name: "conflict",
        components: {titleUnderlineChoice},
        data() {
            return {
                choices: ['权限申请', '冲突申诉'],
                choiceIndex: 1
            }
        },
        methods: {
            makeChoice(i) {
                if(this.choiceIndex !== i){
                    this.$router.push('/apply')
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./style/permissionCommon";
    .result-div {
        margin-top: 26px;
        padding-top: 15px;
        padding-left: 20px;
        padding-bottom: 30px;
        box-sizing: border-box;
        width: 800px;
        // height: 270px;
        background: #F2F5F8;

        .result-item {
            margin-top: 16px;
            color: #404040;
        }

        .img-item {
            position: relative;
            width: 100px;
            height: 100px;
            border-radius: 8px;
            background: #fff;
            margin-right: 20px;
            margin-top: 20px;

            .icon-img {
                position: absolute;
                height: 20px;
                top: -10px;
                right: -10px;
            }
        }

        .camera {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ccc;
        }
    }

    .btn-div {
        margin-top: 25px;
        margin-left: 80px;
    }

    .el-row /deep/.el-button,
    .el-row /deep/.el-button+.el-button {
      width: 100px;
      margin-left: 80px;
      margin-top: 40px;
    }

    .desc {
      width: 600px;
      height: 110px;
      background: #fff;
      padding: 15px;
      resize: none;
      outline: none;
      border: none;
      border-radius: 10px;
      margin-top: 20px;
    }

    /deep/.el-button {
      width: 225px;
      margin-top: 30px;
      margin-left: 100px;
    }
</style>