<template>
    <router-view v-if="showView"></router-view>
    <div class="tutorial-wrap" v-else>
        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'16px'}"
                  :cell-style="{cursor:'pointer'}" fit
                  highlight-current-row empty-text="暂无数据" style="font-size:16px;color: #666666;"
                  @row-click="clickTableRow">
            <el-table-column label="图标">
                <template #default="scope">
                    <div>
                        <img style="width: 74px;height: 42px;border-radius:5%" :src="scope.row.img">
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="tableTitles[0]" :prop="tableProps[0]" width="700"></el-table-column>
            <el-table-column :label="tableTitles[1]" :prop="tableProps[1]" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <div>
                        <span class="action-span" @click.navite.stop="getActionChoice(scope.row)">打开</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
    </div>
</template>

<script>
    import {GetLiveData} from "@/api";
    import {getLoading, getPageInfo} from "@/utils";

    export default {
        name: "liveTraining",
        data() {
            return {
                columnNum: 3,
                tableTitles: ['直播标题', '开始时间'],
                tableProps: ['title', 'start_time'],
                tableData: [],
                pageInfo: {},
            }
        },
        computed: {
            showView() {
                return this.$route.matched.length === 3
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData(curPage) {
                let that = this
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                let loading = getLoading(that, '.tutorial-wrap')
                GetLiveData({...temp}).then((res) => {
                    that.tableData = res.data.page_data
                    that.pageInfo = getPageInfo(res.data)
                    loading.close()
                })
            },

            changePageIndex(curPage) {
                this.getData(curPage)
            },

            clickTableRow(row) {
                this.getActionChoice(row)
            },

            getActionChoice(item) {
                let routeUrl = this.$router.resolve({name: 'livingDetail', params: {id: item.id}})
                // let routeUrl = this.$router.resolve({name: 'livingDetail', params: {id: 138}})
                window.open(routeUrl.href, '_blank')
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./style/tutorialCommon";

    .action-span {
        display: inline-block;
        padding: 9px 24px;
        border-radius: 5px;
        background-color: #4C88FF;
        color: #ffffff;
        cursor: pointer;
    }
</style>