<template>
    <div>
        <analysisi-chart title="销售概况及趋势" :statistics="statistics" :chart-data="chartData"
                         @confirmDate="confirmDate"></analysisi-chart>
        <div class="analysis-table-wrap">
            <small-title detail-title="地区顾问销售统计" style="margin-bottom: 38px;"></small-title>
            <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}" fit
                      highlight-current-row empty-text="暂无数据" @sort-change="sortColumn">
                <el-table-column v-for="i in titles.length" :key="i" :label="titles[i-1]" :prop="propName[i-1]"
                                 align="center" :sortable="i===1?false:'custom'"></el-table-column>
            </el-table>
            <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                           :page-size="pageInfo.per_page"
                           :page-count="pageInfo.last_page" :current-page="pageInfo.current_page"
                           :total="pageInfo.total"
                           class="page-div" @current-change="changePageIndex"></el-pagination>
        </div>
    </div>
</template>

<script>
    import {myTable, smallTitle, analysisiChart} from "@/components";
    import {getLoading, getPageInfo} from "@/utils";
    import {processSalesData,getConfirmDate} from "@/utils/dataProcess";
    import {GetAreaSalesTable} from "@/api";

    export default {
        name: "areaDataSalesAnalysis",
        components: {myTable, smallTitle, analysisiChart},
        data() {
            return {
                statistics: [
                  {typename: '付费用户', num: 0},
                  {typename: '下单用户', num: 0},
                  {typename: '订单数', num: 0},
                  {typename: '销售额', num: 0},
                  {typename: '总销售额', num: 0}
                ],
                chartData: [],

                titles: ['顾问姓名', '会员订单数', '专栏订单数', '商城订单数', '订单金额'],
                propName: ['name', 'member', 'column', 'shop', 'amount'],
                tableData: [],
                pageInfo: {}
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            // 按照某列排序
            sortColumn(column) {
                console.log(column.order)
                console.log(column.prop)
                // 将需要排序的信息以【全局变量】形式展示  比如切换页码仍然会伴随着排序信息
                // this.getData()
            },

            // 获取数据
            getData() {
                let that = this
                let loading = getLoading(that, '.analysis-wrap')
                Promise.all([processSalesData(that, {is_area: that.$store.state.isAreaAdmin}), that.getTableData()]).then((res) => {
                    loading.close()
                }).catch((e) => {
                    loading.close()
                })
            },

            // 获取表格数据
            getTableData(curPage) {
                let that = this
                let temp = {}
                Object.assign(temp,that.$store.state.recentMonthDate)
                let loading;
                if (curPage) {
                    temp["page"] = curPage
                    loading = getLoading(that, '.analysis-table-wrap')
                }
                return new Promise(function (resolve, reject) {
                    GetAreaSalesTable(temp).then((res) => {
                        that.tableData = res.data.page_data
                        that.pageInfo = getPageInfo(res.data)
                        if (loading) loading.close()
                        resolve()
                    }).catch((e) => {
                        if (loading) loading.close()
                        reject()
                    })
                })
            },

            // 时间选择
            confirmDate(date) {
                let loading = getLoading(this, '.analysis-chart-wrap')
                processSalesData(this, {
                    is_area: this.$store.state.isAreaAdmin,
                    ...getConfirmDate(this, date)
                }, loading)
            },

            // 切换页码
            changePageIndex(curPage) {
                this.getTableData(curPage)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";
</style>

