<template>
    <div class="resource-wrap" v-if="!showView">
        <title-underline-choice :choice-index=2 :choices=choices @getChoice="makeChoice"></title-underline-choice>
        <div class="flex-div filter-creation-giving-course">
            <div @click="toCreate" class="event-div">
                <img alt="" src="../../assets/icon_zengsong.png">
                <span>赠送记录</span>
            </div>
            <div class="search-div">
                <el-input placeholder="输入素材名称" v-model="searchKeyword"></el-input>
                <el-button @click="getData">搜索</el-button>
            </div>
        </div>
        <my-table :column-num=columnNum :table-data=tableData :table-props=tableProps :table-titles=tableTitles
                  :actions="actions" :page-info="pageInfo" :slots="slots" @makeActionChoice="getActionChoice" @pageIndexChange="changePageIndex"></my-table>
    </div>
    <router-view v-else></router-view>
    <my-masks v-if="showGivingQRCode" :pos="2" :qrcode-info="qrcodeInfo" @closeMask="showGivingQRCode=false"></my-masks>
</template>

<script>
    import {GetGivingCourse} from "@/api";
    import {myMasks,titleUnderlineChoice,myTable,} from "@/components";
    import {getLoading, getPageInfo} from "@/utils";

    export default {
        name: "givingCourses",
        components: {myMasks,titleUnderlineChoice,myTable,},
        data() {
            return {
                choices:['推广好文','推广海报','赠送课程','我的创作'],
                // 搜索关键字
                searchKeyword: '',
                // 表格
                columnNum: 2,
                tableTitles: ['添加时间'],
                tableProps: ['add_time'],
                tableData: [],
                actions:['立即赠送'],
                pageInfo:{},
                slots:{name:'课程',i:0,w:800},
                // 二维码
                showGivingQRCode:false,
                qrcodeInfo:{
                    tit:'赠送课程',
                }
            }
        },
        computed:{
            showView(){
                return this.$route.matched.length === 3;
            }
        },
        mounted(){
            this.getData()
        },
        methods: {
            getData(curPage){
                let that = this
                let loading = getLoading(that,'.resource-wrap')
                let temp = {}
                if(curPage)
                    temp['page'] = curPage
                if(that.searchKeyword)
                    temp['title'] = that.searchKeyword
                GetGivingCourse({...temp}).then((res => {
                    if (res.data.page_data && res.data.page_data .length) {
                      that.tableData = res.data.page_data
                      that.pageInfo = getPageInfo(res.data)
                    }
                    loading.close()
                }))
            },

            changePageIndex(curPage){
                this.getData(curPage)
            },

            makeChoice(i) {
                this.$router.push(this.$router.options.routes[3].children[i].path)
            },

            getActionChoice(item, ai) {
                this.showGivingQRCode = true
                this.qrcodeInfo.url = `https://mini.dongjinyu.com/wechat/personal_office_v2/giving_course_prompt?id=${item.id}`
                // this.$message.info('暂无数据')
            },

            toCreate() {
                this.$router.push('/givingRecords')
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "style/resourceCenterCommon";
    .event-div {
        .event-btn-div(@backgroundcolor: #F55858,@bordercolor: #FF0000);
    }
</style>