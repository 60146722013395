import {add0, getLoading, getLocalStorage} from "@/utils";
import {createStore} from 'vuex';
import IMstore from "@/store/IMstore";

export default createStore({
    state: {
        token: getLocalStorage('token'),
        loginUserinfo: JSON.parse(getLocalStorage('loginUserinfo')),
        isAreaAdmin: 0,            // 是否是地区管理员
        breadCrumbList: [],    // 顶部面包屑列表
        breadCrumbNameArr: [],  // 面包屑名字数组,用户判断路由在子元素位置时，sidebar的选中状态
        articleTagList: [],    // 素材中心 推广好文的标签列表
        posterTagList: [],     // 素材中心 推广海报的标签列表
        promotionCodeUrl: '',  // 素材中心 推广好文 我的创作 - 立即推广链接
        editCreation: {},      // 素材中心 我的创作  需要修改的创作信息
        editLiveCourse: {},    // 地区服务 需要修改的直播课信息
        editTag: {},           // 需要修改的标签对象
        userTags: [],   // 用户标签
        services: {},   // 权限申请 - 服务课  用户筛选 - 服务课筛选
        uploadUrl: 'https://api.dongjinyu.com/common/file/upload',
        myChart: '',  // 全局图表实例
        recentMonthDate: {},  // 最近一个月时间,
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setLoginUserinfo(state, loginUserinfo) {
            state.loginUserinfo = loginUserinfo
        },
        setIsAreaAdmin(state, v) {
            state.isAreaAdmin = v ? 1 : 0
        },
        setBreadCrumbList(state, arr) {
            state.breadCrumbList = arr
        },
        setBreadCrumbNameArr(state, arr) {
            state.breadCrumbNameArr = arr
        },
        setArticleTagList(state, tl) {
            state.articleTagList = tl
        },
        setPosterTagList(state, tl) {
            state.posterTagList = tl
        },
        setSomeInfo(state, item) {
            state.promotionCodeUrl = item.link_material
            state.services = item.service_level
        },
        setEditCreation(state, item) {
            state.editCreation = item
        },
        setEditLiveCourse(state,item){
            state.editLiveCourse = item
        },
        setEditTag(state, tag) {
            state.editTag = tag
        },
        setUserTags(state, tags) {
            state.userTags = tags
        },
        setRecentMonthDate(state) {
            let date = new Date()
            state.recentMonthDate.end_time = date.getFullYear() + "-" + add0(date.getMonth() + 1) + "-" + add0(date.getDate())
            let date1 = new Date(date - 1000 * 60 * 60 * 24 * 30)
            state.recentMonthDate.start_time = date1.getFullYear() + "-" + add0(date1.getMonth() + 1) + "-" + add0(date1.getDate())
        },
    },
    // 异步 commit
    actions: {
        setSomeInfo(state, item) {
            state.commit('setSomeInfo', item)
        },
    },
    modules: {
        IMstore
    }
})
