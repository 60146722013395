<template>
    <div class="tutorial-wrap">
        <!--<orange-option :option-index=optionIndex :options=options @getClickOption="clickOption" style="margin-bottom: 24px;"></orange-option>-->
        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'16px'}" fit
                  highlight-current-row empty-text="暂无数据" style="font-size:16px;color: #666666;">
            <el-table-column :label="tableTitles[0]">
                <template #default="scope">
                    <span style="color: #FF4444">{{scope.row.type == 0 ? '【系统更新】' : item.type == 1 ? '【内部培训】' : ''}}</span>
                    <span style="cursor: pointer" @click="clickItem(scope.row.id)">{{scope.row.title}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="tableTitles[1]" :prop="tableProps[1]"
                             align="center"></el-table-column>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
        <my-masks v-if="showAnnouceDetail" :pos="7" :announce-detail="announceDetail"
                  @closeMask="showAnnouceDetail=false"></my-masks>
    </div>
</template>

<script>
    import {GetAnnouncement, GetAnnouncementDetail} from "@/api";
    import {myMasks, orangeOption,} from "@/components";
    import {getPageInfo} from "@/utils";

    export default {
        name: "announcement",
        components: {myMasks, orangeOption},
        data() {
            return {
                optionIndex: 0,
                // options: ['全部', '系统更新', '内部培训', '消息提醒'],
                options: [{name: '全部'}, {name: '系统更新'}, {name: '内部培训'}, {name: '消息提醒'}],
                // 表格
                tableTitles: ['标题', '发布时间'],
                tableProps: ['title', 'add_time'],
                tableData: [],
                pageInfo: {},
                // 公告详情
                showAnnouceDetail: false,
                announceDetail: {},
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            clickOption(i) {
                this.optionIndex = i;
            },
            getData(curPage) {
                let that = this
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                GetAnnouncement({...temp}).then((res => {
                  console.log(res);
                    that.tableData = res.data.page_data
                    that.pageInfo = getPageInfo(res.data)
                }))
            },
            changePageIndex(curPage) {
                this.getData(curPage)
            },
            clickItem(id) {
                let that = this
                GetAnnouncementDetail({id: id + ''}).then((res) => {
                    that.announceDetail = res.data
                    that.showAnnouceDetail = true
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./style/tutorialCommon";
</style>