<template>
    <div>
        <router-view/>
        <chat-index v-if="flag"></chat-index>
    </div>
</template>

<script>
    import ChatIndex from "@/views/chat/chatIndex.vue";

    export default {
        components: {ChatIndex},
        data() {
            return {
                flag: false,
            }
        },
        watch: {
            '$route'(newRoute, oldRoute) {
                if (newRoute !== oldRoute) {
                    // let disableArr = ['/','/login','/livingDetail','/chatRoom','/m3u8'];
                    // let disableArr = ['/livingDetail','/chatRoom','/m3u8'];
                    // this.flag = newRoute.path !== "/" && newRoute.path !== "/login" && disableArr.indexOf(newRoute.path) !== -1
                    this.flag = newRoute.path !== "/" && newRoute.path !== "/login" && newRoute.path.indexOf('/livingDetail') === -1 && newRoute.path.indexOf('/chatRoom') === -1 && newRoute.path.indexOf('/m3u8') === -1
                    this.$store.commit('IMstore/setIMOpen', this.flag)
                    // this.flag = !newRoute.meta.hideChat
                }
            }
        },
        mounted() {
            // console.log(this.$route)
            // console.log(this.$route.meta.hideChat)
            // console.log(this.$route.meta.hideChat !== true)
            //
            // this.flag = this.$route.meta.hideChat !== true
            // console.log(this.flag)
        },
        mounted() {
            this.flag = this.$route.path !== "/" && newRoute.path !== "/login"
            this.$store.commit('IMstore/setIMOpen', this.flag)
        },
    }
</script>

<style lang="less">
    @import "styles/common";

    #app {
        font-family: RegularFont;
        font-size: @font-size-default;
        color: @font-color-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /** todo   need  权限问题   【已 add commit,但没Push】
         1. 图标 tabBar 缺少 icon0_unselected  icon1_selected   myMask.vue中缩小图标缺少（和放大图标切换,已经有一个，但不好看，不对应）
         3. iconfont库图标 代码方式 使用
         素材中心 - 赠送课程 - 赠送记录 - 已下架课程没有图片，设置默认的占位图,后端还是前端
         素材中心 - 我的创作 - 我要创作 -  (设置不启用目前仍然出现在 我的创作 列表中)
         销售分析和会员分析  用户数和销售额在一个折线图上合理吗 （dataProcess.js）
         如果对用户添加标签需要多选，则需要修改poppver
         IM - 离线到在线 新消息气泡

         n.注：s的问题  methods  component  components   for length 的计算次数  自定义组件不可以“递归”调用（引用）  object直接赋值（深拷贝）
    **/
</style>
