<template>
    <commodity-page :choice-index="1"></commodity-page>
</template>

<script>
    import CommodityPage from "./components/commodityPage.vue";
    export default {
        name:'physicalCommodity',
        components: {CommodityPage},
    }
</script>