<template>
    <div class="study-data">
        <el-table :data="tableData" style="margin-top: 25px;"
                  :header-cell-style="{background:'#F6F6F6',color:'#181818',fontWeight:'bold'}" fit
                  highlight-current-row class="study-table">
            <el-table-column v-for="(v,i) in tableTitles" :key="i" :label="v" align="center">
                <template #default="scope">
                    <span style="font-size: 48px;line-height:48px;color: #292929;font-family: MediumFont;">{{scope.row[tableProps[i]]}}</span>
                    <span style="font-size: 18px;">{{unitArr[i]}}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="title-div flex-div study-chart" style="justify-content: space-between;">
            <small-title detail-title="学习时长趋势图"></small-title>
            <div class="title-div-r flex-div">
                <div @click="recentDate(7)">近7天</div>
                <div @click="recentDate(30)">近30天</div>
                <el-date-picker
                        v-model="pickDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期" :unlink-panels="true" value-format="YYYY-MM-DD" @change="dateChange">
                </el-date-picker>
            </div>
        </div>
        <div id="my-chart" style="width: 100%;height: 360px"></div>
        <!--<my-chart :chart-width=100 :chart-height=360></my-chart>-->
    </div>
</template>

<script>
    import {getUserDetailData} from "@/api";
    import {myChart, smallTitle} from "@/components";
    import {add0, getLoading, initChart} from "@/utils";

    export default {
        name: "learningData",
        components: {myChart, smallTitle},
        data() {
            return {
                columnNum: 4,
                tableTitles: ['今日学习时长', '累计学习时长', '连续学习天数', '完成课程节数'],
                tableProps: ['today', 'total', 'days', 'complete_section'],
                unitArr: ['分钟', '分钟', '天', '节'],
                tableData: [],
                // 时间筛选
                pickDate: '',
                chartData: {},
            }
        },
        watch: {
            chartData(newV, oldV) {
                let that = this
                that.$nextTick(() => {
                    initChart(that, newV)
                })
            }
        },
        mounted() {
            let that = this;
            let loading = getLoading(that, '.study-data')
            that.getData(loading)
        },
        methods: {
            getData(loading, time) {
                let that = this
                getUserDetailData(1, {...that.$route.params, ...time}).then((res) => {
                    that.tableData[0] = res.data.overview
                    that.processStudyChart(res.data.chart)
                    loading.close()
                }).catch((e) => {
                    that.pickDate = ''
                    that.$message.info(e.msg)
                    loading.close()
                })
            },
            // 处理学习时长趋势图
            processStudyChart(data) {
                let that = this
                let temp = {xAxisData: [], series: []}
                temp.series = [{
                    name: '',
                    data: []
                }]
                data.forEach((item, index) => {
                    temp.xAxisData[index] = item.date
                    temp.series[0].data[index] = item.data
                })
                that.chartData = temp
            },

            // 最近 7 / 30 天数据
            recentDate(i) {
                let date = new Date()
                let date1 = new Date(date - 1000 * 60 * 60 * 24 * i)
                let end_time = date.getFullYear() + "-" + add0(date.getMonth() + 1) + "-" + add0(date.getDate())
                let start_time = date1.getFullYear() + "-" + add0(date1.getMonth() + 1) + "-" + add0(date1.getDate())
                let loading = getLoading(this, '.study-data')
                this.getData(loading, {start_time: start_time, end_time: end_time})
            },

            // 日期选择
            dateChange() {
                if (this.pickDate) {
                    let loading = getLoading(this, '.study-data')
                    this.getData(loading, {start_time: this.pickDate[0], end_time: this.pickDate[1]})
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .title-div {
        margin: 45px 0 20px 0;

        .title-div-r {
            div:nth-child(1),
            div:nth-child(2) {
                width: 80px;
                height: 30px;
                line-height: 30px;
                background: #FFFFFF;
                border: 1px solid #E5E5E5;
                border-radius: 4px;
                text-align: center;
                font-size: 14px;
                color: #818181;
                cursor: pointer;
            }

            div:nth-child(2) {
                margin: 0 40px 0 30px;
            }
        }
    }
</style>