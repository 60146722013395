<template>
    <div class="flex-div orange-option-wrap">
        <span v-for="(op,i) in options" :key="op.id" @click="chooseOption(i,op.id)"
              :class="oIndex === i ? 'active-option-span' : ''">{{op.name}}</span>
    </div>
</template>

<script>
    export default {
        name: "orangeOption",
        props: {
            options: Array,
            optionIndex: 0
        },
        data() {
            return {
                oIndex: 0,
            }
        },
        mounted() {
            this.oIndex = this.optionIndex;
        },
        watch: {
            optionIndex(newValue, oldValue) {
                if (newValue !== oldValue)
                    this.oIndex = newValue
            }
        },
        methods: {
            chooseOption(i, id) {
                this.$emit('getClickOption', i, id);
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .orange-option-wrap {
        flex-wrap: wrap;

        span {
            margin: 22px 33px 0 0;
            padding: 4px 22px;
            border: 1px solid #D2D2D2;
            border-radius: 5px;
            color: #717171;
            cursor: pointer;
        }

        .active-option-span {
            background: #FFF4E6;
            border-color: #FFC780;
            color: #EA7D26;
        }
    }
</style>