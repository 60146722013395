<template>
    <div class="tutorial-wrap">
        <div class="top" style="margin-bottom: 26px;">教程数量：{{tableData.length}}</div>

        <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}"
                  :cell-style="{cursor:'pointer'}" fit
                  highlight-current-row empty-text="暂无数据" @row-click="clickItem">
            <el-table-column v-for="i in columnNum-1" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                            ></el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <div class="action-class">
                        <span @click.stop="getActionChoice(scope.row,0)">{{actions[0]}}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>

        <my-masks v-if="showDetail" :pos="0" :homework-data="detailData" @closeMask="showDetail=false"></my-masks>
    </div>
</template>

<script>
    import {GetVideoCourse, GetVideoCourseDetail} from "@/api";
    import {myTable, myMasks} from "@/components";
    import {getPageInfo} from "@/utils";

    export default {
        name: "graphicTutorials",
        components: {myMasks, myTable},
        data() {
            return {
                columnNum: 3,
                tableTitles: ['标题', '添加时间'],
                tableProps: ['title', 'add_time'],
                tableData: [],
                actions: ['查看'],
                pageInfo: {},

                showDetail: false,
                detailData: {}
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData(curPage) {
                let that = this
                let temp = {}
                if (curPage) {
                    temp['page'] = curPage
                }
                GetVideoCourse({...temp}).then((res) => {
                    that.tableData = res.data.page_data
                    that.pageInfo = getPageInfo(res.data)
                })
            },
            changePageIndex(curPage) {
                this.getData(curPage)
            },

            clickItem(row) {
                this.getActionChoice(row)
            },
            getActionChoice(item, ai) {
                let that = this
                GetVideoCourseDetail({id: item.id}).then((res) => {
                    that.detailData = res.data
                    that.detailData['top'] = '详情'
                    that.showDetail = true
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./style/tutorialCommon";

    .top {
        position: relative;
        padding-left: 28px;
        height: 50px;
        text-align: left;
        background-color: #F2F5F8;
        line-height: 50px;
        font-size: 16px;
        color: #4D4D4D;
    }

    .top::before {
        position: absolute;
        content: '';
        left: 0;
        width: 4px;
        height: 50px;
        background: #4C88FF;
    }
</style>