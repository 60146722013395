<template>
    <div class="flex-div">{{detailTitle}}</div>
</template>

<script>
    export default {
        name: "smallTitle",
        props:['detailTitle']
    }
</script>

<style lang="less" scoped>
    div{
        margin-left: 15px;
        position: relative;
        font-size: 23px;
        color: #2C8EFC;
        justify-content: flex-start;
    }
    div::before{
        position: absolute;
        content: '';
        left: -15px;
        width: 4px;
        height: 21px;
        background: #2C8EFC;
        border-radius: 2px;;
    }
</style>