<template>
    <div>
        <el-table v-if="tableData" :data="tableData" :cell-style="{cursor: cursor}" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'14px'}" fit
                  highlight-current-row empty-text="暂无数据" @row-click="clickItem">

            <el-table-column v-if="slots" :label="slots.name" :width="slots.w">
                <template #default="scope">
                    <table-slots :i="slots.i" :columnData="scope.row"></table-slots>
                </template>
            </el-table-column>

            <template v-if="!actions">
                <el-table-column label="用户">
                    <template #default="scope">
                        <div class="resource-item">
                            <img class="avatar" :src="scope.row.user.icon" alt="">
                            {{scope.row.user.name}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-for="i in columnNum" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                                 align="center"></el-table-column>
            </template>
            <template v-else-if="isUserDetail">
                <el-table-column v-for="i in columnNum" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                                 align="center"></el-table-column>
            </template>
            <template v-else>
                <el-table-column v-for="i in columnNum-1" :key="i" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                                 align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <div class="action-class">
                            <span @click="chooseAction(scope.row,0)">{{actions[0]}}</span>
                            <template v-if="actions.length > 1">
                              <template v-for="j in actions.length-1" :key="j">
                                <span class="line">|</span>
                                <span @click="chooseAction(scope.row,j)">{{actions[j]}}</span>
                              </template>
                            </template>
                        </div>
                    </template>
                </el-table-column>
            </template>
        </el-table>
        <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                       :page-size="pageInfo.per_page"
                       :page-count="pageInfo.last_page" :current-page="pageInfo.current_page" 
                       :total="pageInfo.total"
                       class="page-div" @current-change="changePageIndex"></el-pagination>
    </div>
</template>
<script>
    import TableSlots from "@/components/tableSlots.vue";
    export default {
        name: "myTable",
        components: {TableSlots},
        props: ['tableTitles', 'tableProps', 'columnNum', 'tableData', 'actions', 'pageInfo', 'slots', 'isUserDetail', 'cursor'],

        methods: {
            chooseAction(item, ai) {
                // this.$message.success(`${this.actions[ai]}`)
                this.$emit('makeActionChoice', item, ai)
            },
            changePageIndex(c){
                this.$emit('pageIndexChange',c)
            },
            clickItem(row) {
                this.$emit('getDetail', row.cabinet_id)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";
    .resource-item {
      display: flex;
      align-items: center;

      .avatar {
        width: 40px;
        border-radius: 50%;
        margin-right: 15px;
      }
    }
</style>