<template>
    <analysisi-chart title="会员概况及趋势" :statistics="statistics" :chart-data="chartData"
                     @confirmDate="confirmDate"></analysisi-chart>
</template>

<script>
    import {analysisiChart} from "@/components";
    import {getLoading} from "@/utils";
    import {processVipChartData,getConfirmDate} from "@/utils/dataProcess";

    export default {

        name: "areaDataVipAnalysis",
        components: {analysisiChart},
        data() {
            return {
                statistics: [{typename: '累计会员数', num: 0}, {typename: '新增会员数', num: 0}, {
                    typename: '活跃会员数',
                    num: 0
                }, {typename: '过期会员数', num: 0}, {typename: '会员销售额', num: 0}],
                chartData: []
            }
        },
        mounted() {
            this.getChartData()
        },
        methods: {
            // 获取折线数据
            getChartData(date) {
                let that = this
                let loading = getLoading(that, '.analysis-chart-wrap')
                processVipChartData(that, {is_area: that.$store.state.isAreaAdmin, ...date}, loading)
            },

            // 时间选择
            confirmDate(date) {
                this.getChartData(getConfirmDate(this, date))
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";
</style>
