<template>
    <div class="analysis-chart-wrap">
        <div class="title-div">
            <small-title :detail-title="title"></small-title>
            <div class="time-select-div">
                <span>时间筛选</span>
                <el-date-picker
                        v-model="pickDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期" :unlink-panels="true" value-format="YYYY-MM-DD" @change="dateChange">
                </el-date-picker>
            </div>
        </div>
        <div class="data-list-div" v-if="statistics">
            <div v-for="(item, i) in statistics" :key="i" class="data-item">
                <span>{{item.typename}}</span>
                <span>{{item.num}}</span>
            </div>
        </div>
        <div id="my-chart" style="width: 100%;height: 360px"></div>
        <!--<my-chart :chart-width=100 :chart-height=360 :series-data="dataStatistic.chart"></my-chart>-->
    </div>
</template>

<script>
    import smallTitle from "./smallTitle";
    import {initChart} from "@/utils";

    export default {
        name: "analysisiChart",
        components: {smallTitle},
        props: ['title', 'statistics', 'chartData'],
        data() {
            return {
                pickDate: '',
            }
        },
        watch: {
            chartData: {
                deep: true,
                handler(val) {
                  let that = this
                  console.log(val);
                  if (document.querySelector('#my-chart')) {
                    initChart(that, val)
                  }
                }
            }
        },
        mounted() {
            // console.log(this.chartData);
            // let that = this
            // that.$nextTick(() => {
            //     initChart(that, that.chartData)
            // })
        },
        methods: {
            dateChange(e) {
                let that = this
                that.pickDate = e
                that.$emit('confirmDate', e)
            }
        }
    }
</script>

<style lang="less" scoped>
    #my-chart {
        width: 700px;
        height: 300px;
    }

    .analysis-chart-wrap {
        margin: 30px auto;

        .title-div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time-select-div span:first-child {
                margin-right: 25px;
            }

            .time-select-div ::v-deep(.el-date-editor) {
                width: 360px;
                height: 40px;
            }
        }

        .data-list-div {
            margin: 40px auto;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .data-item {
                display: flex;
                flex-direction: column;
                align-items: center;

                span:first-child {
                    font-size: 18px;
                    color: #717171;
                }

                span:last-child {
                    font-size: 55px;
                    color: #2C2C2C;
                    /*font-family: Alibaba PuHuiTi;*/
                }
            }
        }
    }
</style>