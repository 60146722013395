<template>
    <div>
        <router-view v-if="$route.meta.title === '新建标签'"></router-view>
        <template v-else>
            <div class="flex-div create-btn" @click="toCreateNewTag(0)">
                <img src="@/assets/icon_new_tag.png" alt="">
                <span>新建标签</span>
            </div>
            <el-table :data="tableData" :header-cell-style="{background:'#F6F6F6',color:'#181818',fontSize:'16px'}" fit
                      highlight-current-row empty-text="暂无数据" style="font-size:16px;color: #666666;">
                <el-table-column v-for="(i, index) in columnNum-1" :key="index" :label="tableTitles[i-1]" :prop="tableProps[i-1]"
                                 align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <div class="action-class">
                            <span style="cursor: pointer" @click="getActionChoice(scope.row,0)">{{actions[0]}}</span>
                            <span class="line">|</span>
                            <el-popconfirm title="确定删除？" @confirm="confirmDelete(scope.row)">
                                <template #reference>
                                <span style="cursor: pointer"
                                      @click="getActionChoice(scope.row,1)">{{actions[1]}}</span>
                                </template>
                            </el-popconfirm>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination v-if="pageInfo" background hide-on-single-page layout="prev, pager, next, jumper"
                           :page-size="pageInfo.per_page"
                           :page-count="pageInfo.last_page" :current-page="pageInfo.current_page"
                           :total="pageInfo.total"
                           class="page-div" @current-change="changePageIndex"></el-pagination>
        </template>
    </div>
</template>

<script>
    import {myTable, titleUnderlineChoice} from "@/components";
    import {DelUserTag, GetUserTagList} from "@/api";
    import {getPageInfo, setLocalStorage} from "@/utils";

    export default {
        name: "标签管理",
        components: {titleUnderlineChoice, myTable},
        data() {
            return {
                editItem: {},
                // 表格
                columnNum: 4,
                tableTitles: ['标签名字', '标签人数', '创建时间'],
                tableProps: ['name', 'user_count', 'add_time'],
                tableData: [],
                actions: ['编辑', '删除'],
                pageInfo: '',
                // 新建的标签
                newTag: '',
            }
        },
        computed: {
            // showCreate() {   // 是否显示新建标签模块
            //     return this.$route.meta.title === '新建标签'
            // }
        },
         created() {
            this.initData()
        },
        methods: {
            // 获取标签数据
            getData(curPage) {
                let that = this
                let temp = {}
                if (curPage)
                    temp['page'] = curPage
                GetUserTagList(temp).then((res) => {
                    if (res.data.page_data && res.data.page_data .length) {
                        that.tableData = res.data.page_data
                        that.pageInfo = getPageInfo(res.data)
                    }
                })
            },

            // 点击新建标签   i : 0 - 新建标签  1 - 标签修改
            toCreateNewTag(i) {
                if(i === 1)
                    this.$store.state.editTag = this.editItem
                this.$router.push({name: 'createTag', params: {edit: i}})
            },
            // 操作
            getActionChoice(item, ai) {
                let that = this
                if (ai === 0) {
                    that.editItem = item
                    this.toCreateNewTag(1)
                }
            },

            // 确认删除
            confirmDelete(item) {
                let that = this
                DelUserTag({id: item.id}).then((res) => {
                    that.$message.info(res.data)
                    that.getData()
                })
            },

            // 页码改变
            changePageIndex(curPage) {
                this.getData(curPage)
            },

            // 初始化数据
            initData() {
                this.editItem = {}
                this.getData()
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "/src/styles/common";

    .create-btn {
        margin: 25px 0;
        .event-btn-div(@fontsize: 16px,@backgroundcolor: #F58B52,@bordercolor: #ED582B)
    }
</style>